import { useCallback } from "react";
import { useGenerateBoleto } from "@/api/useNewCharge";
import { NewChargeReceipt, Popover, TokenDrawer } from "@/components";
import { useToggle } from "@/hooks";
import { useFormReducer } from "@/hooks/useFormReducer";
import { guidTokenType } from "@/services/general";
import { guidTag } from "@/services/newCharge";
import { useNewCharge } from "@/stores/NewCharge";
import { StandardCustomEvent } from "@/types/general";
import { TODAY } from "@/utils/constants";
import { formatDateToApi, isValidDate } from "@/utils/filter";
import { handleNormalizeDate, unMask } from "@/utils/format";
import {
  getChargeRoutesData,
  Hooks,
  toast,
  useAccountStore,
  useUserStore,
} from "@/utils/utility";
import { Drawer } from "@hyperlocal/vital";
import { addDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import * as S from "./Boleto.styles";

const { useDispatchTokenEvent, useListenCustomEvent, useMediaQuery } = Hooks;

const getMinimumDueDateRecommend = (date: Date) => addDays(date, 3);

const routes = getChargeRoutesData();

type BoletoForm = {
  dueDate: Date;
  internalIdentification: string;
  chargeDescription: string;
};

const boletoFormInitialState: BoletoForm = {
  dueDate: getMinimumDueDateRecommend(new Date()),
  chargeDescription: "",
  internalIdentification: "",
};

interface BoletoProps {
  amount: number;
}

const formValidation = (formData: BoletoForm, amount: number) => {
  const validationMap: Record<keyof BoletoForm, boolean> = {
    internalIdentification: !!formData.internalIdentification,
    chargeDescription: !!formData.chargeDescription,
    dueDate: !!formData.dueDate,
  };

  return Object.keys(formData).every((key) => validationMap[key]) && amount > 0;
};

export function Boleto({ amount }: BoletoProps) {
  const { dispatch, formState } = useFormReducer<BoletoForm>(
    boletoFormInitialState,
  );

  const [isTokenOpen, setTokenDrawer] = useToggle();

  const isMobile = useMediaQuery("mobile");

  const [isReceiptOpen, setReceiptDrawer] = useToggle();

  const { payerData, updateChargeData, resetNewChargeStore } = useNewCharge();

  const { currentAccountId: guidAccount } = useAccountStore();

  const {
    user: { userId: guidUser },
  } = useUserStore();

  const navigate = useNavigate();

  const saveChargeData = () => {
    updateChargeData({
      amount,
      type: "BOLETO",
      chargeRecurrence: "ONCE",
      created: TODAY,
      description: formState.chargeDescription,
      dueDate: formState.dueDate,
    });
  };

  const dispatchGenerateEvent = useDispatchTokenEvent({
    detail: {
      eventIdentifier: "GENERATE_BOLETO",
      request: {
        guid_token_type: guidTokenType.GENERATE_BOLETO,
        path: routes.newCharge,
        guid_user: guidUser,
      },
    },
  });

  const generateBoletoMutation = useGenerateBoleto({
    onError: (error) => {
      const errorMessage =
        error?.response?.data[0]?.message || "Houve um erro ao gerar o boleto";
      toast.error(errorMessage);
    },
    onSuccess: () => {
      saveChargeData();
      if (isMobile) {
        navigate(routes.newChargeReceipt);
      } else {
        setReceiptDrawer();
      }
    },
  });

  const onReceiptDismiss = () => {
    navigate(routes.charge);
    resetNewChargeStore();
  };

  const onGenerate = useCallback(
    (e: StandardCustomEvent) => {
      setTokenDrawer();
      const [DDD, cellphone] = payerData.cellphone.split(" ");
      generateBoletoMutation.mutate({
        amount,
        guidAccount,
        dueDate: formatDateToApi(formState.dueDate),
        document: unMask(payerData.document),
        email: payerData.email,
        guid_user: guidUser,
        message: formState.chargeDescription,
        summary: formState.internalIdentification,
        payer_name: payerData.name,
        send_Sms: true,
        send_email: true,
        guid_tag: guidTag.GENERATE_BOLETO,
        area_code: DDD,
        cellphone: unMask(cellphone),
        token: e.detail.token,
      });
    },
    [
      amount,
      formState.chargeDescription,
      formState.dueDate,
      formState.internalIdentification,
      generateBoletoMutation,
      guidAccount,
      guidUser,
      payerData.cellphone,
      payerData.document,
      payerData.email,
      payerData.name,
      setTokenDrawer,
    ],
  );

  useListenCustomEvent({
    eventName: "GENERATE_BOLETO",
    callback: onGenerate,
  });

  const popoverMessage =
    "Use pelo menos 3 dias úteis a contar da data de criação. Evite gerar boletos vencidos.";

  return (
    <>
      <S.Container>
        <S.Title>Dados da cobrança</S.Title>
        <S.InputsContainer>
          <S.Input
            type="date"
            hint={
              <Popover message={popoverMessage} title="Data do vencimento">
                <S.Icon name="TagsAlertsInfoCircle" fill="currentColor" />
              </Popover>
            }
            label="Boleto vence em"
            value={formatDateToApi(formState.dueDate)}
            min={formatDateToApi(TODAY)}
            onChange={({ target }) => {
              const date = handleNormalizeDate(target.value);
              if (isValidDate(date)) dispatch({ name: "dueDate", value: date });
            }}
          />
          <div />
          <S.Input
            type="text"
            label="Identificação interna"
            placeholder="Digite aqui"
            onChange={({ target }) =>
              dispatch({ name: "internalIdentification", value: target.value })
            }
          />
          <S.Input
            type="text"
            label="Descrição da cobrança"
            placeholder="Digite aqui"
            onChange={({ target }) =>
              dispatch({ name: "chargeDescription", value: target.value })
            }
          />
        </S.InputsContainer>
        <S.Button
          onClick={setTokenDrawer}
          isLoading={generateBoletoMutation.isLoading}
          disabled={!formValidation(formState, amount)}
        >
          Gerar cobrança
        </S.Button>
      </S.Container>
      <TokenDrawer
        isOpen={isTokenOpen}
        onClose={setTokenDrawer}
        dispatchOnMount={dispatchGenerateEvent}
      />
      <Drawer
        open={isReceiptOpen}
        onDismiss={onReceiptDismiss}
        position="right"
        title="Resumo do boleto"
      >
        {isReceiptOpen && <NewChargeReceipt />}
      </Drawer>
    </>
  );
}
