import { queries, requestBalance, useAccountStore } from "@/utils/utility";
import { useQuery } from "@tanstack/react-query";

const { balanceKeys } = queries;

export function useBalance() {
  const { currentAccountId } = useAccountStore();

  return useQuery({
    queryKey: balanceKeys.getBalance({ accountId: currentAccountId }),
    queryFn: () => requestBalance(),
    suspense: false,
  });
}
