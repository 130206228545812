export const RECURRENCE_CYCLES_OPTION = {
  diario: "Diário",
  semanal: "Semanal",
  quinzenal: "Quinzenal",
  mensal: "Mensal",
  bimestral: "Bimestral",
  trimestral: "Trimestral",
  semestral: "Semestral",
  anual: "Anual",
} as const;

export const MIN_ZIPCODE_LENGTH = 8;

export const CREDIT_CARD_ACCEPTABLE_LENGTH = [16, 19];

export const CVV_ACCEPTABLE_LENGTH = 3;

export const EXPIRATION_ACCEPTABLE_LENGTH = 4;

export const TODAY = new Date();
