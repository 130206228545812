import { breakpoints } from "@/styles/breakpoints";
import {
  Button as VitalButton,
  Divider as VitalDivider,
} from "@hyperlocal/vital";
import styled, { css } from "styled-components";

const commonStyle = css`
  &[data-align="flex-start"] {
    align-self: flex-start;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacings.inset.xl};
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > span:first-child {
    font-size: ${({ theme }) => theme.fontSizes.x2s};
    color: ${({ theme }) => theme.palette.neutral.darker};
  }
`;

export const Link = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 430px;

  ${breakpoints.mobile} {
    max-width: 90vw;
  }

  & > span:first-child {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    color: ${({ theme }) => theme.palette.primary.main};
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden; /* "overflow" value must be different from "visible" */

    text-overflow: ellipsis;
  }
`;

export const Title = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.palette.neutral.darkest};
`;

export const Amount = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.xl};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const Divider = styled(VitalDivider)`
  width: 100%;
  margin: ${({ theme }) => `${theme.spacings.inset.md} 0`};
`;

export const DataList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-row-gap: ${({ theme }) => theme.spacings.inset.x2s};
  max-width: 430px;

  ${breakpoints.mobile} {
    max-width: 90vw;
  }
`;

export const DataTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.x2s};
  color: ${({ theme }) => theme.palette.neutral.darker};
  max-width: 430px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* ellipsis na segunda linha */
  -webkit-box-orient: vertical;

  &[data-color="darkest"] {
    color: ${({ theme }) => theme.palette.neutral.darkest};
  }

  ${commonStyle};

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.x4s};
    max-width: 90vw;
  }
`;

export const DataDescription = styled.span`
  text-align: end;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.palette.neutral.dark};

  ${commonStyle};

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.x3s};
  }
`;

export const ContainerTitle = styled.h5`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.neutral.darkest};
  align-self: flex-start;
  margin-bottom: ${({ theme }) => theme.spacings.inset.md};
`;

export const PixQRCode = styled.img`
  max-width: 200px;
  max-height: 200px;
  width: 100%;
  height: 100%;
`;

export const Button = styled(VitalButton)`
  justify-content: center;
`;
