import { useChargesList } from "@/api/useChargesList";
import { ScrollArea } from "@/components";
import { CardsListSkeleton } from "@/pages/Charge/components/ChargesPerPeriod/CardsListSkeleton";
import { DrawerDetailsState } from "@/pages/Charge/components/ChargesPerPeriod/ChargesPerPeriod";
import * as S from "@/pages/Charge/components/ChargesPerPeriod/ChargesPerPeriod.styles";
import { useNewCharge } from "@/stores/NewCharge";
import { unMask } from "@/utils/format";
import { formatCurrency, Hooks } from "@/utils/utility";
import Icon from "@hyperlocal/vital-icons";
import { Avatar, Card } from "@hyperlocal/vital2";
import { useSearchParams } from "react-router-dom";

export const LastCharges = () => {
  const { isFetching, data } = useChargesList();
  const isMobile = Hooks.useMediaQuery("mobile");
  const [searchParams, setSearchParams] = useSearchParams();
  const { updatePayerData } = useNewCharge();

  const handleClick = (detail: DrawerDetailsState) => {
    updatePayerData({
      name: detail.client.name,
      email: detail.client.email,
      document: unMask(detail.client.document),
      cellphone: `${detail.client.mobilePhoneArea}${detail.client.mobilePhoneNumber}`,
    });
    if (isMobile) {
      searchParams.set("tab", "new-charge");
      setSearchParams(searchParams);
    }
  };

  if (isFetching) return <CardsListSkeleton />;

  if (!data || !data.items.length)
    return <S.EmptyText>Nenhuma cobrança</S.EmptyText>;

  return (
    <div className="flex h-full flex-col justify-between gap-6 overflow-hidden rounded-sm bg-neutral-white mobile:!gap-5 mobile:!p-0 mobile:!pb-6">
      <h5 className="px-6 text-sm/8 font-bold text-neutral-darkest mobile:text-base">
        Últimas cobranças realizadas
      </h5>
      <ScrollArea>
        <ul className="relative z-[1] mx-6 flex flex-col gap-2 mobile:!mx-4">
          {data.items.map((item) => (
            <li key={item.guidLink}>
              <button className="w-full" onClick={() => handleClick(item)}>
                <Card className="flex w-full items-center gap-3">
                  <Avatar.Root
                    size="md"
                    variant="secondary"
                    className="flex-shrink-0"
                  >
                    <Avatar.Slot name="GeralMoneyPay" />
                  </Avatar.Root>
                  <div className="flex flex-col text-left">
                    <span className="text-base text-neutral-darkest">
                      {item.client.name}
                    </span>
                    <span className="text-x2s text-neutral-darkest">
                      Link de cobrança - {formatCurrency(item.amount)}
                    </span>
                  </div>
                  <Icon
                    name="ArrowNoLineRigh"
                    fill="currentColor"
                    className="ml-auto size-4 text-neutral-darkest"
                  />
                </Card>
              </button>
            </li>
          ))}
        </ul>
      </ScrollArea>
    </div>
  );
};
