import { Skeleton } from "@/components";
import { breakpoints } from "@/styles/breakpoints";
import { styled } from "styled-components";
import { BalanceSectionVariants } from "./BalanceSection";

export const ValueWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.stack.nano}; // 8px

  ${breakpoints.mobile} {
    gap: ${({ theme }) => theme.spacings.stack.quarck}; // 4px
  }
`;

export const Wrapper = styled.div<{ $variant: BalanceSectionVariants }>`
  flex: 1;
  overflow: hidden;

  ${ValueWrapper} {
    color: ${({ theme, $variant }) =>
      $variant === "balance"
        ? theme.palette.primary.main
        : theme.palette.neutral.darker};
  }

  ${breakpoints.mobile} {
    display: ${({ $variant }) => ($variant === "balance" ? "flex" : "none")};
    align-items: center;
    gap: ${({ theme }) => theme.spacings.stack.nano}; // 8px

    ${ValueWrapper} {
      color: ${({ theme }) => theme.palette.neutral.darker};
    }
  }
`;

export const Title = styled.h4`
  margin-bottom: 8px;

  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.md};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.palette.neutral.darkest};

  ${breakpoints.mobile} {
    margin-bottom: 0;

    font-size: ${({ theme }) => theme.fontSizes.x2s};
    line-height: ${({ theme }) => theme.lineHeights.sm};
    color: ${({ theme }) => theme.palette.neutral.darker};
  }
`;

export const Prefix = styled.small`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.md};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  // Color é setada no Wrapper através do ValueWrapper

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    line-height: ${({ theme }) => theme.lineHeights.md};
  }
`;

export const ValueSkeleton = styled(Skeleton)`
  border-radius: ${({ theme }) => theme.borderRadius.sm}; // 8px

  height: 32px;
  width: 96px;

  ${breakpoints.mobile} {
    height: 24px;
    width: 48px;
  }
`;

export const Value = styled.b`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  line-height: ${({ theme }) => theme.lineHeights.default};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  // Color é setada no Wrapper através do ValueWrapper

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    line-height: ${({ theme }) => theme.lineHeights.md};
  }
`;
