import { DigitalSaleLinkChargeData } from "@/stores/NewCharge/types";
import { getInstallmentValue } from "@/utils/installment";
import { formatCurrency } from "@/utils/utility";
import Icon from "@hyperlocal/vital-icons";
import { toast } from "@hyperlocal/vital2";
import * as S from "../../NewChargeReceipt.styles";

export function DigitalSaleLinkReceipt(
  props: Readonly<DigitalSaleLinkChargeData>,
) {
  const {
    amount,
    created,
    description,
    dueDate,
    saleMaxInstallments,
    type,
    link,
  } = props;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(link);
      toast({
        title: "Link copiado!",
        variant: "success",
      });
    } catch (error) {
      toast({
        title: "Houve um erro ao copiar o link",
        variant: "error",
      });
    }
  };
  return (
    <S.Container>
      <S.Title>Valor da venda</S.Title>
      <S.Amount>{formatCurrency(amount)}</S.Amount>
      <S.LinkContainer>
        <span>Link da cobrança</span>
        <S.Link>
          <span>{props.link}</span>
          <button onClick={handleCopy}>
            <Icon
              name="GeralGeralCopy"
              color="black"
              width={24}
              height={24}
              fill="currentColor"
            />
          </button>
        </S.Link>
      </S.LinkContainer>
      <S.Divider />
      <S.ContainerTitle>Informações da cobrança</S.ContainerTitle>
      <S.DataList>
        {description && (
          <>
            <S.DataTitle>Descrição</S.DataTitle>
            <S.DataDescription>{description}</S.DataDescription>
          </>
        )}
        <S.DataTitle>Tipo de cobrança</S.DataTitle>
        <S.DataDescription>
          {saleMaxInstallments === "1" ? "Única" : "Recorrente"}
        </S.DataDescription>
        <S.DataTitle>Pagamento em até</S.DataTitle>
        <S.DataDescription>
          {saleMaxInstallments} de{" "}
          {getInstallmentValue(amount, Number(saleMaxInstallments))}
        </S.DataDescription>
        <S.DataTitle>Criado em</S.DataTitle>
        <S.DataDescription>{created.toLocaleDateString()}</S.DataDescription>
        {type === "DIGITAL_SALE_LINK" && (
          <>
            <S.DataTitle>Vencimento</S.DataTitle>
            <S.DataDescription>
              {dueDate.toLocaleDateString()}
            </S.DataDescription>
          </>
        )}
      </S.DataList>
    </S.Container>
  );
}
