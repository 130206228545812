import Icon from "@hyperlocal/vital-icons";
import { IntlFormatterConfig } from "react-intl-currency-input";
import * as S from "./PaymentInput.styles";

const currencyConfig: IntlFormatterConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

interface PaymentInputProps {
  handleInput: (value: number) => void;
  inputValue: number;
  maxValue?: number;
}

export function PaymentInput({
  handleInput,
  maxValue,
  inputValue,
}: PaymentInputProps) {
  return (
    <S.Container>
      <S.Label>Valor da cobrança</S.Label>
      <S.Value>
        <S.Currency>R$</S.Currency>
        <S.SInput
          config={currencyConfig}
          value={inputValue}
          onChange={(_, value) => {
            if (value >= maxValue) return handleInput(maxValue);
            handleInput(value);
          }}
          currency="BRL"
          max={maxValue}
        />
        <S.ClearInput onClick={() => handleInput(0)}>
          <Icon name="SettingTrashClose" className="closeIcon" />
        </S.ClearInput>
      </S.Value>
    </S.Container>
  );
}
