import * as ScrollArea from "@radix-ui/react-scroll-area";
import styled, { keyframes } from "styled-components";

const SCROLLBAR_SIZE = 8;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const ScrollAreaRoot = styled(ScrollArea.Root)`
  overflow: hidden;
`;

export const ScrollAreaViewport = styled(ScrollArea.Viewport)`
  width: 100%;
  height: 100%;
`;

export const ScrollAreaScrollbar = styled(ScrollArea.Scrollbar)`
  display: flex;
  user-select: none;
  touch-action: none;

  background: ${({ theme }) => theme.palette.neutral.lighter};
  border-radius: ${SCROLLBAR_SIZE / 2}px;

  margin-right: 4px;

  transition: filter 160ms ease-out;

  &:hover {
    filter: brightness(0.95);
  }

  &[data-state="visible"] {
    animation: ${fadeIn} 0.15s ease-out;
  }

  &[data-state="hidden"] {
    animation: ${fadeOut} 0.15s ease-out;
  }

  &[data-orientation="vertical"] {
    width: ${SCROLLBAR_SIZE}px;
  }
`;

export const ScrollAreaThumb = styled(ScrollArea.Thumb)`
  flex: 1;
  position: relative;
  background: ${({ theme }) => theme.palette.neutral.light};
  border-radius: ${SCROLLBAR_SIZE / 2}px;

  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
  }
`;
