import React, { ReactNode } from "react";
import Icon from "@hyperlocal/vital-icons";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import * as S from "./Popover.styles";

const PopoverRoot = PopoverPrimitive.Root;

interface ContentProps {
  title: string;
  contentMessage: string;
}

const PopoverContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement> & ContentProps
>(({ ...props }, forwardedRef) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      sideOffset={5}
      side="top"
      {...props}
      ref={forwardedRef}
    >
      <S.Container>
        <S.Header>
          <S.Title>{props.title}</S.Title>
          <S.CloseContainer>
            <Icon
              name="GeralClose"
              width={24}
              height={24}
              fill="currentColor"
            />
          </S.CloseContainer>
        </S.Header>
        <S.Divider />
        <S.Message>{props.contentMessage}</S.Message>
      </S.Container>
    </PopoverPrimitive.Content>
  </PopoverPrimitive.Portal>
));
PopoverContent.displayName = "PopoverContent";

interface PopoverProps {
  children: ReactNode;
  title: string;
  message: string;
}

export function Popover({ children, message, title }: PopoverProps) {
  return (
    <PopoverRoot>
      <S.PopoverTrigger>{children}</S.PopoverTrigger>
      <PopoverContent title={title} contentMessage={message} />
    </PopoverRoot>
  );
}
