import { Skeleton } from "@/components";
import { breakpoints } from "@/styles/breakpoints";
import styled from "styled-components";

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacings.squished.md}; // 12px 24px

  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.palette.neutral.lighter};
  border-radius: ${({ theme }) => theme.borderRadius.sm}; // 8px

  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacings.stack.x3s}; // 16px

  width: 100%;

  & + & {
    margin-top: ${({ theme }) => theme.spacings.inline.x2s}; // 8px
  }

  ${breakpoints.mobile} {
    padding: ${({ theme }) => theme.spacings.squished.sm}; // 12px 16px
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.stack.x3s}; // 16px

  width: 100%;
`;

export const IconSkeleton = styled(Skeleton)`
  border-radius: ${({ theme }) => theme.borderRadius.circle}; // 50%

  height: 40px;
  width: 40px;

  flex-shrink: 0;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.stack.quarck}; // 4px

  width: 100%;
`;

export const TypeAndStatusSkeleton = styled(Skeleton)`
  border-radius: ${({ theme }) => theme.borderRadius.sm}; // 8px

  height: 21px;
  width: 144px;

  ${breakpoints.mobile} {
    height: 15px;
    width: 104px;
  }
`;

export const PersonSkeleton = styled(Skeleton)`
  border-radius: ${({ theme }) => theme.borderRadius.sm}; // 8px

  height: 24px;
  width: 200px;

  ${breakpoints.mobile} {
    width: 100%;
    max-width: 144px;
  }
`;

export const InstallmentSkeleton = styled(Skeleton)`
  border-radius: ${({ theme }) => theme.borderRadius.sm}; // 8px

  height: 21px;
  width: 122px;

  ${breakpoints.mobile} {
    height: 15px;
    width: 80px;
  }
`;

export const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: ${({ theme }) => theme.borderRadius.xs}; // 4px
`;
