import { useEffect } from "react";
import { useInstallmentList } from "@/api/useInstallmentsList";
import { Breadcrumb, BreadcrumbItem } from "@/components";
import { useSetHeader } from "@/hooks";
import { formatCurrency, setTokenData } from "@/utils/utility";
import { useParams } from "react-router-dom";
import { Filter, InstallmentsList, StatusReport } from "./components";
import * as S from "./FutureInstallments.styles";

const breadcrumbs: BreadcrumbItem[] = [
  { name: "Cobrança", path: "charge" },
  { name: "Parcelas Futuras", path: "futureInstallments" },
];

export function FutureInstallments() {
  const { id } = useParams();
  useSetHeader({ title: "Parcelas Futuras", backRoute: "charge" });

  const { data, isError } = useInstallmentList({
    guidDigitalSaleRecurrence: id,
  });

  useEffect(() => {
    setTokenData(null);
  }, []);

  return (
    <S.Container>
      <S.Header>
        <Breadcrumb breadcrumbs={breadcrumbs} />
        <S.Title>Parcelas Futuras</S.Title>
      </S.Header>

      <S.Section>
        <S.LeftWrapper>
          {!isError && (
            <S.StatusWrapper>
              <StatusReport
                amount={formatCurrency(data.amountPaid)}
                type="paid"
                subTitle={`Pagas ${data.paidQuantity} de ${data.totalInstallments}`}
                title="Pagas"
              />
              <StatusReport
                type="pending"
                title="Pendente"
                amount={formatCurrency(data.amountPending)}
              />
            </S.StatusWrapper>
          )}
          <Filter />
        </S.LeftWrapper>
        <InstallmentsList
          isError={isError}
          installmentsReport={data?.installmentsReport}
        />
      </S.Section>
    </S.Container>
  );
}
