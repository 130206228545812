import { useModalState } from "@/pages/Charge/hooks";
import { CHARGE_STATUS, DATE_RANGES, useChargesFilter } from "@/stores";
import { formatDateToApi } from "@/utils/date";
import { Hooks } from "@/utils/utility";
import { Chips } from "@hyperlocal/vital";
import * as S from "./Filters.styles";
import { MobileSection } from "./MobileSection";

const TODAY = new Date();

export function Filters() {
  const isMobile = Hooks.useMediaQuery("mobile");
  const { filterState, dispatchFilter } = useChargesFilter();
  const { handleCloseModal, handleOpenModal, modalState } = useModalState();

  function handleClearFilters() {
    dispatchFilter({ type: "CLEAR_FILTERS" });
  }

  return (
    <>
      <MobileSection handleOpenModal={handleOpenModal} />

      <S.Container data-state={modalState}>
        <S.Title>{isMobile ? "Por período" : "Filtro de cobrança"}</S.Title>
        <S.ChipsContainer>
          {DATE_RANGES.map((range) => (
            <Chips
              key={range}
              testId="range" // "chips-range"
              active={filterState.selectedRange === range}
              onClick={() =>
                dispatchFilter({
                  type: "SET_SELECTED_RANGE",
                  payload: { selectedRange: range },
                })
              }
            >
              {range} dias
            </Chips>
          ))}
        </S.ChipsContainer>

        <S.SearchInputWrapper>
          <S.Input
            testId="search" // input-search
            type="search"
            placeholder="Buscar..."
            value={filterState.search}
            onChange={(event) =>
              dispatchFilter({
                type: "SET_SEARCH",
                payload: { search: event.target.value },
              })
            }
          />
        </S.SearchInputWrapper>

        <S.Title>Período personalizado</S.Title>

        <S.DateInputsWrapper>
          <S.Input
            testId="start-date" // input-start-date
            type="date"
            label="De"
            max={formatDateToApi(filterState.endDate ?? TODAY)}
            value={formatDateToApi(filterState.startDate)}
            helper={filterState.dateErrors.startDate ? "error" : "info"}
            helperText={filterState.dateErrors.startDate}
            onChange={(event) =>
              dispatchFilter({
                type: "SET_START_DATE",
                payload: { startDate: new Date(event.target.value) },
              })
            }
          />
          <S.Input
            testId="end-date" // input-end-date
            type="date"
            label="Até"
            min={formatDateToApi(filterState.startDate)}
            value={formatDateToApi(filterState.endDate)}
            helper={filterState.dateErrors.endDate ? "error" : "info"}
            helperText={filterState.dateErrors.endDate}
            onChange={(event) =>
              dispatchFilter({
                type: "SET_END_DATE",
                payload: { endDate: new Date(event.target.value) },
              })
            }
          />
        </S.DateInputsWrapper>

        <S.Title>Status da cobrança</S.Title>

        <S.CheckboxWrapper>
          {CHARGE_STATUS.map((status) => (
            <S.Checkbox
              key={status}
              testId="status"
              title={status}
              checked={filterState.chargeStatus.includes(status)}
              onChange={(event) => {
                const addStatus = [...filterState.chargeStatus, status];
                const removeStatus = filterState.chargeStatus.filter(
                  (item) => item !== status,
                );

                dispatchFilter({
                  type: "SET_CHARGE_STATUS",
                  payload: {
                    chargeStatus: event.checked ? addStatus : removeStatus,
                  },
                });
              }}
            >
              {status}
            </S.Checkbox>
          ))}
        </S.CheckboxWrapper>

        <div>
          <S.FilterButton variant="primary" onClick={handleCloseModal}>
            Filtrar
          </S.FilterButton>

          <S.CleanFiltersButton
            testId="clean-filter" // "button-clean-filter"
            variant="link"
            onClick={handleClearFilters}
          >
            Limpar filtros
          </S.CleanFiltersButton>
        </div>
      </S.Container>
    </>
  );
}
