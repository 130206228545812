import { useCallback, useState } from "react";
import {
  useCancelInstallmentList,
  useControlInstallmentList,
  useInstallmentList,
} from "@/api/useInstallmentsList";
import { TokenDrawer } from "@/components";
import { useSetHeader, useToggle } from "@/hooks";
import { guidTokenType } from "@/services/general";
import { DateCustomEvent, StandardCustomEvent } from "@/types/general";
import {
  getChargeRoutesData,
  getEventIdentifier,
  Hooks,
  toast,
  useAccountStore,
  useUserStore,
} from "@/utils/utility";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as S from "./InstallmentsListActions.styles";

type EventToCall = "control" | "cancel";

const routes = getChargeRoutesData();

const { useListenCustomEvent, useDispatchCustomEvent } = Hooks;

export function InstallmentsListActions() {
  const [isTokenOpen, setTokenDrawer] = useToggle();
  const [eventToCall, setEventTOCall] = useState<EventToCall>("cancel");
  const dispatchEvent = useDispatchCustomEvent();
  const queryClient = useQueryClient();

  const { id } = useParams();

  const { currentAccountId: guidAccount } = useAccountStore();

  const {
    user: { userId: guidUser },
  } = useUserStore();

  const { data } = useInstallmentList({
    guidDigitalSaleRecurrence: id,
  });

  const isInterrupt = data.saleRecurrence.status === "I";

  const isCancelled = data.saleRecurrence.status === "C";

  const isPaid = data.saleRecurrence.status === "E";

  const generateTokenRequest = ({
    guidTokenType,
    eventIdentifier,
    handleDate = false,
  }) => {
    return {
      request: {
        guid_user: guidUser,
        guid_token_type: guidTokenType,
        path: routes.futureInstallments.replace(":id", id),
        header: {
          title: "Token",
        },
      },
      eventIdentifier,
      handleDate,
    };
  };

  const setHeader = useSetHeader({
    title: "Parcelas Futuras",
    backRoute: "charge",
  });

  const navigate = useNavigate();

  const controlEventDispatch = () => {
    dispatchEvent({
      eventName: "REQUEST_TOKEN",
      eventInitDict: {
        detail: generateTokenRequest({
          eventIdentifier: getEventIdentifier("CONTROL_INSTALLMENT"),
          guidTokenType: guidTokenType.CONTROL_INSTALLMENT,
          handleDate: true,
        }),
      },
    });
  };

  const cancelEventDispatch = () => {
    dispatchEvent({
      eventName: "REQUEST_TOKEN",
      eventInitDict: {
        detail: generateTokenRequest({
          eventIdentifier: getEventIdentifier("CANCEL_INSTALLMENT"),
          guidTokenType: guidTokenType.CANCEL_INSTALLMENT_LIST,
        }),
      },
    });
  };

  const eventDispatch = () => {
    const eventDispatchMap: Record<EventToCall, () => void> = {
      cancel: cancelEventDispatch,
      control: controlEventDispatch,
    };

    return eventDispatchMap[eventToCall]();
  };

  const invalidateQueryAndReturn = () => {
    queryClient.invalidateQueries(["getInstallmentList"]);
    navigate(routes.charge);
  };

  const controlMutation = useControlInstallmentList({
    mutationKey: ["controlMutation", id],
    onSuccess: (_, variables) => {
      const message = variables.isInterrupt
        ? "Recorrência pausada"
        : "Recorrência despausada";
      toast.success(message);
      invalidateQueryAndReturn();
    },
    onError: (error, variables) => {
      const action = variables.isInterrupt ? "pausar" : "despausar";
      const errorMessage =
        error?.response?.data[0]?.message ||
        `Houve um erro ao ${action} a recorrência`;
      toast.error(errorMessage);
    },
  });

  const cancelMutation = useCancelInstallmentList({
    mutationKey: ["cancelMutation", id],
    onSuccess: () => {
      toast.success("Recorrência cancelada");
      invalidateQueryAndReturn();
    },
    onError: (error) => {
      const errorMessage =
        error?.response?.data[0]?.message ||
        "Houve um erro ao cancelar a recorrência";
      toast.error(errorMessage);
    },
  });

  const openTokenDrawer = (eventToCall: EventToCall) => {
    setEventTOCall(eventToCall);
    setTokenDrawer();
  };

  const onCancelInstallment = useCallback(
    (e: StandardCustomEvent) => {
      setTokenDrawer();
      setHeader();
      cancelMutation.mutate({
        guidAccount,
        guid_user: guidUser,
        token: e.detail.token,
        guidDigitalSaleRecurrence: id,
      });
    },
    [cancelMutation, guidAccount, guidUser, id, setHeader, setTokenDrawer],
  );

  const onControlInstallments = useCallback(
    ({ detail }: DateCustomEvent) => {
      setTokenDrawer();
      setHeader();
      controlMutation.mutate({
        guid_user: guidUser,
        guidAccount,
        token: detail.token,
        isInterrupt: !isInterrupt,
        guidDigitalSaleRecurrence: id,
        isChange_date: !!detail.date,
        processing_date: detail?.date || "",
      });
    },
    [
      controlMutation,
      guidAccount,
      guidUser,
      id,
      isInterrupt,
      setHeader,
      setTokenDrawer,
    ],
  );

  useListenCustomEvent({
    eventName: "CANCEL_INSTALLMENT",
    callback: onCancelInstallment,
  });

  useListenCustomEvent({
    eventName: "CONTROL_INSTALLMENT",
    callback: onControlInstallments,
  });

  return (
    <>
      <S.ButtonContainer>
        <S.Button
          variant="link"
          isLoading={cancelMutation.isLoading}
          onClick={() => openTokenDrawer("cancel")}
          disabled={isCancelled || isPaid}
        >
          Cancelar
        </S.Button>
        <S.Button
          variant="secondary"
          isLoading={controlMutation.isLoading}
          onClick={() => openTokenDrawer("control")}
          disabled={isCancelled || isPaid}
        >
          {isInterrupt ? "Despausar" : "Pausar"}
        </S.Button>
      </S.ButtonContainer>
      <TokenDrawer
        dispatchOnMount={eventDispatch}
        isOpen={isTokenOpen}
        onClose={setTokenDrawer}
      />
    </>
  );
}
