import { Button as VitalButton } from "@hyperlocal/vital";
import styled from "styled-components";

export const Container = styled.div`
  padding-top: ${({ theme }) => theme.spacings.inset.md};
  margin-top: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Button = styled(VitalButton)`
  justify-content: center;
  color: ${({ variant, theme }) =>
    variant === "link" ? theme.palette.status.error.errorDark : "auto"};
`;
