import * as svgs from "@/assets/svgs";
import {
  Breadcrumb,
  BreadcrumbItem,
  ErrorFallback,
  NewChargeDrawer,
} from "@/components";
import { useSetHeader, useToggle } from "@/hooks";
import {
  Balance,
  ChargesPerPeriod,
  Filters,
  ReceivedCard,
} from "@/pages/Charge/components";
import { useNewCharge } from "@/stores/NewCharge";
import { getChargeRoutesData } from "@/utils/utility";
import { Button } from "@hyperlocal/vital2";
import { ErrorBoundary } from "react-error-boundary";
import { Link, useNavigate } from "react-router-dom";
import * as S from "./Charge.styles";

const routes = getChargeRoutesData();

const breadcrumbs: BreadcrumbItem[] = [{ name: "Cobrança", path: "charge" }];

export function Charge() {
  const navigate = useNavigate();
  useSetHeader({ title: "Cobrança", backRoute: "dashboard" });

  const { resetNewChargeStore } = useNewCharge();
  const [isNewChargeOpen, setIsNewChargeOpen] = useToggle();

  function handleOpenNewChargeDrawer() {
    resetNewChargeStore();
    setIsNewChargeOpen();
  }

  function handleNewChargeSubmit() {
    navigate(routes.newCharge);
  }

  return (
    <>
      <S.Container>
        <S.Header>
          <div>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <S.TitleContainer>
              <Link to={routes.dashboard}>
                <svgs.ArrowLeft />
              </Link>
              <S.Title>Cobrança</S.Title>
            </S.TitleContainer>
          </div>
          <Button.Root onClick={handleOpenNewChargeDrawer}>
            Nova cobrança
          </Button.Root>
        </S.Header>
        <S.Section>
          <S.LeftWrapper>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Balance />
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Filters />
            </ErrorBoundary>
          </S.LeftWrapper>

          <S.RightWrapper>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <ReceivedCard />
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <ChargesPerPeriod />
            </ErrorBoundary>
          </S.RightWrapper>
        </S.Section>
      </S.Container>
      <NewChargeDrawer
        isOpen={isNewChargeOpen}
        onDismiss={setIsNewChargeOpen}
        onSubmit={handleNewChargeSubmit}
      />
    </>
  );
}
