import { createContext, ReactNode, useContext, useReducer } from "react";
import { TODAY } from "@/utils/constants";
import { DATE_RANGES, INSTALLMENT_STATUS } from "@/utils/filter";
import { handleNormalizeDate } from "@/utils/format";
import {
  Action,
  DateRanges,
  State,
  FilterContext as TFilterContext,
} from "./types";

const handleStartDate = (state: State, date: Date) => {
  const newStartDate = handleNormalizeDate(date);

  if (state.endDate && newStartDate > state.endDate) {
    return {
      ...state,
      dateErrors: {
        startDate: "A data de início deve ser anterior à data de término",
        endDate: undefined,
      },
    };
  }

  return {
    ...state,
    dateErrors: {
      startDate: undefined,
      endDate: undefined,
    },
    selectedRange: undefined,
    startDate: newStartDate,
  };
};

const handleEndDate = (state: State, date: Date) => {
  const newEndDate = handleNormalizeDate(date);

  if (state.startDate && newEndDate < state.startDate) {
    return {
      ...state,
      dateErrors: {
        startDate: undefined,
        endDate: "A data de término deve ser posterior à data de início",
      },
    };
  }

  return {
    ...state,
    dateErrors: {
      startDate: undefined,
      endDate: undefined,
    },
    selectedRange: undefined,
    endDate: newEndDate,
  };
};

const handleSelectRange = (state: State, range: DateRanges) => {
  const startDate = new Date(TODAY);
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date(TODAY);
  endDate.setHours(0, 0, 0, 0);
  endDate.setDate(endDate.getDate() + Number(range));

  return {
    ...state,
    selectedRange: range,
    startDate,
    endDate,
  };
};

const initialState: State = {
  startDate: null,
  endDate: null,
  search: "",
  installmentStatus: [...INSTALLMENT_STATUS],
  ranges: [...DATE_RANGES],
  selectedRange: undefined,
  installmentSelected: null,
  dateErrors: {
    endDate: "",
    startDate: "",
  },
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_START_DATE":
      return handleStartDate(state, action.payload.startDate);
    case "SET_END_DATE":
      return handleEndDate(state, action.payload.endDate);
    case "SET_SEARCH":
      return { ...state, search: action.payload.search };
    case "SET_SELECTED_RANGE":
      return handleSelectRange(state, action.payload.selectedRange);
    case "SET_INSTALLMENT_STATUS":
      return { ...state, installmentStatus: action.payload.installmentStatus };
    case "SET_SELECT_INSTALLMENT":
      return {
        ...state,
        installmentSelected: action.payload.installmentSelected,
      };
    case "CLEAR_FILTERS":
      return initialState;
    default:
      return state;
  }
};

export const FilterContext = createContext<TFilterContext>({
  filterState: initialState,
  dispatchFilter: () => null,
});

export const FilterProvider = ({ children }: { children: ReactNode }) => {
  const [filterState, dispatch] = useReducer(reducer, initialState);

  return (
    <FilterContext.Provider value={{ filterState, dispatchFilter: dispatch }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => {
  const value = useContext(FilterContext);
  return value;
};
