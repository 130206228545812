import { useEffect } from "react";
import { getCities, useCep, useGetStates } from "@/api/useAddress";
import { City } from "@/api/useAddress.types";
import { useCreditCardCompanies } from "@/api/useInstallmentDetail";
import { useGetPayerData } from "@/api/useNewCharge";
import { InputMask } from "@/components";
import { useFormReducer } from "@/hooks/useFormReducer";
import { useNewCharge } from "@/stores/NewCharge";
import { CepPromiseError } from "@/types/request";
import { RECURRENCE_CYCLES_OPTION, TODAY } from "@/utils/constants";
import { formatDateToApi, isValidDate } from "@/utils/filter";
import { handleNormalizeDate, unMask } from "@/utils/format";
import { generateMaxInstallmentsOption } from "@/utils/installment";
import { toast, useAccountStore } from "@/utils/utility";
import { Select } from "@hyperlocal/vital";
import { RecurrenceActions } from "./components";
import * as S from "./Recurrence.styles";

const RECURRENCE_MAX_OPTION = 36;

export type RecurrenceForm = {
  totalInstallments: string;
  recurrenceCycle: keyof typeof RECURRENCE_CYCLES_OPTION;
  processingDate: Date;
  internalIdentification: string;
  extractDescription: string;
  cardName: string;
  cardNumber: string;
  cardExpiration: string;
  CVV: string;
  creditCardGuid: string;
  zipcode: string;
  street: string;
  neighborhood: string;
  number: string;
  state: string;
  city: string;
  complement?: string;
  citiesList: City[];
  guidSaleRecurrenceRecipient: string;
};

const FORM_INITIAL_STATE: RecurrenceForm = {
  cardExpiration: "",
  cardName: "",
  cardNumber: "",
  city: "",
  creditCardGuid: "",
  CVV: "",
  internalIdentification: "",
  neighborhood: "",
  number: "",
  processingDate: TODAY,
  recurrenceCycle: "diario",
  state: "RO",
  street: "",
  totalInstallments: "",
  zipcode: "",
  complement: "",
  extractDescription: "",
  citiesList: [],
  guidSaleRecurrenceRecipient: "",
};

const INSTALLMENT_NUMBER_OPTION = generateMaxInstallmentsOption(
  RECURRENCE_MAX_OPTION,
);

export function Recurrence({ amount }: { amount: number }) {
  const { data: creditCardCompanies } = useCreditCardCompanies({
    suspense: false,
  });

  const { dispatch, formState } =
    useFormReducer<RecurrenceForm>(FORM_INITIAL_STATE);

  const { currentAccountId: guidAccount } = useAccountStore();

  const { payerData } = useNewCharge();

  const { data: statesList } = useGetStates({
    suspense: false,
  });

  useGetPayerData({
    document: unMask(payerData.document),
    guidAccount,
    config: {
      onSuccess: (data) => {
        dispatch({
          name: "guidSaleRecurrenceRecipient",
          value: data.guid_digital_sale_recurrence_recipient,
        });
      },
    },
  });

  const { refetch } = useCep(formState.zipcode, {
    suspense: false,
    enabled: false,
    onError: (error: CepPromiseError) => {
      dispatch({
        name: "zipcode",
        value: "",
      });
      const errorMessage = error?.message || "Houve um erro ao procurar o CEP";

      toast.error(errorMessage);
    },
    onSuccess: (data) => {
      dispatch({
        name: "neighborhood",
        value: data.neighborhood,
      });

      dispatch({
        name: "street",
        value: data.street,
      });

      dispatch({
        name: "city",
        value: data.city,
      });

      dispatch({
        name: "state",
        value: data.state,
      });

      getCities(data.state).then((res) =>
        dispatch({
          name: "citiesList",
          value: res,
        }),
      );
    },
  });

  const handleInput = ({
    target,
  }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const name = target.name as keyof RecurrenceForm;
    const value = target.value;
    dispatch({ name, value });
  };

  useEffect(() => {
    const isZipCodeFilled = unMask(formState.zipcode).length === 8;
    if (isZipCodeFilled) {
      refetch();
    }
  }, [formState.zipcode, refetch]);

  return (
    <S.Container>
      <S.Title>Dados da cobrança</S.Title>
      <S.ChargeDataContainer>
        <S.InputContainer gridArea="totalInstallments">
          <Select
            label="Nº de recorrências"
            name="totalInstallments"
            onChange={handleInput}
          >
            <option value="">Selecionar</option>
            {INSTALLMENT_NUMBER_OPTION.map((installment) => (
              <option value={`${installment}`} key={installment}>
                {installment}x
              </option>
            ))}
          </Select>
        </S.InputContainer>
        <S.InputContainer gridArea="recurrenceCycle">
          <Select
            label="Ciclo de recorrência"
            name="recurrenceCycle"
            onChange={handleInput}
            value={formState.recurrenceCycle}
          >
            {Object.entries(RECURRENCE_CYCLES_OPTION).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </Select>
        </S.InputContainer>
        <S.InputContainer gridArea="processingDate">
          <S.Input
            type="date"
            label="Data de lançamento"
            name="processingDate"
            value={formatDateToApi(formState.processingDate)}
            min={formatDateToApi(TODAY)}
            onChange={({ target }) => {
              const date = handleNormalizeDate(target.value);
              if (isValidDate(date))
                dispatch({ name: "processingDate", value: date });
            }}
          />
        </S.InputContainer>
        <S.InputContainer gridArea="internalIdentification">
          <S.Input
            type="text"
            label="Identificação interna"
            placeholder="Digite aqui"
            name="internalIdentification"
            onChange={handleInput}
          />
        </S.InputContainer>
        <S.InputContainer gridArea="extractDescription">
          <S.Input
            type="text"
            label="Texto na fatura"
            placeholder="Digite aqui"
            name="extractDescription"
            onChange={handleInput}
          />
        </S.InputContainer>
      </S.ChargeDataContainer>
      <S.Title data-spacing="top">Dados do cartão</S.Title>
      <S.CreditCardContainer>
        <S.InputContainer gridArea="cardName">
          <S.Input
            type="text"
            label="Nome impresso no cartão"
            placeholder="Digite aqui"
            name="cardName"
            onChange={handleInput}
          />
        </S.InputContainer>
        <S.InputContainer gridArea="cardNumber">
          <InputMask
            mask="9999.9999.9999.9999999"
            name="cardNumber"
            type="number"
            label="Nº do cartão"
            placeholder="0000.0000.0000.0000"
            onChange={handleInput}
          />
        </S.InputContainer>
        <S.InputContainer gridArea="cardExpiration">
          <InputMask
            mask="99/99"
            name="cardExpiration"
            type="number"
            label="Validade"
            placeholder="00/00"
            onChange={handleInput}
          />
        </S.InputContainer>
        <S.InputContainer gridArea="CVV">
          <InputMask
            mask="999"
            name="CVV"
            type="number"
            label="CVV"
            placeholder="000"
            onChange={handleInput}
          />
        </S.InputContainer>
        <S.InputContainer gridArea="creditCardGuid">
          <Select name="creditCardGuid" label="Bandeira" onChange={handleInput}>
            <option value="">Selecionar</option>
            {creditCardCompanies?.map((company) => (
              <option
                value={company.guid_creditcard_company}
                key={company.guid_creditcard_company}
              >
                {company.description}
              </option>
            ))}
          </Select>
        </S.InputContainer>
      </S.CreditCardContainer>
      <S.Title data-spacing="top">Dados do endereço</S.Title>
      <S.AddressContainer>
        <S.InputContainer gridArea="zipcode">
          <InputMask
            name="zipcode"
            mask="99999-999"
            value={formState.zipcode}
            label="CEP"
            onChange={handleInput}
          />
        </S.InputContainer>
        <S.InputContainer gridArea="street">
          <S.Input
            type="text"
            value={formState.street}
            disabled={!formState.street}
            label="Endereço da cobrança"
            placeholder="Digite o endereço"
            name="street"
            onChange={handleInput}
          />
        </S.InputContainer>
        <S.InputContainer gridArea="neighborhood">
          <S.Input
            type="text"
            label="Bairro"
            value={formState.neighborhood}
            disabled={!formState.street}
            placeholder="Digite o endereço"
            name="neighborhood"
            onChange={handleInput}
          />
        </S.InputContainer>
        <S.InputContainer gridArea="number">
          <InputMask
            name="number"
            mask="99999999"
            disabled={!formState.street}
            label="Número"
            onChange={handleInput}
          />
        </S.InputContainer>
        <S.InputContainer gridArea="state">
          <Select
            label="Estado"
            name="state"
            value={formState.state}
            disabled={!formState.street}
          >
            {statesList?.map((state) => (
              <option key={state.sigla}>{state.sigla}</option>
            ))}
          </Select>
        </S.InputContainer>
        <S.InputContainer gridArea="city">
          <Select
            name="city"
            label="Cidade"
            onChange={handleInput}
            value={formState.city}
            disabled={!formState.city}
          >
            {formState.citiesList.map((item, index) => (
              <option key={index}>{item.nome}</option>
            ))}
          </Select>
        </S.InputContainer>
        <S.InputContainer gridArea="complement">
          <S.Input
            type="text"
            label="Complemento"
            placeholder="Digite (opcional)"
            name="complement"
            value={formState.complement}
            onChange={(e) => {
              const acceptableLength = 10;
              const complement = e.target.value;
              if (complement.length <= acceptableLength) {
                handleInput(e);
              }
            }}
          />
        </S.InputContainer>
        <S.CEPAnchor
          href="https://buscacepinter.correios.com.br/app/endereco/index.php"
          target="blank"
          rel="noreferrer"
        >
          não sei meu CEP
        </S.CEPAnchor>
      </S.AddressContainer>
      <RecurrenceActions {...formState} amount={amount} />
    </S.Container>
  );
}
