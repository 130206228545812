import { useInfiniteChargeList } from "@/api/useChargesList";
import { Skeleton } from "@/components";
import { useChargesFilter } from "@/stores";
import { formatCurrency } from "@/utils/utility";
import { Chips } from "@hyperlocal/vital";
import * as S from "./MobileSection.styles";

interface MobileSectionProps {
  handleOpenModal: () => void;
}

export function MobileSection({ handleOpenModal }: MobileSectionProps) {
  const { filterState, dispatchFilter } = useChargesFilter();
  const { data, isFetching } = useInfiniteChargeList();

  return (
    <S.Container>
      <S.Wrapper>
        <div>
          <S.Title>Recebidos</S.Title>
          {isFetching ? (
            <Skeleton height="20px" width="120px" borderRadius="8px" />
          ) : (
            <S.Value>
              {formatCurrency(data?.paidAmount ?? 0, {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </S.Value>
          )}
        </div>

        <Chips leftIcon={{ name: "FilterFilter" }} onClick={handleOpenModal}>
          Filtros
        </Chips>
      </S.Wrapper>

      <S.Input
        type="search"
        testId="mobile-search" // input-mobile-search
        placeholder="Buscar..."
        value={filterState.search}
        onChange={(event) =>
          dispatchFilter({
            type: "SET_SEARCH",
            payload: { search: event.target.value },
          })
        }
      />
    </S.Container>
  );
}
