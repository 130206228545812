import { useInfiniteChargeList } from "@/api/useChargesList";
import { formatCurrency } from "@/utils/utility";
import * as S from "./ReceivedCard.styles";
import { Tooltip } from "./Tooltip";
import { TooltipContent } from "./TooltipContent";

export function ReceivedCard() {
  const { data, isFetching } = useInfiniteChargeList();

  const notDoneAmounts = {
    canceled: data?.canceledAmount ?? 0,
    expired: data?.expiredAmount ?? 0,
  };

  const notDoneAmountsSum = notDoneAmounts.canceled + notDoneAmounts.expired; // Estornado + Cancelado + Expirado

  const notDoneItems = [
    { text: "Expiradas:", amount: notDoneAmounts.expired },
    { text: "Canceladas:", amount: notDoneAmounts.canceled },
  ];

  return (
    <S.Container>
      <S.Wrapper>
        <S.CardContainer>
          <S.CardHeader>
            <S.PendingIcon
              name="MoneyMoneyRecive"
              fill="currentColor"
              width="16"
              height="16"
            />
            <S.CardHeaderTitle>Pendentes</S.CardHeaderTitle>
          </S.CardHeader>

          {isFetching ? (
            <S.Skeleton height="20px" width="112px" borderRadius="6px" />
          ) : (
            <S.Amount>{formatCurrency(data?.pendingAmount ?? 0)}</S.Amount>
          )}
        </S.CardContainer>

        <Tooltip content={<TooltipContent items={notDoneItems} />}>
          <S.CardContainer>
            <S.CardHeader>
              <S.ReversedIcon
                name="MoneyMoneyCanceled"
                fill="currentColor"
                width="16"
                height="16"
              />
              <S.CardHeaderTitle>Não efetuados</S.CardHeaderTitle>
            </S.CardHeader>

            {isFetching ? (
              <S.Skeleton height="20px" width="112px" borderRadius="6px" />
            ) : (
              <S.Amount>{formatCurrency(notDoneAmountsSum)}</S.Amount>
            )}
          </S.CardContainer>
        </Tooltip>
      </S.Wrapper>
    </S.Container>
  );
}
