import { formatCurrency } from "@/utils/utility";
import * as S from "./BalanceSection.styles";

const SECRET_VALUE = "••••••";

export type BalanceSectionVariants = "balance" | "received";

export interface BalanceSectionProps {
  title: string;
  value: number;
  variant: BalanceSectionVariants;
  isLoading: boolean;
  isValuesVisible: boolean;
}

const loadingText: Record<BalanceSectionVariants, string> = {
  balance: "Buscando saldo...",
  received: "Buscando recebidos...",
};

export function BalanceSection({
  title,
  value,
  variant,
  isLoading,
  isValuesVisible,
}: BalanceSectionProps) {
  const balanceValue = isValuesVisible
    ? formatCurrency(value ?? 0, {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : SECRET_VALUE;

  const balanceValueTitle = isLoading
    ? loadingText[variant]
    : `R$ ${balanceValue}`;

  return (
    <S.Wrapper $variant={variant}>
      <S.Title>{title}</S.Title>
      <S.ValueWrapper title={balanceValueTitle}>
        <S.Prefix>R$</S.Prefix>
        {isLoading ? (
          <S.ValueSkeleton testId="skeleton-value" />
        ) : (
          <S.Value data-testid="value">{balanceValue}</S.Value>
        )}
      </S.ValueWrapper>
    </S.Wrapper>
  );
}
