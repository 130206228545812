import { DigitalLinkData } from "@/api/useChargesList";
import { NewChargeDrawer } from "@/components";
import { useToggle } from "@/hooks";
import { useNewCharge } from "@/stores/NewCharge";
import { getChargeRoutesData } from "@/utils/utility";
import { useNavigate } from "react-router-dom";
import { CardsList } from "./CardsList";
import * as S from "./ChargesPerPeriod.styles";

export type DrawerDetailsState = DigitalLinkData;

const routes = getChargeRoutesData();

export function ChargesPerPeriod() {
  const { resetNewChargeStore } = useNewCharge();
  const navigate = useNavigate();

  const [isNewChargeOpen, setIsNewChargeOpen] = useToggle();

  function handleNewChargeSubmit() {
    navigate(routes.newCharge);
  }

  function handleOpenNewChargeDrawer() {
    resetNewChargeStore();
    setIsNewChargeOpen();
  }

  return (
    <S.Container>
      <CardsList setIsNewChargeOpen={setIsNewChargeOpen} />

      <S.NewChargeButton
        testId="new-charge" // button-new-charge
        onClick={handleOpenNewChargeDrawer}
        variant="primary"
      >
        Nova cobrança
      </S.NewChargeButton>

      <NewChargeDrawer
        isOpen={isNewChargeOpen}
        onDismiss={setIsNewChargeOpen}
        onSubmit={handleNewChargeSubmit}
      />
    </S.Container>
  );
}
