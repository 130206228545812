import { breakpoints } from "@/styles/breakpoints";
import {
  Button as VitalButton,
  Checkbox as VitalCheckbox,
  Input as VitalInput,
} from "@hyperlocal/vital";
import { css, styled } from "styled-components";

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.neutral.white};
  padding: ${({ theme }) => theme.spacings.stack.x2s}; // 24px
  border-radius: ${({ theme }) => theme.borderRadius.sm}; // 8px

  ${breakpoints.mobile} {
    padding: ${({ theme }) => theme.spacings.stack.x3s}; // 16px
    border-radius: 0;

    position: absolute;
    inset: 0;
    z-index: 10;

    overflow: hidden auto;

    display: none;

    &[data-state="open"] {
      display: block;
    }
  }
`;

export const Title = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacings.inline.xs}; // 16px

  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: ${({ theme }) => theme.lineHeights.md};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.neutral.darkest};

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

export const ChipsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.stack.nano}; // 8px

  overflow-x: auto;
  white-space: nowrap;

  ${breakpoints.mobile} {
    // Essa margem negativa junto com o padding é para melhorar o overflow sem
    // precisar remover o padding de 16px do componente pai e depois adicionar
    // em todos os filhos.
    margin: 0 -16px;
    padding: 0 16px;

    margin-bottom: 16px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const SearchInputWrapper = styled.div`
  margin: 16px 0 28px;

  ${breakpoints.mobile} {
    display: none;
  }
`;

export const Input = styled(VitalInput)`
  box-sizing: content-box;
`;

export const DateInputsWrapper = styled.div`
  margin-bottom: 28px;

  display: flex;
  gap: ${({ theme }) => theme.spacings.stack.x3s}; // 16px
`;

export const CheckboxWrapper = styled.div`
  margin-bottom: 16px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  white-space: nowrap;

  ${breakpoints.mobile} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Checkbox = styled(VitalCheckbox)`
  padding: 10px;
`;

const sharedButtonStyles = css`
  justify-content: center;
  width: 100%;
`;

export const FilterButton = styled(VitalButton)`
  ${sharedButtonStyles}

  margin-top: ${({ theme }) => theme.spacings.inline.x2s}; // 8px
  display: none;

  ${breakpoints.mobile} {
    display: flex;
  }
`;

export const CleanFiltersButton = styled(VitalButton)`
  ${sharedButtonStyles}

  margin: 0 auto;
`;
