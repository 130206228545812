import { FavoriteCharge } from "@/components/FavoriteCharge";
import { ShareCharge } from "@/components/ShareCharge";
import { PixChargeData } from "@/stores/NewCharge/types";
import { formatCurrency, toast } from "@/utils/utility";
import * as S from "../../NewChargeReceipt.styles";

export function PixReceipt(props: PixChargeData) {
  const onCopy = async () => {
    try {
      await navigator.clipboard.writeText(props.pixUrl);
    } catch (error) {
      toast.error("Não foi possível copiar o código");
    }
  };
  return (
    <S.Container>
      {!!props.amount && (
        <>
          <S.Title>Valor do Pix</S.Title>
          <S.Amount>{formatCurrency(props.amount)}</S.Amount>
        </>
      )}
      <S.PixQRCode
        src={`data:image/png;base64,${props.imageQrCodeBase64}`}
        alt="QRCode"
      />
      <S.DataTitle data-align="flex-start" data-color="darkest">
        Pix copia e cola
      </S.DataTitle>
      <S.DataTitle data-align="flex-start">{props.pixUrl}</S.DataTitle>
      <S.Button onClick={onCopy} icon="GeralGeralCopy" variant="link">
        Copiar código
      </S.Button>
      <S.Divider />
      <S.ContainerTitle>Informações da cobrança</S.ContainerTitle>
      {!!props.description && (
        <>
          <S.DataTitle data-align="flex-start" data-color="darkest">
            Mensagem da cobrança
          </S.DataTitle>
          <S.DataTitle data-align="flex-start" data-color="darkest">
            {props.description}
          </S.DataTitle>
          <S.Divider />
        </>
      )}
      <S.DataList>
        <S.DataTitle>Favorecido</S.DataTitle>
        <S.DataDescription>{props.receiver}</S.DataDescription>
        <S.DataTitle>Chave PIX</S.DataTitle>
        <S.DataDescription>{props.pixKey}</S.DataDescription>
        <S.DataTitle>Instituição</S.DataTitle>
        <S.DataDescription>396 - HUB IP S.A.</S.DataDescription>
      </S.DataList>

      <FavoriteCharge />
      <ShareCharge />
    </S.Container>
  );
}
