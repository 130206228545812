import { breakpoints } from "@/styles/breakpoints";
import { Divider as VitalDivider } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import styled, { css } from "styled-components";

const commonColorOnStatus = css`
  &[data-status="P"] {
    color: ${({ theme }) => theme.palette.status.warning.warningDark};
  }
  &[data-status="A"] {
    color: ${({ theme }) => theme.palette.status.warning.warningDark};
  }
  &[data-status="E"] {
    color: ${({ theme }) => theme.palette.status.success.successDark};
  }
  &[data-status="C"] {
    color: ${({ theme }) => theme.palette.status.error.errorDark};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { spacings } }) =>
    `${spacings.inset.xl} 0 ${spacings.inset.lg} 0`};
  gap: ${({ theme }) => theme.spacings.inset.md};
`;

export const Avatar = styled(Icon)`
  width: ${({ theme }) => theme.spacings.stack.x2s};
  height: ${({ theme }) => theme.spacings.stack.x2s};
  flex-shrink: 0;

  box-sizing: content-box;
  padding: 20px;
  border-radius: ${({ theme }) => theme.borderRadius.circle}; // 50%
  align-self: center;

  &[data-status="P"] {
    background: ${({ theme }) => theme.palette.status.warning.warningLight};

    color: ${({ theme }) => theme.palette.status.warning.warningDark};
  }
  &[data-status="A"] {
    background: ${({ theme }) => theme.palette.status.warning.warningLight};

    color: ${({ theme }) => theme.palette.status.warning.warningDark};
  }
  &[data-status="E"] {
    background: ${({ theme }) => theme.palette.status.success.successLight};

    color: ${({ theme }) => theme.palette.status.success.successDark};
  }
  &[data-status="C"] {
    background: ${({ theme }) => theme.palette.status.error.errorLight};

    color: ${({ theme }) => theme.palette.status.error.errorDark};
  }
`;

export const StatusTitleContainer = styled.span`
  text-align: center;
`;

export const StatusTitle = styled.h5`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  &:first-child {
    color: ${({ theme }) => theme.palette.neutral.darkest};
  }

  &:last-child {
    ${commonColorOnStatus}
  }
`;

export const ChipContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.inset.md};
  justify-content: center;
`;

export const Divider = styled(VitalDivider)`
  width: 100%;
  background: ${({ theme }) => theme.palette.neutral.light};
`;

export const InstallmentInfoContainer = styled.div`
  padding: ${({ theme: { spacings } }) => `${spacings.inset.md} 0 `};
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-row-gap: ${({ theme }) => theme.spacings.inset.md};
`;

export const InsTallmentInfoTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.palette.neutral.darkest};
  text-align: start;

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.x2s};
  }
`;

export const InstallmentInfoData = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.palette.neutral.dark};
  text-align: end;

  &[data-direction="start"] {
    align-self: flex-start;
  }

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.x2s};

    &[data-direction="start"] {
      display: none;
    }
  }
`;

export const InstallmentAmount = styled.h5`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.neutral.darkest};
  text-align: end;

  ${commonColorOnStatus}

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

export const OperationId = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.x2s};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.palette.neutral.darkest};
  margin-top: ${({ theme }) => theme.spacings.inset.md};
  align-self: flex-start;

  ${breakpoints.mobile} {
    display: none;
  }
`;
