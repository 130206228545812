import { useNewCharge } from "@/stores/NewCharge";
import { CustomizedChargeData } from "@/stores/NewCharge/types";
import {
  BoletoReceipt,
  DigitalSaleLinkReceipt,
  PixReceipt,
  RecurrenceReceipt,
} from "./components";

const ComponentMap: Record<
  CustomizedChargeData["type"],
  (props: CustomizedChargeData) => JSX.Element
> = {
  BOLETO: BoletoReceipt,
  PIX: PixReceipt,
  DIGITAL_SALE_LINK: DigitalSaleLinkReceipt,
  DIGITAL_SALE: DigitalSaleLinkReceipt,
  DIGITAL_SALE_RECURRENCE: RecurrenceReceipt,
};

export function NewChargeReceipt() {
  const { chargeData } = useNewCharge();

  const ComponentToRender = ComponentMap[chargeData.type];

  return <ComponentToRender {...chargeData} />;
}
