import { City, UF, ZipCode } from "@/api/useAddress.types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
import cepPromise from "cep-promise";

export function useCep(cep: string, options?: UseQueryOptions<ZipCode>) {
  return useQuery<ZipCode>({
    ...options,
    queryKey: ["cep", cep],
    queryFn: () => cepPromise(cep),
  });
}

export const getCities = async (uf: string) => {
  try {
    const response = await axios.get<City[]>(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getStates = async () => {
  try {
    const response = await axios.get<UF[]>(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados",
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const useGetStates = (options?: UseQueryOptions<UF[]>) => {
  return useQuery({
    ...options,
    queryKey: ["getStates"],
    queryFn: () => getStates(),
  });
};
