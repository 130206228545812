import { Skeleton } from "@/components";
import * as S from "./CardButtonSkeleton.styles";

export function CardButtonSkeleton() {
  return (
    <S.Container>
      <S.LeftWrapper>
        <S.IconSkeleton />
        <S.Box>
          <S.TypeAndStatusSkeleton />
          <S.PersonSkeleton />
        </S.Box>
      </S.LeftWrapper>

      <S.RightWrapper>
        <S.InstallmentSkeleton />
        <Skeleton width="88px" height="24px" borderRadius="8px" />
      </S.RightWrapper>
    </S.Container>
  );
}
