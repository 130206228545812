import { DigitalSaleRecurrenceChargeData } from "@/stores/NewCharge/types";
import { RECURRENCE_CYCLES_OPTION } from "@/utils/constants";
import { formatCurrency } from "@/utils/utility";
import * as S from "../../NewChargeReceipt.styles";

export function RecurrenceReceipt(props: DigitalSaleRecurrenceChargeData) {
  const {
    amount,
    created,
    description,
    firstDueDate,
    recurrenceCycle,
    saleMaxInstallments,
  } = props;
  return (
    <S.Container>
      <S.Title>Valor da recorrência</S.Title>
      <S.Amount>{formatCurrency(amount)}</S.Amount>
      <S.Divider />
      <S.ContainerTitle>Informações da cobrança</S.ContainerTitle>
      <S.DataList>
        <S.DataTitle>Descrição</S.DataTitle>
        <S.DataDescription>{description}</S.DataDescription>
        <S.DataTitle>Tipo de cobrança</S.DataTitle>
        <S.DataDescription>Recorrente</S.DataDescription>
        <S.DataTitle>Pagamento em até</S.DataTitle>
        <S.DataDescription>
          {saleMaxInstallments}x de {formatCurrency(amount)}
        </S.DataDescription>
        <S.DataTitle>Ciclo da recorrência</S.DataTitle>
        <S.DataDescription>
          {RECURRENCE_CYCLES_OPTION[recurrenceCycle]}
        </S.DataDescription>
        <S.DataTitle>Criado em</S.DataTitle>
        <S.DataDescription>{created.toLocaleDateString()}</S.DataDescription>
        <S.DataTitle>1º vencimento em</S.DataTitle>
        <S.DataDescription>
          {firstDueDate.toLocaleDateString()}
        </S.DataDescription>
      </S.DataList>
      <S.Divider />
    </S.Container>
  );
}
