import * as S from "./StatusReport.styles";

export type StatusReportProps = {
  title: string;
  amount: string;
} & ({ type: "paid"; subTitle: string } | { type: "pending" });

export function StatusReport(props: StatusReportProps) {
  return (
    <S.Container>
      <S.Wrapper>
        <S.Avatar data-state={props.type} icon="GeralMoneyPayReceived" />
        <div>
          <S.Title>{props.title}</S.Title>
          {props.type === "paid" && <S.SubTitle>{props.subTitle}</S.SubTitle>}
        </div>
      </S.Wrapper>
      <S.Amount data-testid="status-report-amount" data-state={props.type}>
        {props.amount}
      </S.Amount>
    </S.Container>
  );
}
