/* eslint-disable @typescript-eslint/ban-ts-comment */

import { Drawer } from "@hyperlocal/vital";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";

export interface TokenDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  dispatchOnMount: () => void;
}

export function TokenDrawer({
  isOpen,
  onClose,
  dispatchOnMount,
}: TokenDrawerProps) {
  return (
    <Drawer
      testId={{ drawer: "token-drawer" }}
      title="Token"
      position="right"
      onDismiss={onClose}
      open={isOpen}
    >
      {isOpen && (
        <Parcel
          // @ts-ignore
          config={() => System.import("@hyperlocal/banking-token-validation")}
          mountParcel={mountRootParcel}
          parcelDidMount={dispatchOnMount}
        />
      )}
    </Drawer>
  );
}
