import { Button as VitalButton } from "@hyperlocal/vital";
import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => `0 ${theme.spacings.inset.lg}`}; // 0 24px
  padding-top: ${({ theme }) => theme.spacings.inset.md};
`;

export const Button = styled(VitalButton)`
  width: 100%;
  gap: ${({ theme }) => theme.spacings.inset.md}; //16px
  justify-content: center;
  color: ${({ variant, theme }) =>
    variant === "link" ? theme.palette.status.error.errorDark : "auto"};
`;
