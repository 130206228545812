import { Button, Input } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";

export const ShareCharge = () => {
  return (
    <div className="w-full space-y-4 border-t py-4">
      <h5 className="text-sm font-bold">Como deseja enviar o comprovante?</h5>

      <div className="flex flex-col gap-2">
        <div className="w-full">
          <label>E-mail do cliente</label>
          <Input type="text" className="m-0 box-content" inputMode="email" />
        </div>

        <div className="w-full">
          <label>Celular do cliente</label>
          <Input type="text" className="m-0 box-content" inputMode="tel" />
        </div>
      </div>

      <Button className="w-full justify-center">
        <Icon name="ComputersShare" />
        Compartilhar
      </Button>
    </div>
  );
};
