import { breakpoints } from "@/styles/breakpoints";
import { Input as VitalInput } from "@hyperlocal/vital";
import styled from "styled-components";

export const Container = styled.div`
  // Essa margem negativa de -8px é para reduzir a margin do Input de pesquisa.
  margin: 0 16px -8px;

  display: none;

  ${breakpoints.mobile} {
    display: block;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 8px;
`;

export const Title = styled.h4`
  margin-bottom: 8px;

  font-size: ${({ theme }) => theme.fontSizes.x2s};
  line-height: ${({ theme }) => theme.lineHeights.sm};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.palette.neutral.darker};
`;

export const Value = styled.span`
  display: block;

  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: ${({ theme }) => theme.lineHeights.default};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.neutral.darkest};
`;

export const Input = styled(VitalInput)`
  box-sizing: content-box;
`;
