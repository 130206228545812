import { useCancelLink } from "@/api/useCancelLink";
import { queryClient } from "@/lib";
import { useNewCharge } from "@/stores/NewCharge";
import { canCancelLink } from "@/utils/allowedRoles";
import { formatDocument, getDigitalLinkStatus, unMask } from "@/utils/format";
import {
  formatCurrency,
  ProtectedComponent,
  queries,
  useAccountStore,
} from "@/utils/utility";
import { Drawer } from "@hyperlocal/vital";
import { toast } from "@hyperlocal/vital2";
import { DrawerDetailsState } from "../ChargesPerPeriod";
import { Icon } from "../Icon";
import * as S from "./ChargeDetailsDrawer.styles";

const { chargeKeys } = queries;

interface ChargeDetailsDrawerContentProps {
  details: DrawerDetailsState;
}

function ChargeDetailsDrawerContent({
  details,
}: ChargeDetailsDrawerContentProps) {
  return (
    <>
      <S.Header>
        <Icon
          type="Venda digitada"
          status={getDigitalLinkStatus(details)}
          size={24}
          padding={20}
        />
        <S.Title>Link de cobrança</S.Title>
        <S.Status data-status={getDigitalLinkStatus(details)}>
          {getDigitalLinkStatus(details)}
        </S.Status>
      </S.Header>

      <S.Divider />

      <S.Wrapper>
        <S.LeftText>Valor da cobrança</S.LeftText>
        <S.Status data-status={getDigitalLinkStatus(details)}>
          {formatCurrency(details.amount)}
        </S.Status>
      </S.Wrapper>

      <S.Wrapper>
        <S.LeftText>Data de criação</S.LeftText>
        <S.RightText>
          {new Date(details.createdAt).toLocaleDateString()}
        </S.RightText>
      </S.Wrapper>

      <S.Wrapper>
        <S.LeftText>Data de vencimento</S.LeftText>
        <S.RightText>
          {new Date(details.expirationDate).toLocaleDateString()}
        </S.RightText>
      </S.Wrapper>

      <S.Wrapper>
        <S.LeftText>Tipo de transação</S.LeftText>
        <S.RightText>Link de cobrança</S.RightText>
      </S.Wrapper>

      <S.Divider />

      <S.Wrapper>
        <S.LeftText>Pagador</S.LeftText>
        <S.RightText>{details.client.name}</S.RightText>
      </S.Wrapper>

      <S.Wrapper>
        <S.LeftText>CPF/CNPJ</S.LeftText>
        <S.RightText>{formatDocument(details.client.document)}</S.RightText>
      </S.Wrapper>

      <S.Divider />

      <div>
        <S.SmallTitle>Id da Operação</S.SmallTitle>
        <S.RightText>{details.guidLink}</S.RightText>
      </div>
    </>
  );
}

type ChargeDetailsDrawerProps = React.ComponentProps<typeof Drawer> & {
  details: DrawerDetailsState;
  setIsNewChargeOpen: () => void;
};

export function ChargeDetailsDrawer({
  details,
  onDismiss,
  setIsNewChargeOpen,
  ...props
}: ChargeDetailsDrawerProps) {
  const { updatePayerData } = useNewCharge();

  const { currentAccountId } = useAccountStore();

  const cancelMutation = useCancelLink({
    onSuccess: () => {
      queryClient.invalidateQueries(chargeKeys.infiniteList(currentAccountId));
      onDismiss();
      toast({
        title: "Cobrança cancelada",
        variant: "success",
      });
    },
  });

  function handleClose() {
    if (cancelMutation.isLoading) return;
    onDismiss();
  }

  function handleOpenNewChargeDrawer() {
    handleClose();
    setIsNewChargeOpen();

    updatePayerData({
      name: details.client.name || "",
      cellphone:
        details.client.mobilePhoneArea + details.client.mobilePhoneNumber || "",
      email: details.client.email || "",
      document: unMask(details.client.document) || "",
    });
  }

  async function handleCancelLink() {
    await cancelMutation.mutateAsync({
      guidLink: details.guidLink,
    });
  }

  const isPending = getDigitalLinkStatus(details) === "Pendente";

  return (
    <Drawer title="Detalhe da cobrança" onDismiss={handleClose} {...props}>
      <S.Container>
        <S.Content>
          <ChargeDetailsDrawerContent details={details} />
        </S.Content>

        <div>
          <S.Button
            testId="open-new-charge-drawer" // button-open-new-charge-drawer
            onClick={handleOpenNewChargeDrawer}
            variant="primary"
          >
            Cobrar novamente
          </S.Button>
          {isPending && (
            <ProtectedComponent allowedRoles={canCancelLink}>
              <S.Button
                onClick={handleCancelLink}
                variant="secondary"
                isLoading={cancelMutation.isLoading}
              >
                Cancelar cobrança
              </S.Button>
            </ProtectedComponent>
          )}
        </div>
      </S.Container>
    </Drawer>
  );
}
