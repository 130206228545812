import { AccessGroupName } from "@/utils/utility";

export const canCancelLink: AccessGroupName[] = [
  "Administrador",
  "Moderador",
  "Proprietário",
];

export const canAccessCharge: AccessGroupName[] = [
  "Administrador",
  "Moderador",
  "Proprietário",
  "Financeiro",
];
