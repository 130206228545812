import { breakpoints } from "@/styles/breakpoints";
import { createGlobalStyle } from "styled-components";

const rootId = "#single-spa-application\\:\\@hyperlocal\\/banking-charge";

export const GlobalStyles = createGlobalStyle`
  body {
    font-family: ${({ theme }) => theme.fonts.base};
  }

  #hyperlocal-main {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  ${rootId} {
    flex: 1;
    background: ${({ theme }) => theme.palette.neutral.lighter};
    overflow-y: auto;

    ${breakpoints.mobile} {
      background: ${({ theme }) => theme.palette.neutral.white};
    }
  }
`;
