import { breakpoints } from "@/styles/breakpoints";
import { Button as VitalButton } from "@hyperlocal/vital";
import styled, { css } from "styled-components";

const commonStyle = css`
  &[data-align="start"] {
    text-align: start;
  }
  &[data-align="end"] {
    text-align: end;
  }
  &[data-align="center"] {
    text-align: center;
  }
`;

export const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacings.inset.lg}; //24px
  gap: ${({ theme }) => theme.spacings.inset.x2s}; //8px
  display: flex;
  flex-direction: column;
`;

export const Button = styled(VitalButton)`
  justify-content: center;
  width: 100%;

  margin-top: ${({ theme, variant }) =>
    variant === "primary" ? theme.spacings.inset.md : "auto"};
`;

export const SimulationContainer = styled.div`
  height: 100%;
  margin: ${({ theme }) => `0 ${theme.spacings.inset.md} 0 0`};

  ${breakpoints.mobile} {
    margin: 0;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  margin-top: ${({ theme }) => theme.spacings.inset.md};
`;

export const Th = styled.th`
  ${commonStyle};
  padding-bottom: ${({ theme }) => theme.spacings.inset.md};
`;

export const Td = styled.td`
  ${commonStyle};
  padding: ${({ theme }) => `${theme.spacings.inset.x2s} 0`};

  &:first-child,
  &:last-child {
    padding: ${({ theme }) => `0 ${theme.spacings.inset.md}`};
  }

  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.palette.neutral.darkest};

  &[data-type="value"] {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.palette.primary.main};
  }

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.x2s};
  }
`;

export const Tr = styled.tr`
  border-radius: ${({ theme }) => theme.borderRadius.xs};

  &:nth-child(odd) {
    background: ${({ theme }) => theme.palette.neutral.lighter};
  }
`;

export const DrawerContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
