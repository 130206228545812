import { InstallmentDetail } from "@/components";
import * as S from "./InstallmentDetailsPage.style";

export function InstallmentDetailsPage() {
  return (
    <S.Container>
      <InstallmentDetail />
    </S.Container>
  );
}
