import { breakpoints } from "@/styles/breakpoints";
import { styled } from "styled-components";

export const Container = styled.div`
  max-width: 1248px;
  padding: ${({ theme }) => theme.spacings.inset.xl};
  margin: 0 auto;

  ${breakpoints.mobile} {
    padding: 0;
    background: ${({ theme }) => theme.palette.neutral.white};
    height: 100%;
    padding-bottom: 0;
  }
`;

export const Header = styled.header`
  ${breakpoints.mobile} {
    display: none;
  }
`;

export const TitleContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.inline.sm}; // 24px
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Title = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  line-height: ${({ theme }) => theme.lineHeights.default};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.neutral.darkest};
`;

export const Section = styled.section`
  margin-top: ${({ theme }) => theme.spacings.inset.lg}; //24px

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacings.inset.md}; //16px

  position: relative;
  overflow: hidden;

  ${breakpoints.mobile} {
    display: none;
  }
`;

export const LeftWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.inline.xs};
  max-height: 700px;

  ${breakpoints.mobile} {
    height: 100%;
    max-height: none;
  }
`;

export const MobileView = styled.div`
  display: none;
  ${breakpoints.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

export const DesktopView = styled.div`
  display: flex;
  height: 100%;

  ${breakpoints.mobile} {
    display: none;
  }
`;

export const RightWrapper = styled.div`
  background: ${({ theme }) => theme.palette.neutral.white};
  padding: ${({ theme }) => `${theme.spacings.inset.lg} 0`}; //24px 0
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  inset: 0;
  grid-column: 2;

  ${breakpoints.mobile} {
    height: 100%;
    max-height: none;
    overflow: hidden;
    display: flex;
    position: relative;
  }
`;

export const BalanceContainer = styled.div`
  ${breakpoints.mobile} {
    display: none;
  }
`;
