import { API } from "@/utils/utility";
import { AxiosInstance, AxiosRequestHeaders } from "axios";

export const api = API.manager as unknown as AxiosInstance;

export const pixApi = API.pix as unknown as AxiosInstance;

export const pixDefaultHeaders = {
  ...(API.pix?.defaults.headers as unknown as AxiosRequestHeaders),
};
