import { breakpoints } from "@/styles/breakpoints";
import { Input as VitalInput, Switch as VitalSwitch } from "@hyperlocal/vital";
import styled, { css } from "styled-components";

interface AddressContainerProps {
  gridTemplateColumns: string;
}

interface GridAreaProp {
  gridArea?: string;
}

const commonInputStyle = css`
  input {
    box-sizing: inherit;
    width: 100%;
    height: 100%;
  }

  select {
    box-sizing: inherit;
    width: 100%;
    height: 100%;
    padding: 9.5px 16px;
  }
`;

export const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacings.inset.lg};
`;

export const Title = styled.h5`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.neutral.darkest};
  margin-bottom: ${({ theme }) => theme.spacings.inset.md};
`;

export const ChargeDataContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${({ theme }) => theme.spacings.inset.md};
  margin-bottom: ${({ theme }) => theme.spacings.inset.md};

  ${commonInputStyle};

  ${breakpoints.mobile} {
    grid-template-columns: 1fr;
  }
`;

export const Input = styled(VitalInput)`
  box-sizing: content-box;
`;

export const InputContainer = styled.div<GridAreaProp>`
  grid-area: ${({ gridArea }) => gridArea};

  transition: height 0.3s ease-in-out;

  overflow: hidden;

  &[data-visible="true"] {
    height: 80px;
  }

  &[data-visible="false"] {
    height: 0;
  }
`;

export const CustomizedGridContainer = styled.div<AddressContainerProps>`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  grid-column-gap: ${({ theme }) => theme.spacings.inset.md};
  grid-row-gap: ${({ theme }) => theme.spacings.inset.lg};

  transition: height 0.3s ease-in-out;

  overflow: hidden;

  &[data-visible="true"] {
    height: 80px;
    opacity: 1;
  }

  &[data-visible="false"] {
    height: 0;
    opacity: 0;
  }
`;

export const SwitchContainer = styled.div`
  align-self: flex-start;
  margin: ${({ theme }) => `${theme.spacings.inset.lg} 0`};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.inset.md};

  & > label {
    cursor: pointer;
  }
`;

export const Switch = styled(VitalSwitch)`
  cursor: pointer;
`;

export const AddressContainer = styled.div`
  display: grid;
  grid-column-gap: ${({ theme }) => theme.spacings.inset.md};
  grid-row-gap: ${({ theme }) => theme.spacings.inset.lg};
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: ${({ theme }) => theme.spacings.inset.md};
  ${commonInputStyle};

  grid-template-areas:
    "zipcode street street street"
    "neighborhood neighborhood number state"
    "city city complement complement";

  ${breakpoints.mobile} {
    grid-column-gap: ${({ theme }) => theme.spacings.inset.x2s}; //8px
    grid-row-gap: ${({ theme }) => theme.spacings.inset.md}; //16px
    grid-template-areas:
      "zipcode zipcode cepFinder cepFinder"
      "street street street number"
      "neighborhood neighborhood neighborhood neighborhood"
      "city city state state"
      "complement complement complement complement";
  }
`;

export const CEPAnchor = styled.a`
  grid-area: cepFinder;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.dark};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  text-decoration: underline;
  ${breakpoints.desktop} {
    display: none;
  }
`;

export const CreditCardContainer = styled.div`
  display: grid;
  grid-column-gap: ${({ theme }) => theme.spacings.inset.md}; //16px
  grid-row-gap: ${({ theme }) => theme.spacings.inset.lg}; //24px
  grid-template-columns: 1fr 1fr 1fr 1fr;

  grid-template-areas:
    "cardName cardName cardNumber cardNumber"
    "cardExpiration CVV creditCardGuid creditCardGuid";

  transition: height 0.3s ease-in-out;

  overflow: hidden;

  &[data-visible="true"] {
    height: 185px;
  }

  &[data-visible="false"] {
    height: 0;
  }

  ${breakpoints.mobile} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${({ theme }) => theme.spacings.inset.x2s}; //8px
    grid-row-gap: ${({ theme }) => theme.spacings.inset.md}; //16px
    grid-template-areas:
      "cardName cardName"
      "cardNumber cardNumber"
      "cardExpiration CVV"
      "creditCardGuid creditCardGuid";

    &[data-visible="true"] {
      height: 370px;
    }

    &[data-visible="false"] {
      height: 0;
    }
  }
`;
