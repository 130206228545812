import { Suspense } from "react";
import { ErrorFallback, LoadingFallback } from "@/components";
import { queryClient } from "@/lib/react-query";
import { Routes } from "@/routes";
import { GlobalStyles } from "@/styles/global";
import { getVertical, Hooks, themeProvider } from "@/utils/utility";
import { Provider } from "@hyperlocal/vital-theme";
import { Toaster } from "@hyperlocal/vital2";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

export function App() {
  const theme = getVertical();
  const isMobile = Hooks.useMediaQuery("mobile");

  return (
    <Provider theme={theme}>
      <ThemeProvider theme={themeProvider}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <BrowserRouter>
            <Suspense fallback={<LoadingFallback />}>
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools />
                <Routes />
              </QueryClientProvider>
              <Toaster position={isMobile ? "bottom-center" : "top-right"} />
            </Suspense>
          </BrowserRouter>
        </ErrorBoundary>
        <GlobalStyles />
      </ThemeProvider>
    </Provider>
  );
}
