type GuidTokenKey =
  | "CANCEL_INSTALLMENT_LIST"
  | "CONTROL_INSTALLMENT"
  | "CANCEL_SINGLE_INSTALLMENT"
  | "ANTICIPATE_INSTALLMENT"
  | "POSTPONE_INSTALLMENT"
  | "EDIT_RECURRENCE_CARD"
  | "GENERATE_BOLETO"
  | "CREATE_DIGITAL_SALE_LINK"
  | "GENERATE_HIDDEN_TOKEN"
  | "CREATE_DIGITAL_SALE_RECURRENCE";

export const guidTokenType: Record<GuidTokenKey, string> = {
  CANCEL_INSTALLMENT_LIST: "766578E1-CE3F-45DA-A4B6-8A3597DC1E71",
  CANCEL_SINGLE_INSTALLMENT: "a5f4a1a9-bb34-4d05-a591-4978bd63012d",
  CONTROL_INSTALLMENT: "285864FC-0EC3-4679-BD99-68F6CE9A59C1",
  ANTICIPATE_INSTALLMENT: "7E957ABF-78D4-4491-9652-2D289F2D9716",
  POSTPONE_INSTALLMENT: "5EF3C6BA-D10B-442B-A2C6-0F72EF8DC4A5",
  EDIT_RECURRENCE_CARD: "262685ED-79AF-4674-AEEC-1E28ADD56E25",
  GENERATE_BOLETO: "08b3b581-a4a4-42d3-a691-b760ed045241",
  CREATE_DIGITAL_SALE_LINK: "EE1B369B-6994-4810-AA8C-5F2279C99239",
  GENERATE_HIDDEN_TOKEN: "d9f5e4a8-9183-4fc8-a852-ead21c55f869",
  CREATE_DIGITAL_SALE_RECURRENCE: "ACAC2E1E-7E0B-4B86-A45D-54CAAC46F1BF",
};
