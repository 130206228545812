import {
  DigitalLinkData,
  DigitalLinkDataResponse,
  GetChargesListProps,
  transformDigitalLinkResponse,
} from "@/api/useChargesList";
import { api } from "@/lib";
import { useChargesFilter } from "@/stores/ChargesFilter";
import { queries, toast, useAccountStore } from "@/utils/utility";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

const { chargeKeys } = queries;

const THIRTY_SECONDS = 1000 * 30;

async function getChargesList({
  accountId,
  limit = 10,
  page = 1,
}: GetChargesListProps) {
  const params = new URLSearchParams();

  params.set("accountId", accountId);

  if (limit) params.set("limit", limit.toString());

  if (page) params.set("page", page.toString());

  try {
    const response = await api.get<DigitalLinkDataResponse>(
      "/paymentLink/getAll",
      {
        params,
      },
    );

    return response.data;
  } catch (error) {
    toast.error("Não foi possível buscar as cobranças.");
    throw new Error(error);
  }
}

export function useChargesList() {
  const { currentAccountId } = useAccountStore();

  return useQuery({
    queryKey: chargeKeys.list(currentAccountId),
    queryFn: () =>
      getChargesList({
        accountId: currentAccountId,
        limit: 10,
      }),
    keepPreviousData: true,
    staleTime: THIRTY_SECONDS,
    suspense: false,
    refetchOnWindowFocus: true,
  });
}

export function useInfiniteCharges() {
  const { currentAccountId } = useAccountStore();

  return useInfiniteQuery({
    queryKey: chargeKeys.infiniteList(currentAccountId),
    queryFn: ({ pageParam }) =>
      getChargesList({
        accountId: currentAccountId,
        limit: 50,
        page: pageParam,
      }),
    select: (data) => {
      const result = data.pages.reduce<DigitalLinkData[]>(
        (prev, cur) => [...prev, ...cur.items],
        [],
      );

      return {
        pages: result,
        pageParams: data.pageParams,
      };
    },
    getNextPageParam: (lastPage) => {
      return !lastPage.last ? lastPage.nextPage : undefined;
    },
  });
}

export function useInfiniteChargeList() {
  const { filterState } = useChargesFilter();
  const { data: chargeData, ...rest } = useInfiniteCharges();

  const transformedResponse = transformDigitalLinkResponse(
    chargeData.pages,
    filterState,
  );

  return {
    data: transformedResponse,
    ...rest,
  };
}
