import { useEffect } from "react";
import * as svgs from "@/assets/svgs";
import { Breadcrumb, BreadcrumbItem, ScrollArea, Tabs } from "@/components";
import { useSetHeader } from "@/hooks";
import { LastCharges } from "@/pages/NewCharge/components/LastCharges/LastCharges";
import { useNewCharge } from "@/stores/NewCharge";
import { getChargeRoutesData } from "@/utils/utility";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import { ChargeWrapper } from "./components";
import * as S from "./NewCharge.styles";

const breadcrumbs: BreadcrumbItem[] = [
  { name: "Cobrança", path: "charge" },
  { name: "Nova Cobrança", path: "newCharge" },
];

const routes = getChargeRoutesData();

export function NewCharge() {
  const navigate = useNavigate();
  const { payerData } = useNewCharge();
  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get("tab") || "new-charge";

  const handleChangeTab = (value: string) => {
    searchParams.set("tab", value);
    setSearchParams(searchParams);
  };

  useSetHeader({ title: "Nova cobrança", backRoute: "charge" });

  useEffect(() => {
    if (!payerData.document) {
      navigate(routes.charge);
    }
  }, [navigate, payerData.document]);

  if (!payerData.document) return null;

  return (
    <S.Container>
      <S.Header>
        <Breadcrumb breadcrumbs={breadcrumbs} />
        <S.TitleContainer>
          <Link to={routes.charge}>
            <svgs.ArrowLeft />
          </Link>
          <S.Title>Nova cobrança</S.Title>
        </S.TitleContainer>
      </S.Header>

      <S.MobileView className="flex-1" id="mobile-view">
        <Tabs.Root
          defaultValue="new-charge"
          value={tab}
          onValueChange={handleChangeTab}
          className="h-full overflow-hidden"
        >
          <Tabs.List className="w-full">
            <Tabs.Trigger className="w-full" value="new-charge">
              Nova cobrança
            </Tabs.Trigger>
            <Tabs.Trigger value="last-charges" className="w-full">
              Últimas cobranças
            </Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value="new-charge" className="h-full">
            <S.LeftWrapper>
              <ScrollArea className="h-full w-full bg-neutral-white p-4">
                <ChargeWrapper />
              </ScrollArea>
            </S.LeftWrapper>
          </Tabs.Content>
          <Tabs.Content value="last-charges" className="h-full">
            <S.RightWrapper>
              <LastCharges />
            </S.RightWrapper>
          </Tabs.Content>
        </Tabs.Root>
      </S.MobileView>

      <S.Section>
        <S.DesktopView>
          <S.LeftWrapper>
            <S.BalanceContainer>
              <Parcel
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                config={() => System.import("@hyperlocal/banking-balance")}
                mountParcel={mountRootParcel}
              />
            </S.BalanceContainer>
            <ScrollArea className="h-full w-full bg-neutral-white px-6 pt-6">
              <ChargeWrapper />
            </ScrollArea>
          </S.LeftWrapper>

          <S.RightWrapper>
            <LastCharges />
          </S.RightWrapper>
        </S.DesktopView>
      </S.Section>
    </S.Container>
  );
}
