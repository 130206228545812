import { breakpoints } from "@/styles/breakpoints";
import styled, { css } from "styled-components";

export const Button = styled.button`
  background: none;
  border: none;
  font: inherit;

  padding: ${({ theme }) => theme.spacings.squished.md}; // 12px 24px
  cursor: pointer;

  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.palette.neutral.lighter};
  border-radius: ${({ theme }) => theme.borderRadius.sm}; // 8px

  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacings.stack.x3s}; // 16px

  width: 100%;

  transition: box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);

  // Force o botão a renderizar através da GPU evitando pequenos pixels perdidos.
  transform: translateZ(0);

  &:hover {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.palette.primary.main};
  }

  ${breakpoints.mobile} {
    padding: ${({ theme }) => theme.spacings.squished.sm}; // 12px 16px
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.stack.x3s}; // 16px

  text-align: start;
`;

const sharedTextStyles = css`
  display: block;

  font-size: ${({ theme }) => theme.fontSizes.x2s};
  line-height: ${({ theme }) => theme.lineHeights.md};
  font-weight: ${({ theme }) => theme.fontWeights.regular};

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.x4s};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
`;

export const TypeAndStatus = styled.span`
  ${sharedTextStyles}

  color: ${({ theme }) => theme.palette.neutral.darkest};
`;

export const Person = styled.span`
  margin-top: 4px;

  display: block;

  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.md};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.palette.neutral.dark};

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.x2s};
    line-height: ${({ theme }) => theme.lineHeights.lg};
  }
`;

export const RightWrapper = styled.div`
  text-align: end;
`;

export const Installment = styled.span`
  ${sharedTextStyles}

  color: ${({ theme }) => theme.palette.neutral.dark};
`;

export const Amount = styled.span<{ $isStatusPaid: boolean }>`
  margin-top: 4px;

  display: block;

  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.md};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ $isStatusPaid, theme }) =>
    $isStatusPaid
      ? theme.palette.status.success.successDark
      : theme.palette.neutral.darkest};

  white-space: nowrap;

  ${breakpoints.mobile} {
    color: ${({ $isStatusPaid, theme }) =>
      $isStatusPaid
        ? theme.palette.status.success.successDark
        : theme.palette.neutral.darker};
  }
`;
