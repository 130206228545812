import { Skeleton } from "@/components";
import { breakpoints } from "@/styles/breakpoints";
import styled from "styled-components";

export const Container = styled.div`
  margin: 0 24px;
  position: relative;
  z-index: 1;

  overflow: hidden;

  ${breakpoints.mobile} {
    margin: 0 16px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 16px;

  & ~ & {
    margin-top: 16px;
  }

  ${breakpoints.mobile} {
    flex-direction: column;
    align-items: initial;

    gap: ${({ theme }) => theme.spacings.stack.quarck}; // 4px

    margin-bottom: 8px;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleSkeleton = styled(Skeleton)`
  border-radius: ${({ theme }) => theme.borderRadius.sm}; // 8px

  height: 30px;
  width: 128px;

  ${breakpoints.mobile} {
    height: 24px;
    width: 112px;
  }
`;

export const TodayTitleSkeleton = styled(TitleSkeleton)`
  width: 176px;

  ${breakpoints.mobile} {
    width: 160px;
  }
`;

export const WeekdayTextSkeleton = styled(Skeleton)`
  border-radius: ${({ theme }) => theme.borderRadius.sm}; // 8px

  height: 18px;
  width: 64px;

  display: none;

  ${breakpoints.mobile} {
    display: block;
  }
`;

export const ResumeSkeleton = styled(Skeleton)`
  border-radius: ${({ theme }) => theme.borderRadius.sm}; // 8px

  height: 20px;
  width: 192px;

  ${breakpoints.mobile} {
    height: 18px;
    width: 144px;
  }
`;
