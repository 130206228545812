import {
  AnticipateInstallment,
  CancelInstallment,
  CreditCardCompanies,
  EditRecurrenceCard,
  PostponeInstallment,
} from "@/api/useInstallmentDetail.types";
import { api, MutationConfig } from "@/lib";
import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";

const editRecurrenceCard = async ({
  guidAccount,
  guidDigitalSaleRecurrence,
  ...data
}: EditRecurrenceCard) => {
  try {
    const url = new URL(
      `Sales/${guidAccount}/Recurrence/Card/${guidDigitalSaleRecurrence}`,
      api.defaults.baseURL,
    );

    url.searchParams.append("api-version", "2.0");
    return api.put<unknown>(url.toString(), data, { timeout: 300000 });
  } catch (error) {
    throw new Error(error);
  }
};

const getCreditCardCompanies = async () => {
  try {
    const response = await api.get<CreditCardCompanies[]>(
      "Desk/CreditCardCompanies",
    );
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

const cancelInstallment = async (data: CancelInstallment) => {
  try {
    const url = new URL("sales/VirtualPOS/Charge/Cancel", api.defaults.baseURL);

    url.searchParams.append("api-version", "2.0");
    return api.post(url.toString(), data, { timeout: 300000 });
  } catch (error) {
    throw new Error(error);
  }
};

const anticipateInstallment = async ({
  guidAccount,
  guidDigitalSaleRecurrenceItem,
  ...data
}: AnticipateInstallment) => {
  try {
    const url = new URL(
      `Sales/${guidAccount}/Recurrence/Pay/${guidDigitalSaleRecurrenceItem}`,
      api.defaults.baseURL,
    );

    url.searchParams.append("api-version", "2.0");
    return api.post(url.toString(), data, { timeout: 300000 });
  } catch (error) {
    throw new Error(error);
  }
};

const postponeInstallment = async ({
  guidAccount,
  guidDigitalSaleRecurrenceItem,
  ...data
}: PostponeInstallment) => {
  try {
    return api.put(
      `Sales/${guidAccount}/Recurrence/Delay/${guidDigitalSaleRecurrenceItem}`,
      data,
      { timeout: 300000 },
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const useEditCard = (
  config?: MutationConfig<typeof editRecurrenceCard>,
) => {
  return useMutation({
    ...config,
    mutationFn: (props: EditRecurrenceCard) => editRecurrenceCard(props),
  });
};

export const useCreditCardCompanies = (
  config?: UseQueryOptions<CreditCardCompanies[]>,
) => {
  return useQuery({
    ...config,
    queryKey: ["getCreditCardCompanies"],
    queryFn: getCreditCardCompanies,
  });
};

export const useCancelInstallment = (
  config?: MutationConfig<typeof cancelInstallment>,
) => {
  return useMutation({
    ...config,
    mutationFn: (props: CancelInstallment) => cancelInstallment(props),
    mutationKey: ["cancelInstallment"],
  });
};

export const useAnticipateInstallment = (
  config?: MutationConfig<typeof anticipateInstallment>,
) => {
  return useMutation({
    ...config,
    mutationKey: ["anticipateInstallment"],
    mutationFn: (props: AnticipateInstallment) => anticipateInstallment(props),
  });
};

export const usePostponeInstallment = (
  config?: MutationConfig<typeof postponeInstallment>,
) => {
  return useMutation({
    ...config,
    mutationKey: ["postponeInstallment"],
    mutationFn: (props: PostponeInstallment) => postponeInstallment(props),
  });
};
