import { useState } from "react";
import { MASKED_INPUT_DEFAULT_PROPS } from "@/utils/format";
import { Button, Sheet, TextField } from "@hyperlocal/vital2";
import { withMask } from "use-mask-input";

const CODE_LENGTH = 6;

interface MFAValidationProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (token: string) => void;
  isLoading: boolean;
}

export const MFAValidation = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
}: MFAValidationProps) => {
  const [code, setCode] = useState("");

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleConfirm = () => {
    onConfirm(code);
  };

  return (
    <Sheet.Root open={isOpen} onOpenChange={handleClose}>
      <Sheet.Content className="z-[9999]">
        <Sheet.Header>
          <Sheet.Title>Token</Sheet.Title>
        </Sheet.Header>
        <div className="flex h-full flex-col">
          <h5 className="mb-4 font-base text-sm/md font-bold text-neutral-darkest">
            Informe o código de autenticação
          </h5>
          <div className="flex gap-2">
            <span>●</span>
            <span>
              Abra seu aplicativo Google Authenticator e insira o código de seis
              dígitos exibido no aplicativo.
            </span>
          </div>
          <TextField.Label htmlFor="totp" className="mt-inset-md">
            Digite o código
          </TextField.Label>
          <TextField.Root>
            <TextField.Input
              id="totp"
              type="text"
              ref={withMask("999999", MASKED_INPUT_DEFAULT_PROPS)}
              value={code}
              onChange={({ target }) => setCode(target.value)}
            />
          </TextField.Root>
          <Button.Root
            className="mt-auto"
            disabled={code.length !== CODE_LENGTH}
            onClick={handleConfirm}
            isLoading={isLoading}
          >
            Verificar
          </Button.Root>
        </div>
      </Sheet.Content>
    </Sheet.Root>
  );
};
