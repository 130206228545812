import { useCallback } from "react";
import {
  CancelInstallmentListProps,
  ControlInstallmentListProps,
  GetInstallmentListProps,
} from "@/api/useInstallmentsList.types";
import { api, MutationConfig } from "@/lib";
import { useFilter } from "@/stores/InstallmentFilter";
import { InstallmentStatus } from "@/stores/InstallmentFilter/types";
import {
  FormattedFutureInstallment,
  FutureInstallment,
  RecurrenceStatus,
} from "@/types/futureInstallments";
import { formateInstallmentResponse } from "@/utils/format";
import { useAccountStore } from "@/utils/utility";
import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";

const statusFilterMap: Partial<Record<RecurrenceStatus, InstallmentStatus>> = {
  P: "Pendentes",
  E: "Pagas",
  C: "Canceladas",
  A: "Agendadas",
  I: "Pausadas",
};

const statusMap: Partial<Record<RecurrenceStatus, string>> = {
  P: "Recorrência pendente",
  E: "Recorrência realizada",
  C: "Recorrência cancelada",
  A: "Recorrência interrompida com data de retorno",
  I: "Recorrência pausada",
};

const addRecurrenceStatus = (data: FutureInstallment) => {
  const result = data.recurrenceDetailsTable.map((item) => ({
    digital_Sale_Recurrence_Items: {
      ...item.digital_Sale_Recurrence_Items,
      recorrenceStatus: statusMap[item.digital_Sale_Recurrence_Items.status],
    },
  }));

  return {
    ...data,
    recurrenceDetailsTable: result,
  };
};

const getInstallmentsList = async ({
  guidAccount,
  guidDigitalSaleRecurrence,
}: GetInstallmentListProps) => {
  try {
    const response = await api.get<FutureInstallment>(
      `Sales/${guidAccount}/Recurrence/Details/${guidDigitalSaleRecurrence}`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const cancelInstallmentList = async ({
  guidAccount,
  guidDigitalSaleRecurrence,
  ...data
}: CancelInstallmentListProps) => {
  try {
    const url = new URL(
      `Sales/${guidAccount}/Recurrence/Cancel/${guidDigitalSaleRecurrence}`,
      api.defaults.baseURL,
    );

    url.searchParams.append("api-version", "2.0");
    return api.put<void>(url.toString(), data, { timeout: 300000 });
  } catch (error) {
    throw new Error(error);
  }
};

const pauseInstallmentList = async ({
  guidAccount,
  guidDigitalSaleRecurrence,
  ...data
}: ControlInstallmentListProps) => {
  try {
    return api.put(
      `Sales/${guidAccount}/Recurrence/Interrupt/${guidDigitalSaleRecurrence}`,
      data,
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const useInstallmentList = ({
  guidDigitalSaleRecurrence,
  config,
}: {
  guidDigitalSaleRecurrence: string;
  config?: UseQueryOptions<
    FutureInstallment,
    AxiosError,
    FormattedFutureInstallment
  >;
}) => {
  const { filterState } = useFilter();

  const handleFilter = useCallback(
    (data: FutureInstallment) => {
      const isDateAbleToFilter =
        filterState.startDate &&
        filterState.endDate &&
        !filterState.dateErrors.endDate &&
        !filterState.dateErrors.startDate;

      let detailsTable = data.recurrenceDetailsTable.filter((item) =>
        filterState.installmentStatus.includes(
          statusFilterMap[item.digital_Sale_Recurrence_Items.status],
        ),
      );

      if (filterState.search) {
        detailsTable = detailsTable.filter((item) =>
          item.digital_Sale_Recurrence_Items.recorrenceStatus
            .toLocaleLowerCase()
            .includes(filterState.search.toLocaleLowerCase()),
        );
      }

      if (isDateAbleToFilter) {
        detailsTable = detailsTable.filter((item) => {
          return (
            new Date(
              item.digital_Sale_Recurrence_Items.processing_date,
            ).getTime() >= new Date(filterState.startDate).getTime() &&
            new Date(
              item.digital_Sale_Recurrence_Items.processing_date,
            ).getTime() <= new Date(filterState.endDate).getTime()
          );
        });
      }

      return {
        ...data,
        recurrenceDetailsTable: detailsTable,
      };
    },
    [filterState],
  );

  const { currentAccountId: guidAccount } = useAccountStore();

  return useQuery({
    ...config,
    queryKey: ["getInstallmentList", guidAccount, guidDigitalSaleRecurrence],
    queryFn: () =>
      getInstallmentsList({ guidAccount, guidDigitalSaleRecurrence }),
    select: (data) => {
      const statusAdded = addRecurrenceStatus(data);
      return formateInstallmentResponse(handleFilter(statusAdded));
    },
  });
};

export const useCancelInstallmentList = (
  config?: MutationConfig<typeof cancelInstallmentList>,
) => {
  return useMutation({
    ...config,
    mutationFn: (props: CancelInstallmentListProps) =>
      cancelInstallmentList(props),
  });
};

export const useControlInstallmentList = (
  config?: MutationConfig<typeof pauseInstallmentList>,
) => {
  return useMutation({
    ...config,
    mutationFn: (props: ControlInstallmentListProps) =>
      pauseInstallmentList(props),
  });
};
