import { breakpoints } from "@/styles/breakpoints";
import { Button as VitalButton, Input as VitalInput } from "@hyperlocal/vital";
import VitalIcon from "@hyperlocal/vital-icons";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacings.inset.lg};
`;

export const Title = styled.h5`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.neutral.darkest};
  margin-bottom: ${({ theme }) => theme.spacings.inset.x2s};
`;

export const RadioContainer = styled.div`
  display: flex;
  margin: ${({ theme }) => `${theme.spacings.inset.md} 0`};
`;

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${({ theme }) => theme.spacings.inset.md};
  grid-row-gap: ${({ theme }) => theme.spacings.inset.lg};

  ${breakpoints.mobile} {
    grid-template-columns: 1fr;
  }
`;

export const Input = styled(VitalInput)`
  box-sizing: content-box;
`;

export const Button = styled(VitalButton)`
  justify-content: center;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacings.inset.lg};
`;

export const HintContainer = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-left: 10px;
`;

export const Icon = styled(VitalIcon)`
  height: 19px;
  width: 19px;
  color: ${({ theme }) => theme.palette.status.warning.warningDefault};
  margin-left: 10px;
`;
