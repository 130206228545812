import { InstallmentDetail, ScrollArea } from "@/components";
import { useSetHeader, useToggle } from "@/hooks";
import { useFilter } from "@/stores";
import { MonthlyDetail } from "@/types/futureInstallments";
import { getLongMonthAndYear, getShorMonth } from "@/utils/format";
import { formatCurrency, getChargeRoutesData, Hooks } from "@/utils/utility";
import { Drawer } from "@hyperlocal/vital";
import { useNavigate, useParams } from "react-router-dom";
import { InstallmentsListActions } from "./components";
import * as S from "./InstallmentsList.styles";

interface InstallmentsListProps {
  installmentsReport: MonthlyDetail;
  isError: boolean;
}

const routes = getChargeRoutesData();

const { useMediaQuery } = Hooks;

export function InstallmentsList({
  installmentsReport,
  isError = false,
}: InstallmentsListProps) {
  const {
    dispatchFilter,
    filterState: { installmentSelected },
  } = useFilter();
  const { id } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("mobile");

  const [isDetailOpen, setDetailOpen] = useToggle();
  const setHeader = useSetHeader({
    title: "Parcelas futuras",
    backRoute: "charge",
  });

  const handleDetailNavigation = () => {
    if (isMobile) {
      navigate(routes.futureInstallmentDetail.replace(":id", id));
    } else {
      setDetailOpen();
    }
  };

  const onDismiss = () => {
    setHeader();
    setDetailOpen();
  };

  if (isError) {
    return (
      <S.Container>
        <S.InstallmentNoFound>
          Houve um erro.
          <br />
          Tente novamente mais tarde.
        </S.InstallmentNoFound>
      </S.Container>
    );
  }

  const isInstallmentsListed = !!Object.keys(installmentsReport).length;
  return (
    <>
      <S.Container>
        {isInstallmentsListed ? (
          <ScrollArea>
            <S.ListWrapper>
              {Object.entries(installmentsReport).map(([month, monthData]) => {
                const [MM, YY] = month.split("/");
                const monthTitle = getLongMonthAndYear(
                  new Date(`${MM}/01/${YY}`),
                );
                const formattedMonthTitle =
                  monthTitle.charAt(0).toLocaleUpperCase() +
                  monthTitle.substring(1);
                return (
                  <div key={month}>
                    <S.MonthHeader>
                      <S.MonthTitle>{formattedMonthTitle}</S.MonthTitle>
                      <S.MonthIncome>
                        Recebimento do mês {formatCurrency(monthData.total)}
                      </S.MonthIncome>
                    </S.MonthHeader>
                    {Object.entries(monthData.installments).map(
                      ([day, dayData]) => {
                        const [DD, MM] = day.split("/");
                        const month = getShorMonth(
                          new Date(`${MM}/${DD}/${YY}`),
                        ).toLocaleUpperCase();
                        return (
                          <S.InstallmentCard
                            role="button"
                            key={day}
                            onClick={() => {
                              dispatchFilter({
                                type: "SET_SELECT_INSTALLMENT",
                                payload: { installmentSelected: dayData },
                              });
                              handleDetailNavigation();
                            }}
                          >
                            <S.InstallmentDateWrapper
                              data-status={dayData.status}
                            >
                              <S.InstallmentDate>{DD}</S.InstallmentDate>
                              <S.InstallmentMonth>{month}</S.InstallmentMonth>
                            </S.InstallmentDateWrapper>
                            <S.InstallmentInfoStatus>
                              {dayData.recurrenceStatus}
                            </S.InstallmentInfoStatus>
                            <S.InstallmentAmountInfo>
                              <S.InstallmentsLeft>
                                Parcela {dayData.installmentNumber} de{" "}
                                {dayData.totalInstallments}
                              </S.InstallmentsLeft>
                              <S.InstallmentAmount data-status={dayData.status}>
                                {formatCurrency(dayData.amount)}
                              </S.InstallmentAmount>
                            </S.InstallmentAmountInfo>
                          </S.InstallmentCard>
                        );
                      },
                    )}
                  </div>
                );
              })}
            </S.ListWrapper>
          </ScrollArea>
        ) : (
          <S.InstallmentNoFound>
            Não há mais nada por aqui... <br />
            Para consultar datas anteriores use o filtro.
          </S.InstallmentNoFound>
        )}
        <InstallmentsListActions />
      </S.Container>
      <Drawer
        open={isDetailOpen}
        onDismiss={onDismiss}
        position="right"
        title={`Detalhes da ${installmentSelected?.installmentNumber}ª parcela`}
      >
        {installmentSelected && <InstallmentDetail />}
      </Drawer>
    </>
  );
}
