import { breakpoints } from "@/styles/breakpoints";
import { Button as VitalButton, Input as VitalInput } from "@hyperlocal/vital";
import styled from "styled-components";

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.neutral.white};
  padding: ${({ theme }) => theme.spacings.inset.lg};
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  display: flex;
  flex-direction: column;
  ${breakpoints.mobile} {
    display: none;
  }
`;

export const Title = styled.h5`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.palette.neutral.darkest};
`;

export const Input = styled(VitalInput)`
  box-sizing: content-box;
`;

export const DateRangeContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.inset.x2s};
  margin: ${({ theme }) =>
    `${theme.spacings.inset.md} 0 ${theme.spacings.inset.lg} 0`};
`;

export const DateSelectContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.inset.md};
  margin: ${({ theme }) =>
    `${theme.spacings.inset.md} 0 ${theme.spacings.inset.xl} 0`};
`;

export const SearchInputContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.inset.xl};
`;

export const StatusContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: ${({ theme }) =>
    `${theme.spacings.inset.md} 0 ${theme.spacings.inset.xl} 0`};
`;

export const Button = styled(VitalButton)`
  margin: 0 auto;
`;
