import { Drawer, Modal, Calendar as VitalCalendar } from "@hyperlocal/vital";
import { add } from "date-fns";
import * as S from "./Calendar.styles";

export interface CalendarProps {
  isOpen: boolean;
  onDismiss: () => void;
  minDate: Date;
  onSelectDate: (date: Date) => void;
}

export function Calendar({
  isOpen,
  minDate,
  onDismiss,
  onSelectDate,
}: CalendarProps) {
  const newMinDate = add(minDate, { days: 1 });

  const handleDisableDate = (date: Date) => {
    return date.getTime() < newMinDate.getTime();
  };

  return (
    <>
      <S.ModalContainer>
        <Modal
          variant="custom"
          isOpen={isOpen}
          onClose={onDismiss}
          title="Selecione a nova data"
        >
          <S.CalendarContainer>
            <VitalCalendar
              selectedDate={newMinDate}
              onDisableDate={handleDisableDate}
              onSelect={onSelectDate}
            />
          </S.CalendarContainer>
          <S.WarningText>
            *A prorrogação será aplicada somente a esta parcela específica,
            enquanto as demais manterão suas datas originais sem alteração.
          </S.WarningText>
        </Modal>
      </S.ModalContainer>
      <S.DrawerContainer>
        <Drawer
          title="Selecione a nova data"
          open={isOpen}
          position="bottom"
          onDismiss={onDismiss}
        >
          <S.CalendarContainer>
            <VitalCalendar
              selectedDate={newMinDate}
              onDisableDate={handleDisableDate}
              onSelect={onSelectDate}
            />
          </S.CalendarContainer>
          <S.WarningText>
            *A prorrogação será aplicada somente a esta parcela específica,
            enquanto as demais manterão suas datas originais sem alteração.
          </S.WarningText>
        </Drawer>
      </S.DrawerContainer>
    </>
  );
}
