import { ChargeRoutes } from "./Charge.routes";
import { FutureInstallmentsRoutes } from "./FutureInstallments.routes";

export function Routes() {
  return (
    <>
      <ChargeRoutes />
      <FutureInstallmentsRoutes />
    </>
  );
}
