import { NonEmptyArray } from "@/types/utils";

export const validateEmail = (email: string) => {
  const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  return re.test(email);
};

export function isNonEmpty<A>(array: Array<A>): array is NonEmptyArray<A> {
  return array.length > 0;
}
