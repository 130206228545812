import styled from "styled-components";

export const TooltipText = styled.span`
  display: block;

  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.md};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.palette.primary.main};

  white-space: nowrap;

  & + & {
    margin-top: 2px;
  }
`;
