import { breakpoints } from "@/styles/breakpoints";
import { Skeleton as VitalSkeleton } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import styled, { css } from "styled-components";

export const Container = styled.div`
  overflow-x: auto;
  flex-shrink: 0;

  scroll-snap-type: x mandatory;

  ${breakpoints.mobile} {
    // No mobile essa Div é filho de um grid. Veja implementação e comentários no
    // componente Section do pages/Charge.tsx para entender melhor.
    grid-row: 2;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacings.stack.x3s}; // 16px

  min-width: max-content;

  ${breakpoints.mobile} {
    padding: 0 16px;

    gap: ${({ theme }) => theme.spacings.stack.nano}; // 8px
  }
`;

// Card

export const CardContainer = styled.div`
  background: ${({ theme }) => theme.palette.neutral.white};
  padding: ${({ theme }) => theme.spacings.squished.lg}; // 16px 24px
  border-radius: ${({ theme }) => theme.borderRadius.sm}; // 8px

  scroll-snap-align: center;

  ${breakpoints.mobile} {
    border: 1px solid ${({ theme }) => theme.palette.neutral.light};

    transition: border 400ms cubic-bezier(0.16, 1, 0.3, 1);

    // Seleciona o data-state que contenha a Substring "open"
    &[data-state*="open"] {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export const CardHeader = styled.div`
  margin-bottom: 8px;

  display: flex;
  align-items: center;
  gap: 10px;
`;

// Icons

const sharedIconStyles = css`
  box-sizing: content-box;
  padding: ${({ theme }) => theme.spacings.stack.nano}; // 8px
  border-radius: ${({ theme }) => theme.borderRadius.circle}; // 50%
`;

export const PendingIcon = styled(Icon)`
  ${sharedIconStyles}

  background: ${({ theme }) => theme.palette.status.warning.warningLight};
  color: ${({ theme }) => theme.palette.status.warning.warningDark};
`;

export const ReversedIcon = styled(Icon)`
  ${sharedIconStyles}

  background: ${({ theme }) => theme.palette.status.error.errorLight};
  color: ${({ theme }) => theme.palette.status.error.errorDark};
`;

const sharedFontStyles = css`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.sm};
  color: ${({ theme }) => theme.palette.neutral.dark};
`;

export const CardHeaderTitle = styled.span`
  ${sharedFontStyles}

  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const Skeleton = styled(VitalSkeleton)`
  display: block;
`;

export const Amount = styled.span`
  ${sharedFontStyles}

  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
