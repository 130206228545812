import { formatCurrency } from "@/utils/utility";
import * as S from "./TooltipContent.styles";

export interface TooltipContentProps {
  items: {
    text: string;
    amount: number;
  }[];
}

export function TooltipContent({ items }: TooltipContentProps) {
  return (
    <>
      {items.map((item) => (
        <S.TooltipText key={item.text}>
          {item.text} {formatCurrency(item.amount)}
        </S.TooltipText>
      ))}
    </>
  );
}
