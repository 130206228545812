import { DigitalLinkData } from "@/api/useChargesList";
import { getDigitalLinkStatus } from "@/utils/format";
import { formatCurrency } from "@/utils/utility";
import { Icon } from "../Icon";
import * as S from "./CardButton.styles";

interface CardButtonProps extends DigitalLinkData {
  handleClick: () => void;
}

export function CardButton(props: Readonly<CardButtonProps>) {
  const { handleClick, client, amount } = props;

  return (
    <S.Button onClick={handleClick} data-testId="open-drawer-button">
      <S.LeftWrapper>
        <Icon
          type="Venda digitada"
          status={getDigitalLinkStatus(props)}
          size={16}
          padding={12}
        />
        <div>
          <S.TypeAndStatus>
            Link de cobrança {getDigitalLinkStatus(props).toLowerCase()}
          </S.TypeAndStatus>
          <S.Person>{client.name}</S.Person>
        </div>
      </S.LeftWrapper>

      <S.RightWrapper>
        <S.Installment>Em até {props.limitInstallments}x</S.Installment>
        <S.Amount $isStatusPaid={getDigitalLinkStatus(props) === "Pago"}>
          {formatCurrency(amount)}
        </S.Amount>
      </S.RightWrapper>
    </S.Button>
  );
}
