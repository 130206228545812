import { useCallback, useEffect, useState } from "react";
import {
  useAnticipateInstallment,
  useCancelInstallment,
  usePostponeInstallment,
} from "@/api/useInstallmentDetail";
import { useInstallmentList } from "@/api/useInstallmentsList";
import { TokenDrawer } from "@/components/TokenDrawer/TokenDrawer";
import { useSetHeader, useToggle } from "@/hooks";
import { guidTokenType } from "@/services/general";
import { useFilter } from "@/stores";
import {
  FormattedFutureInstallment,
  InstallmentActionOption,
} from "@/types/futureInstallments";
import { StandardCustomEvent } from "@/types/general";
import { RequestError } from "@/types/request";
import { getGroupedDay, getGroupedMonth } from "@/utils/format";
import {
  getChargeRoutesData,
  Hooks,
  toast,
  Types,
  useAccountStore,
  useUserStore,
} from "@/utils/utility";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import { Calendar } from "../Calendar/Calendar";
import * as S from "./InstallmentDetailActions.style";

export interface InstallmentDetailActionsProps {
  actionType: InstallmentActionOption;
}

type GenDispatchToken = {
  eventId: Types["ICustomEventName"];
  guidTokenType: string;
};

type EventToDispatch = "anticipate" | "postpone" | "cancel";

const routes = getChargeRoutesData();

const { useDispatchTokenEvent, useListenCustomEvent, useMediaQuery } = Hooks;

export function InstallmentDetailActions({
  actionType,
}: InstallmentDetailActionsProps) {
  const [tokenDrawer, setTokenDrawer] = useToggle();
  const [calendarOpen, setCalendarOpen] = useToggle();
  const [eventToDispatch, setEventToDispatch] =
    useState<EventToDispatch>("anticipate");

  const isMobile = useMediaQuery("mobile");

  const navigate = useNavigate();
  const { id } = useParams();
  const { refetch, isFetching } = useInstallmentList({
    guidDigitalSaleRecurrence: id,
    config: {
      enabled: false,
    },
  });
  const {
    filterState: { installmentSelected },
    dispatchFilter,
  } = useFilter();

  const [postponeDate, selectDate] = useState(
    new Date(installmentSelected.processingDate),
  );

  const setHeader = useSetHeader({
    title: `Detalhes da ${installmentSelected?.installmentNumber}ª parcela`,
    backRoute: "previousRoute",
  });

  const { currentAccountId: guidAccount } = useAccountStore();

  const {
    user: { userId: guidUser },
  } = useUserStore();

  const installmentListRoute = routes.futureInstallments.replace(":id", id);

  const generateDispatchTokenBody = ({
    eventId,
    guidTokenType,
  }: GenDispatchToken) => {
    return {
      eventIdentifier: eventId,
      request: {
        guid_user: guidUser,
        guid_token_type: guidTokenType,
        path: installmentListRoute,
        header: {
          title: "Token",
        },
      },
    };
  };

  const actionMap: Record<InstallmentActionOption, string> = {
    anticipate: "Solicitar antecipação",
    postpone: "Prorrogar pagamento",
  };

  const isCancelEnabled = installmentSelected?.status === "E";

  const onDateSelect = (date: Date) => {
    selectDate(date);
    setCalendarOpen();
    setTokenDrawer();
  };

  const onDismiss = useCallback(() => {
    setHeader();
    setTokenDrawer();
  }, [setHeader, setTokenDrawer]);

  const onUpdateSelectedInstallment = (data: FormattedFutureInstallment) => {
    const newMonth = getGroupedMonth(postponeDate);
    const newDay = getGroupedDay(postponeDate);

    const newInstallmentData =
      data.installmentsReport[newMonth].installments[newDay];

    dispatchFilter({
      type: "SET_SELECT_INSTALLMENT",
      payload: { installmentSelected: newInstallmentData },
    });

    if (isMobile) {
      navigate(installmentListRoute);
    }
  };

  const onSuccess = (toastMessage: string) => {
    toast.success(toastMessage);
    refetch().then(({ data }) => onUpdateSelectedInstallment(data));
  };

  const onError = (error: RequestError) => {
    const errorMessage =
      error?.response?.data[0]?.message ||
      "Houve um erro ao antecipar a parcela";
    toast.error(errorMessage);
  };

  const anticipateMutation = useAnticipateInstallment({
    onSuccess: () => {
      onDismiss();
      onSuccess("Parcela antecipada");
    },
    onError,
  });

  const postponeMutation = usePostponeInstallment({
    onSuccess: () => {
      onSuccess("Parcela prorrogada");
    },
    onError,
  });

  const cancelMutation = useCancelInstallment({
    onSuccess: () => {
      onSuccess("Parcela cancelada");
    },
    onError,
  });

  const dispatchAnticipateEvent = useDispatchTokenEvent({
    detail: generateDispatchTokenBody({
      eventId: "ANTICIPATE_INSTALLMENT",
      guidTokenType: guidTokenType.ANTICIPATE_INSTALLMENT,
    }),
  });

  const dispatchPostponeEvent = useDispatchTokenEvent({
    detail: generateDispatchTokenBody({
      eventId: "POSTPONE_INSTALLMENT",
      guidTokenType: guidTokenType.POSTPONE_INSTALLMENT,
    }),
  });

  const dispatchCancelEvent = useDispatchTokenEvent({
    detail: generateDispatchTokenBody({
      eventId: "CANCEL_SINGLE_INSTALLMENT",
      guidTokenType: guidTokenType.CANCEL_SINGLE_INSTALLMENT,
    }),
  });

  const onEventDispatch = () => {
    const eventMap: Record<EventToDispatch, () => void> = {
      anticipate: dispatchAnticipateEvent,
      postpone: dispatchPostponeEvent,
      cancel: dispatchCancelEvent,
    };

    return eventMap[eventToDispatch]();
  };

  const handleInstallmentAction = (event: EventToDispatch) => {
    setEventToDispatch(event);

    if (event !== "postpone") {
      setTokenDrawer();
    } else {
      setCalendarOpen();
    }
  };

  const isPrimaryActionDisabled =
    installmentSelected?.status === "C" || installmentSelected?.status === "E";

  const isPrimaryActionLoading =
    anticipateMutation.isLoading || postponeMutation.isLoading || isFetching;

  const onAnticipate = useCallback(
    (e: StandardCustomEvent) => {
      onDismiss();
      anticipateMutation.mutate({
        guid_user: guidUser,
        guidAccount,
        guidDigitalSaleRecurrenceItem: installmentSelected?.recurrenceItemId,
        token: e.detail.token,
      });
    },
    [
      anticipateMutation,
      guidAccount,
      guidUser,
      installmentSelected?.recurrenceItemId,
      onDismiss,
    ],
  );

  const onPostpone = useCallback(
    (e: StandardCustomEvent) => {
      onDismiss();
      postponeMutation.mutate({
        guid_user: guidUser,
        guidAccount,
        guidDigitalSaleRecurrenceItem: installmentSelected?.recurrenceItemId,
        token: e.detail.token,
        processing_date: format(postponeDate, "yyyy-MM-dd"),
      });
    },
    [
      onDismiss,
      postponeMutation,
      guidUser,
      guidAccount,
      installmentSelected?.recurrenceItemId,
      postponeDate,
    ],
  );

  const onCancel = useCallback(
    (e: StandardCustomEvent) => {
      onDismiss();
      cancelMutation.mutate({
        guid_account: guidAccount,
        token: e.detail.token,
        guidDigitalSaleOrders: installmentSelected?.guidDigitalSalesOrder,
        guidUserActuator: guidUser,
      });
    },
    [
      cancelMutation,
      guidAccount,
      guidUser,
      installmentSelected?.guidDigitalSalesOrder,
      onDismiss,
    ],
  );

  useListenCustomEvent({
    eventName: "CANCEL_SINGLE_INSTALLMENT",
    callback: onCancel,
  });

  useListenCustomEvent({
    eventName: "ANTICIPATE_INSTALLMENT",
    callback: onAnticipate,
  });

  useListenCustomEvent({
    eventName: "POSTPONE_INSTALLMENT",
    callback: onPostpone,
  });

  useEffect(() => {
    selectDate(new Date(installmentSelected.processingDate));
  }, [installmentSelected.processingDate]);

  return (
    <>
      <S.Container>
        <S.Button
          onClick={() => handleInstallmentAction(actionType)}
          disabled={isPrimaryActionDisabled}
          variant="primary"
          isLoading={isPrimaryActionLoading}
        >
          {actionMap[actionType]}
        </S.Button>
        {isCancelEnabled && (
          <S.Button
            onClick={() => handleInstallmentAction("cancel")}
            variant="link"
            isLoading={cancelMutation.isLoading}
          >
            Estornar cobrança
          </S.Button>
        )}
      </S.Container>
      <TokenDrawer
        isOpen={tokenDrawer}
        onClose={onDismiss}
        dispatchOnMount={onEventDispatch}
      />
      <Calendar
        isOpen={calendarOpen}
        minDate={postponeDate}
        onDismiss={setCalendarOpen}
        onSelectDate={onDateSelect}
      />
    </>
  );
}
