import { Button as VitalButton, Input as VitalInput } from "@hyperlocal/vital";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.inset.md};
  padding-top: ${({ theme }) => theme.spacings.inset.md};
  height: 100%;
`;

export const Input = styled(VitalInput)`
  box-sizing: content-box;
`;

export const SwitchContainer = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.inset.md};
`;

export const Button = styled(VitalButton)`
  margin-top: auto;
  justify-content: center;
`;
