import { api, MutationConfig } from "@/lib";
import { toast } from "@hyperlocal/vital2";
import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
import {
  BoletoPaymentRequest,
  DigitalSaleLinkRequest,
  DigitalSaleLinkResponse,
  DigitalSaleRequest,
  PayerDataRequest,
  PayerDataResponse,
  RecurrenceRequest,
  RecurrenceSimulationRequest,
  RecurrenceSimulationResponse,
} from "./useNewCharge.types";

const generateBoleto = async ({
  guidAccount,
  ...data
}: BoletoPaymentRequest) => {
  try {
    const response = await api.post(
      `DigitalAccount/${guidAccount}/charge/Bill`,
      data,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const createDigitalSaleLink = async (data: DigitalSaleLinkRequest) => {
  try {
    const response = await api.post<DigitalSaleLinkResponse>(
      "/paymentLink/createLink",
      data,
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) throw error;

    throw new Error("different error than axios");
  }
};

const createDigitalSale = async (data: DigitalSaleRequest) => {
  try {
    const url = new URL("/sales/VirtualPOS", api.defaults.baseURL);
    url.searchParams.append("api-version", "2.0");
    return api.post(url.toString(), data, { timeout: 300000 });
  } catch (error) {
    throw new Error(error);
  }
};

const createRecurrenceSimulation = async ({
  guidAccount,
  ...data
}: RecurrenceSimulationRequest) => {
  try {
    const response = await api.post<RecurrenceSimulationResponse>(
      `/Sales/${guidAccount}/Recurrence/Preview`,
      data,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const createRecurrenceSale = async ({
  guidAccount,
  ...data
}: RecurrenceRequest) => {
  try {
    const response = await api.post(`/Sales/${guidAccount}/Recurrence`, data, {
      params: {
        "api-version": "2.0",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getPayerData = async ({ document, guidAccount }: PayerDataRequest) => {
  try {
    const response = await api.get<PayerDataResponse>(
      `/Sales/${guidAccount}/Recurrence/Payer/${document}`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const useGenerateBoleto = (
  config?: MutationConfig<typeof generateBoleto>,
) => {
  return useMutation({
    ...config,
    mutationKey: ["generateBoleto"],
    mutationFn: (props: BoletoPaymentRequest) => generateBoleto(props),
  });
};

export const useCreateDigitalSaleLink = (
  config?: MutationConfig<typeof createDigitalSaleLink>,
) => {
  return useMutation({
    ...config,
    mutationKey: ["createDigitalSaleLink"],
    mutationFn: (data) => createDigitalSaleLink(data),
    onError: (error) => {
      const errorMessage =
        error?.response?.data.errors[0]?.friendlyMessage ||
        "Houve um erro ao criar o link de cobrança";
      toast({
        title: errorMessage,
        variant: "error",
      });
    },
  });
};

export const useCreateDigitalSale = (
  config?: MutationConfig<typeof createDigitalSale>,
) => {
  return useMutation({
    ...config,
    mutationKey: ["createDigitalSale"],
    mutationFn: (data) => createDigitalSale(data),
  });
};

export const useRecurrenceSimulate = (
  config?: MutationConfig<typeof createRecurrenceSimulation>,
) => {
  return useMutation({
    ...config,
    mutationKey: ["recurrenceSimulation"],
    mutationFn: (data) => createRecurrenceSimulation(data),
  });
};

export const useCreateRecurrence = (
  config?: MutationConfig<typeof createRecurrenceSale>,
) => {
  return useMutation({
    ...config,
    mutationKey: ["createRecurrenceSale"],
    mutationFn: (data) => createRecurrenceSale(data),
  });
};

export const useGetPayerData = ({
  document,
  guidAccount,
  config,
}: PayerDataRequest & {
  config?: UseQueryOptions<PayerDataResponse>;
}) => {
  return useQuery({
    ...config,
    queryKey: ["getPayerData", document, guidAccount],
    queryFn: () => getPayerData({ document, guidAccount }),
    suspense: false,
  });
};
