import { useEffect } from "react";
import { getCities, useCep, useGetStates } from "@/api/useAddress";
import { City } from "@/api/useAddress.types";
import { InputMask } from "@/components";
import { useFormReducer } from "@/hooks/useFormReducer";
import { CepPromiseError } from "@/types/request";
import { TODAY } from "@/utils/constants";
import { formatDateToApi, isValidDate } from "@/utils/filter";
import { handleNormalizeDate, unMask } from "@/utils/format";
import { getInstallmentSelectOptions } from "@/utils/installment";
import { sanitizeValue } from "@/utils/utility";
import { Input, Select } from "@hyperlocal/vital";
import { toast } from "@hyperlocal/vital2";
import { DigitalSaleAction } from "./components";
import * as S from "./DigitalSale.styles";

const DIGITAL_SALE_MAX_OPTION = 12;

export type DigitalSaleForm = {
  description: string;
  zipcode: string;
  street: string;
  neighborhood: string;
  number: string;
  state: string;
  city: string;
  complement?: string;
  saleMaxInstallments: string;
  saleExpireDate: Date;
  citiesList: City[];
};

interface DigitalSaleProps {
  amount: number;
}

export function DigitalSale({ amount }: DigitalSaleProps) {
  const { data: statesList } = useGetStates({
    suspense: false,
  });

  const installmentSelectOptions = getInstallmentSelectOptions(
    amount,
    DIGITAL_SALE_MAX_OPTION,
  );

  const formInitialState: DigitalSaleForm = {
    state: "RO",
    city: "",
    neighborhood: "",
    number: "",
    saleExpireDate: TODAY,
    saleMaxInstallments: "",
    description: "",
    street: "",
    zipcode: "",
    complement: "",
    citiesList: [],
  };

  const { dispatch, formState } =
    useFormReducer<DigitalSaleForm>(formInitialState);

  const handleInput = ({
    target,
  }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const name = target.name as keyof DigitalSaleForm;
    const value = sanitizeValue(target.value);
    dispatch({ name, value });
  };

  const { refetch } = useCep(formState.zipcode, {
    suspense: false,
    enabled: false,
    onError: (error: CepPromiseError) => {
      dispatch({
        name: "zipcode",
        value: "",
      });
      const errorMessage = error?.message || "Houve um erro ao procurar o CEP";

      toast({
        title: errorMessage,
        variant: "error",
      });
    },
    onSuccess: (data) => {
      dispatch({
        name: "neighborhood",
        value: data.neighborhood,
      });

      dispatch({
        name: "street",
        value: data.street,
      });

      dispatch({
        name: "city",
        value: data.city,
      });

      dispatch({
        name: "state",
        value: data.state,
      });

      getCities(data.state).then((res) =>
        dispatch({
          name: "citiesList",
          value: res,
        }),
      );
    },
  });

  useEffect(() => {
    const isZipCodeFilled = unMask(formState.zipcode).length === 8;
    if (isZipCodeFilled) {
      refetch();
    }
  }, [formState.zipcode, refetch]);

  return (
    <S.Container>
      <S.Title>Dados da cobrança</S.Title>
      <S.ChargeDataContainer>
        <S.InputContainer data-visible>
          <Input
            type="text"
            label="Descrição"
            placeholder="Digite aqui"
            name="description"
            onChange={handleInput}
          />
        </S.InputContainer>
      </S.ChargeDataContainer>
      <S.Title>Dados do endereço</S.Title>
      <S.AddressContainer>
        <S.InputContainer gridArea="zipcode">
          <InputMask
            name="zipcode"
            mask="99999-999"
            value={formState.zipcode}
            label="CEP"
            onChange={handleInput}
          />
        </S.InputContainer>
        <S.InputContainer gridArea="street">
          <Input
            type="text"
            value={formState.street}
            disabled={!formState.street}
            label="Endereço da cobrança"
            placeholder="Digite o endereço"
            name="street"
            onChange={handleInput}
          />
        </S.InputContainer>
        <S.InputContainer gridArea="neighborhood">
          <Input
            type="text"
            label="Bairro"
            value={formState.neighborhood}
            disabled={!formState.street}
            placeholder="Digite o endereço"
            name="neighborhood"
            onChange={handleInput}
          />
        </S.InputContainer>
        <S.InputContainer gridArea="number">
          <InputMask
            name="number"
            mask="99999999"
            disabled={!formState.street}
            label="Número"
            onChange={handleInput}
          />
        </S.InputContainer>
        <S.InputContainer gridArea="state">
          <Select
            label="Estado"
            name="state"
            value={formState.state}
            disabled={!formState.street}
          >
            {statesList?.map((state) => (
              <option key={state.sigla}>{state.sigla}</option>
            ))}
          </Select>
        </S.InputContainer>
        <S.InputContainer gridArea="city">
          <Select
            name="city"
            label="Cidade"
            onChange={handleInput}
            value={formState.city}
            disabled={!formState.city}
          >
            {formState.citiesList.map((item) => (
              <option key={item.id}>{item.nome}</option>
            ))}
          </Select>
        </S.InputContainer>
        <S.InputContainer gridArea="complement">
          <Input
            type="text"
            label="Complemento"
            placeholder="Digite (opcional)"
            name="complement"
            onChange={handleInput}
          />
        </S.InputContainer>
        <S.CEPAnchor
          href="https://buscacepinter.correios.com.br/app/endereco/index.php"
          target="blank"
          rel="noreferrer"
        >
          não sei meu CEP
        </S.CEPAnchor>
      </S.AddressContainer>
      <S.CustomizedGridContainer gridTemplateColumns="1fr">
        <Select
          label="Nº máximo de parcelas"
          disabled={amount === 0}
          onChange={handleInput}
          name="saleMaxInstallments"
        >
          <option value="">Selecionar</option>
          {installmentSelectOptions.map((installment, index) => {
            const installmentNumber = index + 1;
            return (
              <option key={installment} value={installmentNumber}>
                {installment}
              </option>
            );
          })}
        </Select>
        <S.CustomizedGridContainer data-visible gridTemplateColumns="1fr 1fr">
          <S.Input
            type="date"
            label="Data de validade"
            value={formatDateToApi(formState.saleExpireDate)}
            min={formatDateToApi(TODAY)}
            onChange={({ target }) => {
              const date = handleNormalizeDate(target.value);
              if (isValidDate(date))
                dispatch({ name: "saleExpireDate", value: date });
            }}
          />
        </S.CustomizedGridContainer>
      </S.CustomizedGridContainer>
      <DigitalSaleAction {...formState} saleAmount={amount} />
    </S.Container>
  );
}
