import {
  Button as VitalButton,
  Checkbox as VitalCheckbox,
  Input as VitalInput,
} from "@hyperlocal/vital";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.inset.md};
  padding-top: ${({ theme }) => theme.spacings.inset.md};
`;

export const Input = styled(VitalInput)`
  box-sizing: content-box;
`;

export const FlexContainer = styled.div`
  display: flex;
  max-width: 430px;
  gap: ${({ theme }) => theme.spacings.inset.md};

  & > div {
    width: 50%;
    max-width: 200px;
  }
`;

export const Checkbox = styled(VitalCheckbox)`
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Button = styled(VitalButton)`
  justify-content: center;
`;
