import { useEffect, useState } from "react";
import { useInstallmentList } from "@/api/useInstallmentsList";
import { useSetHeader, useToggle } from "@/hooks";
import { useFilter } from "@/stores";
import { InstallmentActionOption } from "@/types/futureInstallments";
import { formatCurrency, getChargeRoutesData } from "@/utils/utility";
import { Button, Chips } from "@hyperlocal/vital";
import { useNavigate, useParams } from "react-router-dom";
import { ChangeCreditCardDrawer, InstallmentDetailActions } from "./components";
import * as S from "./InstallmentDetail.style";

const chipOption: InstallmentActionOption[] = ["anticipate", "postpone"];

const routes = getChargeRoutesData();

const generateStatusTitle = (status) => {
  const title = status?.split(" ").shift();
  const subTitle = status?.split(" ").slice(1).join(" ");
  const subTitleFormatted =
    subTitle?.charAt(0).toLocaleUpperCase() + subTitle?.substring(1);
  return {
    title,
    subTitle: subTitleFormatted,
  };
};

export function InstallmentDetail() {
  const [changeCardOpen, setChangeCard] = useToggle();
  const { id } = useParams();
  const {
    filterState: { installmentSelected },
  } = useFilter();

  const navigate = useNavigate();

  useSetHeader({
    title: `Detalhes da ${installmentSelected?.installmentNumber}ª parcela`,
    backRoute: "previousRoute",
  });

  const { data } = useInstallmentList({
    guidDigitalSaleRecurrence: id,
  });

  const { saleRecurrenceRecipients } = data;
  const [actionType, setActionType] =
    useState<InstallmentActionOption>("anticipate");

  const chipLabel: Record<InstallmentActionOption, string> = {
    anticipate: "Antecipar",
    postpone: "Prorrogar",
  };

  const { subTitle, title } = generateStatusTitle(
    installmentSelected?.recurrenceStatus,
  );

  const isActionsDisabled =
    installmentSelected?.status === "C" || installmentSelected?.status === "E";

  useEffect(() => {
    if (!installmentSelected) {
      navigate(routes.futureInstallments.replace(":id", id));
    }
  }, [id, installmentSelected, navigate]);

  if (!installmentSelected) return null;

  return (
    <>
      <S.Container>
        <S.StatusWrapper>
          <S.Avatar
            data-status={installmentSelected.status}
            name="GeralMoneyPayReceived"
            fill="currentColor"
          />
          <S.StatusTitleContainer>
            <S.StatusTitle>{title}</S.StatusTitle>
            <S.StatusTitle data-status={installmentSelected.status}>
              {subTitle}
            </S.StatusTitle>
          </S.StatusTitleContainer>
          <S.ChipContainer>
            {chipOption.map((chipItem) => {
              const isActive = actionType === chipItem;
              return (
                <Chips
                  rightIcon={isActive && { name: "SettingCheck" }}
                  key={chipItem}
                  active={isActive}
                  onClick={() => setActionType(chipItem)}
                >
                  {chipLabel[chipItem]}
                </Chips>
              );
            })}
          </S.ChipContainer>
        </S.StatusWrapper>
        <S.Divider />
        <S.InstallmentInfoContainer>
          <S.InsTallmentInfoTitle>Valor da cobrança</S.InsTallmentInfoTitle>
          <S.InstallmentAmount data-status={installmentSelected.status}>
            {formatCurrency(installmentSelected.amount)}
          </S.InstallmentAmount>
          <S.InsTallmentInfoTitle>Parcelas</S.InsTallmentInfoTitle>
          <S.InstallmentInfoData>
            Parcela {installmentSelected.installmentNumber} de{" "}
            {data.totalInstallments}
          </S.InstallmentInfoData>
          <S.InsTallmentInfoTitle>Data de transação</S.InsTallmentInfoTitle>
          <S.InstallmentInfoData>
            {new Date(installmentSelected.processingDate).toLocaleDateString()}
          </S.InstallmentInfoData>
          <S.InsTallmentInfoTitle>Tipo da transação</S.InsTallmentInfoTitle>
          <S.InstallmentInfoData>Venda recorrente</S.InstallmentInfoData>
        </S.InstallmentInfoContainer>
        <Button
          variant="link"
          onClick={setChangeCard}
          disabled={isActionsDisabled}
        >
          Trocar de cartão
        </Button>
        <S.Divider />
        <S.InstallmentInfoContainer>
          <S.InsTallmentInfoTitle>Pagador</S.InsTallmentInfoTitle>
          <S.InstallmentInfoData>
            {saleRecurrenceRecipients.payerName}
          </S.InstallmentInfoData>
          <S.InsTallmentInfoTitle>CPF/CNPJ</S.InsTallmentInfoTitle>
          <S.InstallmentInfoData>
            {saleRecurrenceRecipients.document}
          </S.InstallmentInfoData>
        </S.InstallmentInfoContainer>
        <S.Divider />
        <S.OperationId>Id da Operação</S.OperationId>
        <S.InstallmentInfoData data-direction="start">
          {installmentSelected.recurrenceItemId}
        </S.InstallmentInfoData>
        <InstallmentDetailActions actionType={actionType} />
      </S.Container>
      <ChangeCreditCardDrawer
        isOpen={changeCardOpen}
        onDismiss={setChangeCard}
      />
    </>
  );
}
