import React, { ComponentProps, forwardRef } from "react";
import { CardButtonSkeleton } from "../CardButtonSkeleton";
import * as S from "./CardsListSkeleton.styles";

export const CardsListSkeleton = forwardRef<
  HTMLDivElement,
  ComponentProps<"div"> & { rowsNumber?: number }
>(({ rowsNumber, ...rest }, ref) => {
  return (
    <S.Container {...rest} ref={ref}>
      {new Array(rowsNumber).fill(null).map((_, index) => (
        <React.Fragment key={index}>
          <S.Wrapper>
            <S.Box>
              <S.TodayTitleSkeleton />
              <S.WeekdayTextSkeleton />
            </S.Box>
            <S.ResumeSkeleton />
          </S.Wrapper>

          <CardButtonSkeleton />
          <CardButtonSkeleton />
        </React.Fragment>
      ))}
    </S.Container>
  );
});
CardsListSkeleton.displayName = "CardListSkeleton";
