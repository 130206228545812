import { breakpoints } from "@/styles/breakpoints";
import { Button } from "@hyperlocal/vital";
import styled from "styled-components";

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.neutral.white};
  padding: 24px 0;
  border-radius: ${({ theme }) => theme.borderRadius.sm}; // 8px

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacings.stack.x2s}; // 24px

  overflow: hidden;

  height: 100%;

  ${breakpoints.mobile} {
    padding: 0 0 20px;

    gap: 20px;
  }
`;

export const NewChargeButton = styled(Button)`
  justify-content: center;
  margin: 0 24px;

  ${breakpoints.desktop} {
    display: none;
  }

  ${breakpoints.mobile} {
    margin: 0 16px;
  }
`;

export const EmptyText = styled.span`
  display: grid;
  place-content: center;

  height: 100%;

  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.sm};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.palette.neutral.dark};
  text-align: center;
`;

export const CardsList = styled.ul`
  margin: 0 24px;
  position: relative;
  z-index: 1;
  margin-bottom: 24px;

  ${breakpoints.mobile} {
    margin: 0 16px;
  }
`;

export const Header = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 16px;

  & ~ & {
    margin-top: 16px;
  }

  ${breakpoints.mobile} {
    flex-direction: column;
    align-items: initial;

    gap: ${({ theme }) => theme.spacings.stack.quarck}; // 4px

    margin-bottom: 8px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WeekdayText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.x3s};
  line-height: ${({ theme }) => theme.lineHeights.md};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.palette.neutral.dark};

  display: none;

  ${breakpoints.mobile} {
    display: block;
  }
`;

export const Title = styled.h5`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: ${({ theme }) => theme.lineHeights.md};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.neutral.darkest};

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

export const Resume = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.sm};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.palette.neutral.dark};

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.x3s};
    line-height: ${({ theme }) => theme.lineHeights.md};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
`;

export const Item = styled.li`
  list-style: none;

  & + & {
    margin-top: ${({ theme }) => theme.spacings.inline.x2s}; // 8px
  }
`;
