import { Divider as VitalDivider } from "@hyperlocal/vital";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings.inset.md}; //16px
  background: ${({ theme }) => theme.palette.neutral.white};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  box-shadow: ${({ theme }) => theme.shadows.full};
  width: 343px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const Divider = styled(VitalDivider)`
  width: 100%;
  margin: ${({ theme }) => `${theme.spacings.inset.x2s} 0`};
`;

export const Title = styled.h6`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const Message = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.md};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.palette.neutral.darker};
`;

export const CloseContainer = styled(PopoverPrimitive.Close)`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export const PopoverTrigger = styled(PopoverPrimitive.Trigger)`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export const Arrow = styled(PopoverPrimitive.Arrow)`
  fill: ${({ theme }) => theme.palette.neutral.white};
`;
