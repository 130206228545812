import { Charge, NewCharge, NewChargeReceiptPage } from "@/pages";
import { ChargesFilterProvider } from "@/stores";
import { NewChargeProvider } from "@/stores/NewCharge";
import { canAccessCharge } from "@/utils/allowedRoles";
import { getChargeRoutesData, ProtectedRoute } from "@/utils/utility";
import { Route, Routes as Switch } from "react-router-dom";

const routes = getChargeRoutesData();

export function ChargeRoutes() {
  return (
    <ChargesFilterProvider>
      <NewChargeProvider>
        <Switch>
          <Route
            path={routes.charge}
            element={
              <ProtectedRoute allowedRoles={canAccessCharge}>
                <Charge />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.newCharge}
            element={
              <ProtectedRoute allowedRoles={canAccessCharge}>
                <NewCharge />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.newChargeReceipt}
            element={
              <ProtectedRoute allowedRoles={canAccessCharge}>
                <NewChargeReceiptPage />
              </ProtectedRoute>
            }
          />
        </Switch>
      </NewChargeProvider>
    </ChargesFilterProvider>
  );
}
