import {
  DigitalLinkData,
  DigitalLinkDataTransformed,
} from "@/api/useChargesList";
import { State } from "@/stores/ChargesFilter/types";
import {
  formatBrDate,
  getDigitalLinkStatus,
  getGroupedDate,
  normalizeNFD,
} from "@/utils/format";
import { formatCurrency } from "@/utils/utility";

const filterSearch = (response: DigitalLinkData[], search: string) => {
  const searchValue = normalizeNFD(search);

  const searchResult = response.filter((item) => {
    const client = [
      normalizeNFD(item.client.name),
      normalizeNFD(item.client.document),
      normalizeNFD(item.client.email),
    ];
    const amount = formatCurrency(item.amount);

    const date = formatBrDate(new Date(item.createdAt));

    const searchArray = [...client, amount, date];

    return searchArray.some((value) => value.includes(searchValue));
  });

  const result = searchResult;

  return result;
};

const getFilterDateTime = (date: Date) => {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59);
  return newDate;
};

const filterLinkResponse = (response: DigitalLinkData[], filter: State) => {
  const filteredSearch = filterSearch(response, filter.search);

  const filteredDate = filteredSearch.filter((item) => {
    if (!filter.startDate || !filter.endDate) return true;
    const startDate = filter.startDate.getTime();
    const endDate = getFilterDateTime(filter.endDate).getTime();
    const createdAt = new Date(item.createdAt).getTime();

    return startDate <= createdAt && endDate >= createdAt;
  });

  const filteredStatus = filteredDate.filter((item) => {
    return filter.chargeStatus.includes(getDigitalLinkStatus(item));
  });

  return filteredStatus;
};

export const transformDigitalLinkResponse = (
  response: DigitalLinkData[],
  filter: State,
) => {
  const filteredResponse = filterLinkResponse(response, filter);

  const result = filteredResponse.reduce<DigitalLinkDataTransformed>(
    (prev, linkData) => {
      if (linkData.isPaid) prev.paidAmount += linkData.amount;
      else if (linkData.isCancelled) prev.canceledAmount += linkData.amount;
      else if (linkData.isExpired) prev.expiredAmount += linkData.amount;
      else prev.pendingAmount += linkData.amount;

      const createdAt = new Date(linkData.createdAt);

      const groupedDate = getGroupedDate(createdAt);

      if (!prev.groupedItems[groupedDate]) {
        prev.groupedItems[groupedDate] = {
          items: [],
          totalSold: 0,
        };
      }

      prev.groupedItems[groupedDate].totalSold += linkData.amount;
      prev.groupedItems[groupedDate].items.push(linkData);

      return prev;
    },
    {
      groupedItems: {},
      pendingAmount: 0,
      canceledAmount: 0,
      expiredAmount: 0,
      paidAmount: 0,
    },
  );
  return result;
};
