import { useCallback, useEffect, useState } from "react";
import { getChargeRoutesData, Hooks, setHeader } from "@/utils/utility";
import { useNavigate } from "react-router-dom";

type ModalState = "open" | "closed";

export function useModalState() {
  const isMobile = Hooks.useMediaQuery("mobile");
  const navigate = useNavigate();
  const [modalState, setModalState] = useState<ModalState>("closed");

  const handleCloseModal = useCallback(() => {
    setModalState("closed");
    setHeader({
      title: "Cobrança",
      leftIcon: {
        name: "ArrowArrowNoLineLeft",
        onClick: () => {
          navigate(getChargeRoutesData().dashboard);
        },
      },
    });
  }, [navigate]);

  useEffect(() => {
    handleCloseModal();
  }, [isMobile, handleCloseModal]);

  function handleOpenModal() {
    setModalState("open");
    setHeader({
      title: "Filtrar cobranças",
      leftIcon: {
        name: "SettingTrashClose",
        onClick: handleCloseModal,
      },
    });
  }

  return { handleCloseModal, handleOpenModal, modalState };
}
