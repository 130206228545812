import { RequestError } from "@/types/request";

interface GetErrorMessageProps {
  error: RequestError;
  fallbackMessage: string;
}

export const getErrorMessage = ({
  error,
  fallbackMessage,
}: GetErrorMessageProps) => {
  if (error?.response.data) return fallbackMessage;

  if (typeof error.response.data === "string") return error.response.data;

  if (error.response.data.errors.length)
    return error.response.data.errors[0].friendlyMessage;

  return fallbackMessage;
};
