import { breakpoints } from "@/styles/breakpoints";
import { styled } from "styled-components";

export const Container = styled.div`
  max-width: 1248px;
  padding: ${({ theme }) => theme.spacings.stack.xs}; // 32px
  margin: 0 auto;

  // Isso permite que essa Div seja removida da DOM, mas sem remover os filhos.
  ${breakpoints.mobile} {
    display: contents;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  ${breakpoints.mobile} {
    display: none;
  }
`;

export const TitleContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.inline.sm}; // 24px
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Title = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  line-height: ${({ theme }) => theme.lineHeights.default};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.neutral.darkest};
`;

export const Section = styled.section`
  margin-top: ${({ theme }) => theme.spacings.inline.sm}; // 24px

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacings.stack.x3s}; // 16px

  position: relative;
  overflow: hidden;

  ${breakpoints.mobile} {
    margin-top: 0;

    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto) 1fr;

    height: 100%;
  }
`;

export const LeftWrapper = styled.div`
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.stack.x3s}; // 16px

  // Isso torna os elementos filhos dessa Div acessíveis pelas propriedades CSS
  // da Section, tornando possível ordenar os elementos filhos através do
  // grid-row. Em outras palavras, é como se essa Div fosse excluída da DOM
  // porém sem excluir os filhos.
  ${breakpoints.mobile} {
    display: contents;
  }
`;

export const RightWrapper = styled.div`
  position: absolute;
  grid-column: 2;
  inset: 0;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.stack.x3s}; // 16px

  // Isso torna os elementos filhos dessa Div acessíveis pelas propriedades CSS
  // da Section, tornando possível ordenar os elementos filhos através do
  // grid-row. Em outras palavras, é como se essa Div fosse excluída da DOM
  // porém sem excluir os filhos.
  ${breakpoints.mobile} {
    display: contents;

    // No mobile, caso o component de cards renderize um erro, esse CSS abaixo
    // é responsável por posicionar o erro corretamente de acordo com o layout,
    & > [data-type="errorFallback"]:first-child {
      grid-row: 2;
    }
  }
`;
