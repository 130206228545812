import { useCallback } from "react";
import { useCreateRecurrence, useRecurrenceSimulate } from "@/api/useNewCharge";
import { NewChargeReceipt, ScrollArea, TokenDrawer } from "@/components";
import { useToggle } from "@/hooks";
import { guidTokenType } from "@/services/general";
import { useNewCharge } from "@/stores/NewCharge";
import { StandardCustomEvent } from "@/types/general";
import {
  CREDIT_CARD_ACCEPTABLE_LENGTH,
  CVV_ACCEPTABLE_LENGTH,
  EXPIRATION_ACCEPTABLE_LENGTH,
  MIN_ZIPCODE_LENGTH,
  RECURRENCE_CYCLES_OPTION,
  TODAY,
} from "@/utils/constants";
import { formatDateToApi } from "@/utils/filter";
import { unMask } from "@/utils/format";
import {
  formatCurrency,
  getChargeRoutesData,
  Hooks,
  toast,
  useAccountStore,
  useUserStore,
} from "@/utils/utility";
import { Drawer } from "@hyperlocal/vital";
import { useNavigate } from "react-router-dom";
import { RecurrenceForm } from "../../Recurrence";
import * as S from "./RecurrenceActions.styles";

interface RecurrenceActionsProps extends RecurrenceForm {
  amount: number;
}

const { useDispatchTokenEvent, useListenCustomEvent, useMediaQuery } = Hooks;

const routes = getChargeRoutesData();

const formValidation = (data: RecurrenceActionsProps) => {
  const zipcodeCondition = unMask(data.zipcode).length === MIN_ZIPCODE_LENGTH;

  const creditCardLength = unMask(data.cardNumber).length;

  const cardExpirationCondition =
    unMask(data.cardExpiration).length === EXPIRATION_ACCEPTABLE_LENGTH;

  const CVVCondition = data.CVV.length === CVV_ACCEPTABLE_LENGTH;

  const cardNumberCondition =
    CREDIT_CARD_ACCEPTABLE_LENGTH.includes(creditCardLength);

  const validationMap: Record<keyof RecurrenceActionsProps, boolean> = {
    amount: data.amount > 1,
    cardExpiration: cardExpirationCondition,
    cardName: !!data.cardName,
    cardNumber: cardNumberCondition,
    citiesList: true,
    city: !!data.city,
    complement: true,
    creditCardGuid: data.creditCardGuid !== "",
    CVV: CVVCondition,
    extractDescription: !!data.extractDescription,
    guidSaleRecurrenceRecipient: !!data.guidSaleRecurrenceRecipient,
    internalIdentification: !!data.internalIdentification,
    neighborhood: !!data.neighborhood,
    number: !!data.number,
    processingDate: !!data.processingDate,
    recurrenceCycle: !!data.recurrenceCycle,
    state: !!data.state,
    street: !!data.street,
    totalInstallments: !!data.totalInstallments,
    zipcode: zipcodeCondition,
  };

  return Object.keys(data).every((key) => validationMap[key]);
};

export function RecurrenceActions({
  amount,
  ...formData
}: RecurrenceActionsProps) {
  const [isSimulationOpen, setSimulationDrawer] = useToggle();
  const [isTokenOpen, setTokenDrawer] = useToggle();
  const [isReceiptOpen, setReceiptDrawer] = useToggle();

  const isMobile = useMediaQuery("mobile");

  const navigate = useNavigate();

  const { payerData, updateChargeData, resetNewChargeStore } = useNewCharge();

  const onSuccess = () => {
    updateChargeData({
      amount,
      type: "DIGITAL_SALE_RECURRENCE",
      created: TODAY,
      description: formData.extractDescription,
      firstDueDate: formData.processingDate,
      recurrenceCycle: formData.recurrenceCycle,
      saleMaxInstallments: formData.totalInstallments,
    });

    if (isMobile) {
      navigate(routes.newChargeReceipt);
    } else {
      setReceiptDrawer();
    }
  };

  const onReceiptDismiss = () => {
    setReceiptDrawer();
    resetNewChargeStore();
    navigate(routes.charge);
  };

  const simulateMutation = useRecurrenceSimulate({
    onSuccess: () => {
      setSimulationDrawer();
    },
    onError: () => {
      toast.error("Não foi possível realizar a simulação.");
    },
  });

  const createRecurrenceMutation = useCreateRecurrence({
    onError: (error) => {
      const errorMessage =
        error?.response?.data[0]?.message ||
        "Houve um erro ao criar a recorrência";
      toast.error(errorMessage);
    },
    onSuccess,
  });

  const {
    user: { userId },
  } = useUserStore();
  const { currentAccountId: guidAccount } = useAccountStore();

  const dispatchToken = useDispatchTokenEvent({
    detail: {
      eventIdentifier: "CREATE_DIGITAL_SALE_RECURRENCE",
      request: {
        guid_token_type: guidTokenType.CREATE_DIGITAL_SALE_RECURRENCE,
        guid_user: userId,
      },
    },
  });

  const onSimulate = () => {
    const processingDate = formatDateToApi(new Date(formData.processingDate));
    simulateMutation.mutate({
      amount,
      guidAccount,
      card_number: unMask(formData.cardNumber),
      payer_name: payerData.name,
      processing_date: processingDate,
      RecurrenceType: formData.recurrenceCycle,
      total_installments: formData.totalInstallments,
    });
  };

  const onCreateRecurrence = useCallback(
    (e: StandardCustomEvent) => {
      if (isSimulationOpen) {
        setSimulationDrawer();
      }
      const [DDD, cellphone] = payerData.cellphone.split(" ");
      const [expirationMonth, expirationYear] =
        formData.cardExpiration.split("/");

      setTokenDrawer();
      createRecurrenceMutation.mutate({
        amount,
        guidAccount,
        address: formData.street,
        area_code: DDD,
        cellphone: unMask(cellphone),
        card_holder: formData.cardName,
        card_number: unMask(formData.cardNumber),
        city: formData.city,
        complement: formData.complement || "Sem comp.",
        country: "BR",
        CVV: formData.CVV,
        document: unMask(payerData.document),
        email: payerData.email,
        extract_description: formData.extractDescription,
        guid_creditcard_company: formData.creditCardGuid,
        guid_user: userId,
        internal_description: formData.internalIdentification,
        month_valid_thru: expirationMonth,
        year_valid_thru: expirationYear,
        zipcode: formData.zipcode,
        name: payerData.name,
        number: formData.number,
        processing_date: formatDateToApi(formData.processingDate),
        RecurrenceType: formData.recurrenceCycle,
        state: formData.state,
        total_installments: formData.totalInstallments,
        token: e.detail.token,
        guid_sale_recurrence_recipient: formData.guidSaleRecurrenceRecipient,
      });
    },
    [
      amount,
      createRecurrenceMutation,
      formData.CVV,
      formData.cardExpiration,
      formData.cardName,
      formData.cardNumber,
      formData.city,
      formData.complement,
      formData.creditCardGuid,
      formData.extractDescription,
      formData.guidSaleRecurrenceRecipient,
      formData.internalIdentification,
      formData.number,
      formData.processingDate,
      formData.recurrenceCycle,
      formData.state,
      formData.street,
      formData.totalInstallments,
      formData.zipcode,
      guidAccount,
      isSimulationOpen,
      payerData.cellphone,
      payerData.document,
      payerData.email,
      payerData.name,
      setSimulationDrawer,
      setTokenDrawer,
      userId,
    ],
  );

  useListenCustomEvent({
    eventName: "CREATE_DIGITAL_SALE_RECURRENCE",
    callback: onCreateRecurrence,
  });

  return (
    <>
      <S.Container>
        <S.Button
          isLoading={createRecurrenceMutation.isLoading}
          onClick={setTokenDrawer}
          disabled={
            !formValidation({ ...formData, amount }) ||
            simulateMutation.isLoading
          }
        >
          Solicitar pagamento
        </S.Button>
        <S.Button
          isLoading={simulateMutation.isLoading}
          variant="secondary"
          onClick={onSimulate}
          disabled={
            !formValidation({ ...formData, amount }) ||
            createRecurrenceMutation.isLoading
          }
        >
          Simular recorrência
        </S.Button>
      </S.Container>
      <Drawer
        position="right"
        title="Simular recorrência"
        onDismiss={setSimulationDrawer}
        open={isSimulationOpen}
      >
        <ScrollArea>
          <S.SimulationContainer>
            <S.Table>
              <thead>
                <S.Th data-align="start">Parcelas</S.Th>
                <S.Th>Valor</S.Th>
                <S.Th data-align="end">Recorrência</S.Th>
              </thead>
              <tbody>
                {simulateMutation?.data?.map((recurrence, index) => {
                  const processingDate = new Date(recurrence.processing_date);
                  const installment = String(index + 1);
                  return (
                    <S.Tr key={index}>
                      <S.Td>
                        {installment.padStart(2, "0")}/
                        {recurrence.total_installments} -{" "}
                        {processingDate.toLocaleDateString()}
                      </S.Td>
                      <S.Td data-align="center" data-type="value">
                        {formatCurrency(recurrence.amount)}
                      </S.Td>
                      <S.Td data-align="end">
                        {
                          RECURRENCE_CYCLES_OPTION[
                            recurrence.recurrenceType.toLowerCase()
                          ]
                        }
                      </S.Td>
                    </S.Tr>
                  );
                })}
              </tbody>
            </S.Table>
          </S.SimulationContainer>
        </ScrollArea>
        <S.Button variant="primary" onClick={setTokenDrawer}>
          Solicitar pagamento
        </S.Button>
      </Drawer>
      <TokenDrawer
        isOpen={isTokenOpen}
        onClose={setTokenDrawer}
        dispatchOnMount={dispatchToken}
      />
      <Drawer
        open={isReceiptOpen}
        onDismiss={onReceiptDismiss}
        position="right"
        title="Resumo da venda"
      >
        {isReceiptOpen && <NewChargeReceipt />}
      </Drawer>
    </>
  );
}
