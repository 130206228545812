export const DATE_RANGES = ["7", "15", "30", "60", "90"] as const;

export const INSTALLMENT_STATUS = [
  "Pagas",
  "Pendentes",
  "Canceladas",
  "Agendadas",
  "Pausadas",
] as const;

export function isValidDate(date: Date) {
  return date instanceof Date && Number.isFinite(date.getTime());
}

export function formatDateToApi(date: Date) {
  if (isValidDate(date) && date) {
    return date.toISOString().split("T")[0];
  }
  return undefined;
}
