import { useState } from "react";
import { useBalance } from "@/api/useBalance";
import { useInfiniteChargeList } from "@/api/useChargesList";
import Icon from "@hyperlocal/vital-icons";
import * as S from "./Balance.styles";
import { BalanceSection } from "./BalanceSection";

export function Balance() {
  const [isValuesVisible, setIsValuesVisible] = useState(false);
  const {
    data: charges,
    isLoading: isChargesLoading,
    isFetching,
  } = useInfiniteChargeList();

  const {
    data: balance,
    isLoading: isBalanceLoading,
    isError: isBalanceError,
  } = useBalance();

  function handleToggleValuesVisibility() {
    setIsValuesVisible((oldValue) => !oldValue);
  }

  const isLoading = isBalanceLoading || isChargesLoading;

  return (
    <S.Container>
      <BalanceSection
        title="Saldo em conta"
        variant="balance"
        value={!isBalanceError ? balance?.Balance : 0}
        isLoading={isBalanceLoading}
        isValuesVisible={isValuesVisible}
      />

      <S.Divider />

      <BalanceSection
        title="Recebidos"
        variant="received"
        value={charges.paidAmount}
        isLoading={isFetching}
        isValuesVisible={isValuesVisible}
      />

      <S.HiddenValuesButton
        onClick={handleToggleValuesVisibility}
        disabled={isLoading}
        aria-label="Esconder valores"
        data-testid="toggle-button"
      >
        <Icon
          name={isValuesVisible ? "ComputersEye" : "ComputersEyeSlash"}
          fill="#000000"
          width="24"
          height="24"
        />
      </S.HiddenValuesButton>
    </S.Container>
  );
}
