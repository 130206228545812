import { useReducer } from "react";

export type ReducerFormState<FormState> = FormState;

export type ReducerFormAction<D, T extends keyof ReducerFormState<D>> = {
  name: T;
  value: ReducerFormState<D>[T];
};

export function useFormReducer<
  TState extends { [x: string]: TState[keyof TState] },
>(initialState: TState) {
  const formReducer = (
    state: ReducerFormState<TState>,
    { name, value }: ReducerFormAction<TState, keyof TState>,
  ) => {
    return {
      ...state,
      [name]: value,
    };
  };
  const [formState, dispatch] = useReducer(formReducer, initialState);

  return { formState, dispatch };
}
