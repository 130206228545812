import { ChargeStatus, ChargeType } from "@/types/charge";
import { IconName } from "@/types/icons";
import * as S from "./Icon.styles";

function getIconName(type: ChargeType, status: ChargeStatus): IconName {
  if (status === "Estornado") return "GeralMoneyPayReturned";

  const iconNameMappedToType: Record<ChargeType, IconName> = {
    "Venda digitada": "GeralMoneyPay",
    Boleto: "GeralBarcode",
    Recorrência: "GeralMoneyAutopay",
    "Boleto recorrência": "GeralMoneyAutopay",
  };

  return iconNameMappedToType[type];
}

interface IconProps {
  type: ChargeType;
  status: ChargeStatus;
  size: number;
  padding: number;
}

export function Icon({ type, status, size, padding }: IconProps) {
  return (
    <S.Icon
      name={getIconName(type, status)}
      data-status={status}
      fill="currentColor"
      width={size}
      height={size}
      padding={padding}
    />
  );
}
