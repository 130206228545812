import { breakpoints } from "@/styles/breakpoints";
import styled from "styled-components";

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.neutral.white};

  padding-bottom: ${({ theme }) => theme.spacings.inset.lg};
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  display: flex;
  flex-direction: column;

  ${breakpoints.mobile} {
    padding: 0;
    overflow-y: auto;
  }
`;

export const PayerInfoContainerDesktop = styled.div`
  margin: ${({ theme }) => `${theme.spacings.inset.md} 0`};
  gap: ${({ theme }) => theme.spacings.inset.x2s};
  display: flex;
  flex-direction: column;

  ${breakpoints.mobile} {
    display: none;
  }
`;

export const PayerInfoContainerMobile = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: ${({ theme }) => theme.spacings.inset.md};
  align-items: center;
  margin-bottom: 16px;

  ${breakpoints.desktop} {
    display: none;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.inset.x3s};
`;

export const PayerInfoTitle = styled.h5`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.neutral.darkest};

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

export const DataList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const DataTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.x2s};
  color: ${({ theme }) => theme.palette.neutral.darkest};

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.x4s};
  }
`;

export const DataDescription = styled.span`
  text-align: end;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.palette.neutral.dark};

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.x3s};
    text-align: initial;
  }
`;

export const IconContainer = styled.button`
  text-align: end;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-out;

  &[data-active="true"] {
    grid-template-rows: 1fr;
  }
`;

export const Inner = styled.div`
  overflow: hidden;
`;
