import { useEffect, useMemo, useState } from "react";
import { InputMask } from "@/components";
import { useNewCharge } from "@/stores/NewCharge";
import { unMask } from "@/utils/format";
import { validateEmail } from "@/utils/validation";
import { isValidCNPJ, isValidCPF } from "@brazilian-utils/brazilian-utils";
import { Drawer } from "@hyperlocal/vital";
import * as S from "./NewChargeDrawer.styles";

const CPF_MIN_LENGTH = 11;

const CNPJ_MIN_LENGTH = 14;

const CELLPHONE_MIN_LENGTH = 11;

interface NewChargeDrawerProps {
  isOpen: boolean;
  onDismiss: () => void;
  onSubmit?: () => void;
}

type FormInitialState = {
  name: string;
  document: string;
  email: string;
  cellphone: string;
};

type InputErrors = Partial<Record<keyof FormInitialState, string>>;

const formValidation = (form: FormInitialState) => {
  const validationMap: Record<keyof FormInitialState, boolean> = {
    name: !!form.name && form.name.trim().split(" ").length > 1,
    document:
      unMask(form.document).length === CPF_MIN_LENGTH
        ? isValidCPF(unMask(form.document))
        : isValidCNPJ(unMask(form.document)),
    email: validateEmail(form.email),
    cellphone: unMask(form.cellphone).length === CELLPHONE_MIN_LENGTH,
  };

  return Object.keys(form).every((key) => validationMap[key]);
};

const validateDocumentInput = (document: string) => {
  const unmaskedDocument = unMask(document);

  if (unmaskedDocument.length === CNPJ_MIN_LENGTH)
    return isValidCNPJ(unmaskedDocument);

  if (unmaskedDocument.length === CPF_MIN_LENGTH)
    return isValidCPF(unmaskedDocument);

  return false;
};

export function NewChargeDrawer({
  isOpen,
  onDismiss,
  onSubmit,
}: NewChargeDrawerProps) {
  const { updatePayerData, payerData } = useNewCharge();

  const initialState: FormInitialState = useMemo(
    () => ({
      document: payerData.document,
      name: payerData.name,
      cellphone: payerData.cellphone,
      email: payerData.email,
    }),
    [payerData.cellphone, payerData.document, payerData.email, payerData.name],
  );
  const [formState, setFormState] = useState<FormInitialState>(initialState);
  const [inputErrors, setInputErrors] = useState<InputErrors | null>(null);

  useEffect(() => {
    setFormState(initialState);
  }, [initialState]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    const name = target.name as keyof FormInitialState;
    const value = target.value;

    setInputErrors({ [name]: "" });
    setFormState((oldValue) => ({ ...oldValue, [name]: value }));
  };

  const onContinue = () => {
    updatePayerData(formState);
    if (onSubmit) {
      onSubmit();
    }
    onDismiss();
  };

  function onDrawerDismiss() {
    setFormState(initialState);
    setInputErrors(null);
    onDismiss();
  }

  return (
    <Drawer
      open={isOpen}
      onDismiss={onDrawerDismiss}
      position="right"
      title="Dados do cliente"
    >
      <S.Container>
        <S.Input
          type="text"
          value={formState.name}
          label="Nome completo"
          placeholder="Digite aqui..."
          name="name"
          onChange={handleChange}
          onBlur={() => {
            if (formState.name.trim().split(" ").length <= 1) {
              setInputErrors({
                name: "Por favor, insira o nome completo (nome e sobrenome).",
              });
            }
          }}
          helper={inputErrors?.name ? "error" : "info"}
          helperText={inputErrors?.name}
        />
        <InputMask
          label="CPF/CNPJ"
          mask={
            unMask(formState.document).length <= CPF_MIN_LENGTH
              ? "999.999.999-999"
              : "99.999.999/9999-99"
          }
          maskChar=""
          name="document"
          value={formState.document}
          onChange={handleChange}
          onBlur={() => {
            if (!validateDocumentInput(formState.document)) {
              const isCNPJ =
                unMask(formState.document).length === CNPJ_MIN_LENGTH;

              const message = isCNPJ ? "CNPJ inválido" : "CPF inválido";

              setInputErrors({
                document: message,
              });
            }
          }}
          helper={inputErrors?.document ? "error" : "info"}
          helperText={inputErrors?.document}
        />
        <S.Input
          type="text"
          label="Email"
          placeholder="xxxx@email.com"
          name="email"
          onChange={handleChange}
          value={formState.email}
          onBlur={(e) => {
            const email = e.target.value;
            if (!validateEmail(email)) {
              setInputErrors({
                email: "E-mail inválido",
              });
            }
          }}
          helper={inputErrors?.email ? "error" : "info"}
          helperText={inputErrors?.email}
        />
        <InputMask
          label="Telefone"
          mask="99 99999-9999"
          placeholder="xx xxxxx-xxxx"
          name="cellphone"
          onChange={handleChange}
          value={formState.cellphone}
        />
        <S.Button
          testId="new-charge-drawer-submit" // "button-new-charge-drawer-submit"
          onClick={onContinue}
          disabled={!formValidation(formState)}
        >
          Continuar
        </S.Button>
      </S.Container>
    </Drawer>
  );
}
