import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  ChargeData,
  PayerData,
  NewChargeContext as TNewChargeContext,
} from "./types";

export const contextInitialValue: TNewChargeContext = {
  payerData: {
    cellphone: "",
    document: "",
    email: "",
    name: "",
  },
  chargeData: {
    amount: 1,
    type: "BOLETO",
    chargeRecurrence: "ONCE",
    created: new Date(),
    description: "",
    dueDate: new Date(),
  },
  updatePayerData: () => null,
  updateChargeData: () => null,
  resetNewChargeStore: () => null,
};

export const NewChargeContext =
  createContext<TNewChargeContext>(contextInitialValue);

export const NewChargeProvider = ({ children }: { children: ReactNode }) => {
  const [payerData, setPayerData] = useState<PayerData>(
    contextInitialValue.payerData,
  );
  const [chargeData, setChargeData] = useState<ChargeData>(
    contextInitialValue.chargeData,
  );

  const resetNewChargeStore = useCallback(() => {
    setPayerData(contextInitialValue.payerData);
    setChargeData(contextInitialValue.chargeData);
  }, []);

  const value = useMemo(
    () => ({
      payerData,
      updatePayerData: setPayerData,
      chargeData,
      updateChargeData: setChargeData,
      resetNewChargeStore,
    }),
    [chargeData, payerData, resetNewChargeStore],
  );

  return (
    <NewChargeContext.Provider value={value}>
      {children}
    </NewChargeContext.Provider>
  );
};

export const useNewCharge = () => {
  const value = useContext(NewChargeContext);

  if (!value)
    throw new Error("NewChargeContext must be used within NewChargeProvider");

  return value;
};
