import * as Tooltip from "@radix-ui/react-tooltip";
import styled, { keyframes } from "styled-components";

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(4px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

export const TooltipContent = styled(Tooltip.Content)`
  background: ${({ theme }) => theme.palette.neutral.lighter};
  border-radius: ${({ theme }) => theme.borderRadius.sm}; // 8px

  padding: 12px;

  filter: drop-shadow(0 10px 10px hsla(206, 22%, 7%, 0.1))
    drop-shadow(0 0 5px hsla(206, 22%, 7%, 0.05))
    drop-shadow(0 0px 1px ${({ theme }) => theme.palette.neutral.light});

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-side="bottom"] {
    animation-name: ${slideUpAndFade};
  }

  // Fazer a seta (::after) aparecer atrás do elemento.
  transform-style: preserve-3d;

  z-index: 2;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg) translateZ(-1px);

    background: inherit;
    width: 14px;
    height: 14px;

    border-top-left-radius: ${({ theme }) => theme.borderRadius.xs}; // 4px
  }
`;
