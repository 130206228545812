import { breakpoints } from "@/styles/breakpoints";
import IntlCurrencyInput from "react-intl-currency-input";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpoints.mobile} {
    margin-bottom: ${({ theme }) => theme.spacings.inset.lg};
  }
`;

export const Label = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.palette.neutral.darker};
  width: 100%;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacings.stack.nano};
  font-size: ${({ theme }) => theme.fontSizes.xs};

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.x2s};
  }
`;

export const Value = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.neutral.light}`};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 283px;
  gap: ${({ theme }) => theme.spacings.inline.x2s};
`;

export const Currency = styled.small`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.sm};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.palette.neutral.darkest};
`;

export const SInput = styled(IntlCurrencyInput)`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  line-height: ${({ theme }) => theme.lineHeights.sm};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.fonts.base};
  color: ${({ theme }) => theme.palette.primary.main};
  border: none;
  outline: none;
  width: 100%;
  text-align: center;

  &:disabled {
    margin-right: ${({ theme }) => theme.spacings.inset.lg}; //24px
  }
`;

export const ClearInput = styled.button`
  background-color: unset;
  border: none;
  margin: 0px !important;
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;

  .closeIcon {
    fill: ${({ theme }) => theme.palette.neutral.light};
  }
`;
