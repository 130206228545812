import { getErrorMessage } from "@/api/utils/errorMessage";
import { api, MutationConfig } from "@/lib";
import { toast } from "@hyperlocal/vital2";
import { useMutation } from "@tanstack/react-query";

const CancelLink = async ({ guidLink }: { guidLink: string }) => {
  const response = await api.delete("/paymentLink/cancelLink", {
    data: { id: guidLink },
  });
  return response.data;
};

export const useCancelLink = (config?: MutationConfig<typeof CancelLink>) => {
  return useMutation({
    ...config,
    mutationKey: ["cancelLink"],
    mutationFn: CancelLink,

    onError: (error) => {
      const errorMessage = getErrorMessage({
        error,
        fallbackMessage: "Houve um erro ao cancelar o link de cobrança",
      });

      toast({
        title: errorMessage,
        variant: "error",
      });
    },
  });
};
