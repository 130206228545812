import { useCallback, useLayoutEffect } from "react";
import { ChargeRoutesPath } from "@/types/routes";
import { getChargeRoutesData, setHeader } from "@/utils/utility";
import { useNavigate } from "react-router-dom";

interface UseSetHeaderProps {
  title: string;
  backRoute: ChargeRoutesPath | "previousRoute";
}

export function useSetHeader({ title, backRoute }: UseSetHeaderProps) {
  const navigate = useNavigate();

  const handleSetHeader = useCallback(() => {
    setHeader({
      title,
      leftIcon: {
        name: "ArrowArrowNoLineLeft",
        onClick: () => {
          setHeader({});

          if (backRoute === "previousRoute") {
            navigate(-1);
            return;
          }

          navigate(getChargeRoutesData()[backRoute]);
        },
      },
    });
  }, [navigate, backRoute, title]);

  useLayoutEffect(() => {
    handleSetHeader();
  }, [handleSetHeader]);

  return handleSetHeader;
}
