import { FutureInstallments, InstallmentDetailsPage } from "@/pages";
import { FilterProvider } from "@/stores";
import { getChargeRoutesData } from "@/utils/utility";
import { Route, Routes as Switch } from "react-router-dom";

const routes = getChargeRoutesData();

export function FutureInstallmentsRoutes() {
  return (
    <FilterProvider>
      <Switch>
        <Route
          path={routes.futureInstallments}
          element={<FutureInstallments />}
        />
        <Route
          path={routes.futureInstallmentDetail}
          element={<InstallmentDetailsPage />}
        />
      </Switch>
    </FilterProvider>
  );
}
