import { breakpoints } from "@/styles/breakpoints";
import { Avatar as VitalAvatar } from "@hyperlocal/vital";
import styled from "styled-components";

export const Avatar = styled(VitalAvatar)`
  margin-right: ${({ theme }) => theme.spacings.inset.x2s};
  width: ${({ theme }) => theme.spacings.inset.xl};
  height: ${({ theme }) => theme.spacings.inset.xl};
  &[data-state="paid"] {
    background: ${({ theme }) => theme.palette.status.success.successLight};
    & > svg > path {
      fill: ${({ theme }) => theme.palette.status.success.successDark};
    }
  }
  &[data-state="pending"] {
    background: ${({ theme }) => theme.palette.status.warning.warningLight};
    & > svg > path {
      fill: ${({ theme }) => theme.palette.status.warning.warningDark};
    }
  }
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.neutral.white};
  padding: ${({ theme }) => theme.spacings.squished.lg};
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${breakpoints.mobile} {
    border: ${({ theme }) => `1px solid ${theme.palette.neutral.light}`};
    padding: ${({ theme }) => theme.spacings.inset.md};
  }
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.palette.neutral.darkest};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.medium};

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.x2s};
  }
`;

export const SubTitle = styled.p`
  color: ${({ theme }) => theme.palette.neutral.dark};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.x4s};
  }
`;

export const Amount = styled.h5`
  color: ${({ theme }) => theme.palette.status.success.successDark};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: 30px;
  &[data-state="paid"] {
    color: ${({ theme }) => theme.palette.status.success.successDark};
  }
  &[data-state="pending"] {
    color: ${({ theme }) => theme.palette.neutral.dark};
  }

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

export const Wrapper = styled.div`
  display: flex;
`;
