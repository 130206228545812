import * as S from "./Spinner.styles";

export function Spinner() {
  return (
    <S.Svg role="progressbar" viewBox="0 0 50 50">
      <S.Circle
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </S.Svg>
  );
}
