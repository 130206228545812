import { ChargeRoutesPath } from "@/types/routes";
import { getChargeRoutesData } from "@/utils/utility";
import {
  Breadcrumb as VitalBreadcrumb,
  BreadCrumbItem as VitalBreadcrumbItem,
} from "@hyperlocal/vital";
import { useNavigate } from "react-router-dom";

export interface BreadcrumbItem {
  name: string;
  path: ChargeRoutesPath;
}

interface BreadcrumbProps {
  breadcrumbs: BreadcrumbItem[];
  onBreadcrumbClick?: () => void;
}

const routes = getChargeRoutesData();

export function Breadcrumb({
  breadcrumbs,
  onBreadcrumbClick,
}: BreadcrumbProps) {
  const navigate = useNavigate();

  function navigateToDashboard() {
    navigate(getChargeRoutesData().dashboard);
  }

  function navigateToRoute(path: ChargeRoutesPath, index: number) {
    const isLastBreadcrumb = breadcrumbs.length - 1 === index;

    if (isLastBreadcrumb) return;

    navigate(routes[path]);
  }

  return (
    <VitalBreadcrumb onClickIcon={onBreadcrumbClick ?? navigateToDashboard}>
      {breadcrumbs.map((item, index) => (
        <VitalBreadcrumbItem
          key={item.name}
          onClick={() => navigateToRoute(item.path, index)}
        >
          {item.name}
        </VitalBreadcrumbItem>
      ))}
    </VitalBreadcrumb>
  );
}
