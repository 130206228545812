import { formatCurrency } from "@/utils/utility";

export const generateMaxInstallmentsOption = (maxInstallment: number) => {
  return new Array(maxInstallment).fill(null).map((_, index) => index + 1);
};

export const getInstallmentValue = (dividend: number, divisor: number) => {
  const result = dividend / divisor;
  return formatCurrency(result);
};

export const getInstallmentSelectOptions = (
  totalAmount: number,
  maxInstallment: number,
) => {
  const installmentOption = generateMaxInstallmentsOption(maxInstallment);

  return installmentOption.map((installment) => {
    const installmentValue = getInstallmentValue(totalAmount, installment);
    return `${installment}x de ${installmentValue}`;
  });
};
