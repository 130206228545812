import { Fragment, useEffect, useState } from "react";
import { useInfiniteChargeList } from "@/api/useChargesList";
import { useToggle } from "@/hooks";
import { CardsListSkeleton } from "@/pages/Charge/components/ChargesPerPeriod/CardsListSkeleton";
import { ChargeDetailsDrawer } from "@/pages/Charge/components/ChargesPerPeriod/ChargeDetailsDrawer";
import { getDayAndMonth, getWeekday, isToday } from "@/pages/Charge/utils";
import { formatCurrency } from "@/utils/utility";
import { parse } from "date-fns";
import { useInView } from "react-intersection-observer";
import { CardButton } from "../CardButton";
import { DrawerDetailsState } from "../ChargesPerPeriod";
import { ScrollArea } from "../ScrollArea";
import * as S from "./CardsList.styles";

export const initialDrawerDetails: DrawerDetailsState = {
  establishmentName: "",
  limitInstallments: 0,
  description: "",
  checkoutUrl: "",
  amount: 0,
  createdAt: "",
  guidLink: "",
  guidAccount: "",
  expirationDate: "",
  address: {
    zipcode: "",
    street: "",
    district: "",
    city: "",
    state: "",
    complement: "",
    number: "",
  },
  client: {
    name: "",
    document: "",
    email: "",
    mobilePhoneNumber: "",
    mobilePhoneArea: 0,
  },
  paymentsAccepted: [],
  referenceNumber: null,
  isPaid: false,
  isCancelled: false,
  isExpired: false,
};

function EmptyCharges() {
  return <S.EmptyText>Nenhuma cobrança realizada neste período</S.EmptyText>;
}

interface CardsListProps {
  setIsNewChargeOpen: () => void;
}

export function CardsList({ setIsNewChargeOpen }: CardsListProps) {
  const { data, isLoading, hasNextPage, fetchNextPage } =
    useInfiniteChargeList();
  const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] = useToggle();
  const [drawerDetails, setDrawerDetails] =
    useState<DrawerDetailsState>(initialDrawerDetails);

  const { ref, inView } = useInView();

  function handleOpenDrawer(details: DrawerDetailsState) {
    setDrawerDetails(details);
    setIsDetailsDrawerOpen();
  }

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  if (isLoading) return <CardsListSkeleton />;

  if (!data || !Object.values(data.groupedItems).length)
    return <EmptyCharges />;

  return (
    <>
      <ScrollArea>
        <S.CardsList>
          {Object.entries(data.groupedItems).map(([dayDate, daySales]) => {
            const date = parse(dayDate, "dd/MM/yy", new Date());
            return (
              <Fragment key={date.toDateString()}>
                <S.Header>
                  <S.Wrapper>
                    <S.Title>
                      {isToday(new Date(date)) && "Hoje, "}
                      {getDayAndMonth(new Date(date))}
                    </S.Title>
                    <S.WeekdayText>{getWeekday(new Date(date))}</S.WeekdayText>
                  </S.Wrapper>
                  <S.Resume>
                    Vendas no dia {formatCurrency(daySales.totalSold)}
                  </S.Resume>
                </S.Header>
                {daySales.items.map((item) => (
                  <S.Item key={item.guidLink}>
                    <CardButton
                      {...item}
                      handleClick={() => handleOpenDrawer(item)}
                    />
                  </S.Item>
                ))}
              </Fragment>
            );
          })}
        </S.CardsList>
        {hasNextPage ? (
          <CardsListSkeleton ref={ref} />
        ) : (
          <span className="mt-6 flex w-full justify-center text-center">
            Não há mais nada por aqui...
            <br /> Para consultar datas anteriores use o filtro.
          </span>
        )}
      </ScrollArea>
      <ChargeDetailsDrawer
        open={isDetailsDrawerOpen}
        setIsNewChargeOpen={setIsNewChargeOpen}
        onDismiss={setIsDetailsDrawerOpen}
        details={drawerDetails}
        position="right"
      />
    </>
  );
}
