import { breakpoints } from "@/styles/breakpoints";
import {
  Button as VitalButton,
  Divider as VitalDivider,
} from "@hyperlocal/vital";
import { Link } from "react-router-dom";
import { styled } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacings.stack.x2s}; // 24px

  flex: 1;

  ${breakpoints.desktop} {
    max-width: 432px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.stack.x3s}; // 16px
`;

export const Header = styled.header`
  margin: 32px auto 0;
  width: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h4`
  margin: 16px 0 4px;

  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: ${({ theme }) => theme.lineHeights.md};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.neutral.darkest};
`;

export const Status = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: ${({ theme }) => theme.lineHeights.md};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.primary.darker};

  &[data-status="Pago"] {
    color: ${({ theme }) => theme.palette.status.success.successDark};
  }

  &[data-status="Pendente"] {
    color: ${({ theme }) => theme.palette.status.warning.warningDark};
  }

  &[data-status="Estornado"] {
    color: ${({ theme }) => theme.palette.primary.darker};
  }

  &[data-status="Cancelado"],
  &[data-status="Expirado"] {
    color: ${({ theme }) => theme.palette.status.error.errorDark};
  }

  &[data-status="Pausado"] {
    color: ${({ theme }) => theme.palette.neutral.darker};
  }
`;

export const SmallTitle = styled.span`
  margin-bottom: 4px;

  display: block;

  font-size: ${({ theme }) => theme.fontSizes.x2s};
  line-height: ${({ theme }) => theme.lineHeights.md};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.palette.neutral.darkest};
`;

export const Divider = styled(VitalDivider)`
  background: ${({ theme }) => theme.palette.neutral.light};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LeftText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.default};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.palette.neutral.darkest};
`;

export const RightText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.md};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.palette.neutral.dark};
`;

export const Button = styled(VitalButton)`
  justify-content: center;
  width: 100%;

  & + & {
    margin-top: ${({ theme }) => theme.spacings.inline.x2s}; // 8px
  }
`;

export const CancelChargeButton = styled(Button)`
  &[data-link="true"] {
    color: ${({ theme }) => theme.palette.status.error.errorDark};
    border-radius: ${({ theme }) => theme.borderRadius.sm}; // 8px
    transition: background 300ms ease;

    &:hover,
    &:active,
    &:focus {
      background: ${({ theme }) => theme.palette.neutral.lighter};
      color: ${({ theme }) => theme.palette.status.error.errorDark};
    }
  }
`;

export const FutureInstallmentsButton = styled(Link)`
  margin-top: ${({ theme }) => theme.spacings.inline.x2s}; // 8px

  display: flex;
  justify-content: center;

  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.lineHeights.md};

  text-decoration: none;

  height: 48px;
  padding: ${({ theme }) => theme.spacings.squished.md};

  border: none;
  cursor: pointer;

  transition:
    background-color 300ms ease 0s,
    color 300ms ease 0s;
  background: transparent;

  color: ${({ theme }) => theme.palette.primary.main};
  box-shadow: ${({ theme }) =>
    `inset 0 0 0 ${theme.borderWidths.md} ${theme.palette.primary.main}`};
  border-radius: ${({ theme }) => theme.radii.button};

  &:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
    box-shadow: ${({ theme }) =>
      `inset 0 0 0 ${theme.borderWidths.md} ${theme.palette.primary.dark}`};
  }

  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.primary.darker};
    box-shadow: ${({ theme }) =>
      `inset 0 0 0 ${theme.borderWidths.md} ${theme.palette.primary.darker}`};
  }
`;
