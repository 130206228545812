import { useFilter } from "@/stores";
import {
  DATE_RANGES,
  formatDateToApi,
  INSTALLMENT_STATUS,
} from "@/utils/filter";
import { Checkbox, Chips } from "@hyperlocal/vital";
import * as S from "./Filter.styles";

export function Filter() {
  const { filterState, dispatchFilter } = useFilter();

  return (
    <S.Container>
      <S.Title>Filter parcelas</S.Title>
      <S.DateRangeContainer>
        {DATE_RANGES.map((range) => (
          <Chips
            onClick={() =>
              dispatchFilter({
                type: "SET_SELECTED_RANGE",
                payload: { selectedRange: range },
              })
            }
            aria-pressed={filterState.selectedRange === range}
            active={filterState.selectedRange === range}
            key={range}
          >
            {range} dias
          </Chips>
        ))}
      </S.DateRangeContainer>
      <S.SearchInputContainer>
        <S.Input
          type="search"
          value={filterState.search}
          placeholder="Buscar..."
          onChange={({ target }) =>
            dispatchFilter({
              type: "SET_SEARCH",
              payload: { search: target.value },
            })
          }
        />
      </S.SearchInputContainer>
      <S.Title>Período personalizado</S.Title>
      <S.DateSelectContainer>
        <S.Input
          type="date"
          label="De"
          testId="from-date"
          value={formatDateToApi(filterState.startDate)}
          helper={filterState.dateErrors.startDate ? "error" : "info"}
          helperText={filterState.dateErrors.startDate || ""}
          onChange={({ target }) =>
            dispatchFilter({
              type: "SET_START_DATE",
              payload: { startDate: new Date(target.value) },
            })
          }
        />
        <S.Input
          type="date"
          label="Até"
          testId="to-date"
          value={formatDateToApi(filterState.endDate)}
          helper={filterState.dateErrors.endDate ? "error" : "info"}
          helperText={filterState.dateErrors.endDate || ""}
          onChange={({ target }) =>
            dispatchFilter({
              type: "SET_END_DATE",
              payload: { endDate: new Date(target.value) },
            })
          }
        />
      </S.DateSelectContainer>
      <S.Title>Status da venda</S.Title>
      <S.StatusContainer>
        {INSTALLMENT_STATUS.map((status) => (
          <Checkbox
            key={status}
            checked={filterState.installmentStatus.includes(status)}
            onChange={(e) => {
              dispatchFilter({
                type: "SET_INSTALLMENT_STATUS",
                payload: {
                  installmentStatus: e.checked
                    ? [...filterState.installmentStatus, status]
                    : filterState.installmentStatus.filter(
                        (item) => item !== status,
                      ),
                },
              });
            }}
          >
            {status}
          </Checkbox>
        ))}
      </S.StatusContainer>
      <S.Button
        variant="link"
        onClick={() => dispatchFilter({ type: "CLEAR_FILTERS" })}
        testId="reset-filter"
      >
        Limpar filtro
      </S.Button>
    </S.Container>
  );
}
