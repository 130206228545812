import { useState } from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as S from "./Tooltip.styles";

export type TooltipProps = Omit<
  React.ComponentProps<typeof S.TooltipContent>,
  "content"
> & {
  content: React.ReactNode;
  defaultOpen?: boolean;
};

export function Tooltip({
  children,
  content,
  defaultOpen,
  ...props
}: TooltipProps) {
  const [open, setOpen] = useState(false);

  // Veja: https://github.com/radix-ui/primitives/issues/955#issuecomment-960610209
  function handleOpenTooltipOnTouchDevices(
    event: React.PointerEvent<HTMLButtonElement>,
  ) {
    if (event.nativeEvent.pointerType === "mouse") return;
    setOpen(true);
  }

  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={setOpen}
        delayDuration={0}
      >
        <TooltipPrimitive.Trigger
          onClick={handleOpenTooltipOnTouchDevices}
          asChild
        >
          {children}
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <S.TooltipContent side="bottom" sideOffset={20} {...props}>
            {content}
          </S.TooltipContent>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
}
