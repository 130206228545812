import { useCallback } from "react";
import { useCreateDigitalSaleLink } from "@/api/useNewCharge";
import { MFAValidation, NewChargeReceipt } from "@/components";
import { useToggle } from "@/hooks";
import { useNewCharge } from "@/stores/NewCharge";
import { MIN_ZIPCODE_LENGTH, TODAY } from "@/utils/constants";
import { unMask } from "@/utils/format";
import { getChargeRoutesData, useAccountStore } from "@/utils/utility";
import { Drawer } from "@hyperlocal/vital";
import { useNavigate } from "react-router-dom";
import { DigitalSaleForm } from "../../DigitalSale";
import * as S from "./DigitalSaleAction.styles";

type DigitalSaleActionData = {
  saleAmount: number;
} & DigitalSaleForm;

const routes = getChargeRoutesData();

export function DigitalSaleAction({
  saleAmount,
  ...formData
}: DigitalSaleActionData) {
  const [isMFAOpen, setMFADrawer] = useToggle();
  const [isReceiptOpen, setReceiptDrawer] = useToggle();

  const { currentAccountId: guidAccount, account } = useAccountStore();

  const navigate = useNavigate();

  const { payerData, updateChargeData, resetNewChargeStore } = useNewCharge();

  const completeCellphone = unMask(payerData.cellphone);

  const DDD = completeCellphone.slice(0, 2);
  const cellphone = completeCellphone.slice(2, completeCellphone.length);

  const onSuccess = (link: string) => {
    updateChargeData({
      type: "DIGITAL_SALE_LINK",
      amount: saleAmount,
      chargeRecurrence: "CUSTOM",
      created: TODAY,
      description: formData.description,
      dueDate: formData.saleExpireDate,
      saleMaxInstallments: formData.saleMaxInstallments,
      link,
    });

    setMFADrawer();
    setReceiptDrawer();
  };

  const onReceiptDismiss = () => {
    setReceiptDrawer();
    resetNewChargeStore();
    navigate(routes.charge);
  };

  const digitalSaleLinkMutation = useCreateDigitalSaleLink({
    onSuccess: (data) => {
      onSuccess(data.link);
    },
  });

  const formValidation = () => {
    const validationMap: Record<keyof DigitalSaleActionData, boolean> = {
      description: !!formData.description,
      city: !!formData.city,
      complement: true,
      neighborhood: !!formData.neighborhood,
      number: !!formData.number,
      saleAmount: saleAmount > 1,
      saleExpireDate: !!formData.saleExpireDate,
      saleMaxInstallments: !!formData.saleMaxInstallments,
      state: !!formData.street,
      citiesList: true,
      street: !!formData.street,
      zipcode: unMask(formData.zipcode).length === MIN_ZIPCODE_LENGTH,
    };

    return Object.keys(formData).every((key) => validationMap[key]);
  };

  const onCreateDigitalLink = useCallback(
    (token: string) => {
      const expiredDate = new Date(formData.saleExpireDate);

      expiredDate.setHours(20, 59, 0, 0);

      digitalSaleLinkMutation.mutate({
        address: {
          street: formData.street,
          city: formData.city,
          complement: formData.complement || "Sem complemento",
          district: formData.neighborhood,
          number: formData.number,
          state: formData.state,
          zipcode: formData.zipcode,
        },

        guidAccount,
        amount: saleAmount,
        expirationDate: expiredDate.toISOString(),
        establishmentName:
          account.personInfoResponse?.name || account.companyInfoResponse?.name,
        limitInstallments: Number(formData.saleMaxInstallments),
        client: {
          name: payerData.name,
          document: unMask(payerData.document),
          email: payerData.email,
          mobilePhoneArea: DDD,
          mobilePhoneNumber: unMask(cellphone),
        },
        token,
        description: formData.description,
      });
    },
    [
      digitalSaleLinkMutation,
      formData.street,
      formData.city,
      formData.complement,
      formData.neighborhood,
      formData.number,
      formData.state,
      formData.zipcode,
      formData.saleExpireDate,
      formData.saleMaxInstallments,
      formData.description,
      guidAccount,
      saleAmount,
      account.personInfoResponse?.name,
      account.companyInfoResponse?.name,
      payerData.name,
      payerData.document,
      payerData.email,
      DDD,
      cellphone,
    ],
  );

  return (
    <>
      <S.Button onClick={setMFADrawer} disabled={!formValidation()}>
        Solicitar pagamento
      </S.Button>
      <MFAValidation
        isOpen={isMFAOpen}
        onClose={setMFADrawer}
        isLoading={digitalSaleLinkMutation.isLoading}
        onConfirm={onCreateDigitalLink}
      />
      <Drawer
        open={isReceiptOpen}
        onDismiss={onReceiptDismiss}
        position="right"
        title="Resumo da venda"
      >
        {isReceiptOpen && <NewChargeReceipt />}
      </Drawer>
    </>
  );
}
