import { DigitalLinkData, DigitalLinkStatus } from "@/api/useChargesList";
import {
  FormattedFutureInstallment,
  FutureInstallment,
  MonthlyDetail,
} from "@/types/futureInstallments";
import {
  formatCNPJ,
  formatCPF,
  isValidCNPJ,
  isValidCPF,
} from "@brazilian-utils/brazilian-utils";

// 31/08/2023 -> 08/23
export const getGroupedMonth = (date: Date) => {
  return new Date(date).toLocaleDateString("pt-BR", {
    month: "2-digit",
    year: "2-digit",
  });
};
// 31/08/2023 -> 31/08
export const getGroupedDay = (date: Date) => {
  return new Date(date).toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
  });
};

// 31/08/2023 -> agosto de 2023
export const getLongMonthAndYear = (date: Date) => {
  return new Date(date).toLocaleDateString("pt-BR", {
    month: "long",
    year: "numeric",
  });
};

// 31/08/2023 -> ago
export const getShorMonth = (date: Date) => {
  return new Date(date).toLocaleDateString("pt-BR", {
    month: "short",
  });
};

export const unMask = (value: string) => {
  return value.replace(/[^\d]/g, "");
};

export const handleNormalizeDate = (date: Date | string) => {
  const parsedDate = new Date(date);
  parsedDate.setHours(0, 0, 0, 0);
  parsedDate.setDate(parsedDate.getDate() + 1);
  return parsedDate;
};

export const formateInstallmentResponse = (
  data: FutureInstallment,
): FormattedFutureInstallment => {
  const installments = data.recurrenceDetailsTable.reduce(
    (acc, { digital_Sale_Recurrence_Items: cur }) => {
      const groupedMonth = getGroupedMonth(cur.processing_date);

      if (!acc[groupedMonth]) {
        acc[groupedMonth] = {
          total: 0,
          installments: {},
        };
      }

      const groupedDay = getGroupedDay(cur.processing_date);

      acc[groupedMonth].installments[groupedDay] = {
        amount: cur.amount,
        installmentNumber: cur.installment_number,
        processingDate: cur.processing_date,
        schedulingDate: cur.scheduling_date,
        status: cur.status,
        recurrenceItemId: cur.guid_digital_sale_recurrence_Item,
        totalInstallments: cur.total_installments,
        recurrenceStatus: cur.recorrenceStatus,
        guidDigitalSalesOrder: cur.guid_digital_sale_order,
      };

      acc[groupedMonth].total += cur.amount;

      return acc;
    },
    {} as MonthlyDetail,
  );

  const result: FormattedFutureInstallment = {
    saleRecurrence: {
      extractDescription: data.digital_Sale_Recurrence.extract_description,
      internalDescription: data.digital_Sale_Recurrence.internal_description,
      recurrencePeriod: data.digital_Sale_Recurrence.recurrence_period,
      status: data.digital_Sale_Recurrence.status,
      totalInstallments: data.digital_Sale_Recurrence.total_installments,
    },
    saleRecurrenceRecipients: {
      payerName: data.digital_Sale_Recurrence_Recipients.payer_name,
      email: data.digital_Sale_Recurrence_Recipients.email,
      document: data.digital_Sale_Recurrence_Recipients.document,
      cellArea: data.digital_Sale_Recurrence_Recipients.area_code,
      phone: data.digital_Sale_Recurrence_Recipients.cellphone,
    },
    amountPaid: data.totalRecebido,
    amountPending: data.totalReceber,
    paidQuantity: data.qtdePagas,
    totalInstallments: data.digital_Sale_Recurrence.total_installments,
    installmentsReport: installments,
  };

  return result;
};

export function formatDigitableLine(digitableLine: string | null) {
  if (!digitableLine) return;
  /*
    Posição 01-03 = Identificação do banco (exemplo: 001 = Banco do Brasil)
    Posição 04-04 = Código de moeda (exemplo: 9 = Real)
    Posição 05-09 = 5 primeiras posições do campo livre (posições 20 a 24 do código de barras)
    Posição 10-10 = Dígito verificador do primeiro campo
    Posição 11-20 = 6ª a 15ª posições do campo livre (posições 25 a 34 do código de barras)
    Posição 21-21 = Dígito verificador do segundo campo
    Posição 22-31 = 16ª a 25ª posições do campo livre (posições 35 a 44 do código de barras)
    Posição 32-32 = Dígito verificador do terceiro campo
    Posição 33-33 = Dígito verificador geral (posição 5 do código de barras)
    Posição 34-37 = Fator de vencimento (posições 6 a 9 do código de barras)
    Posição 38-47 = Valor nominal do título (posições 10 a 19 do código de barras)
  */
  if (digitableLine.length !== 47) {
    return digitableLine;
  }

  const formattedDigitableLine =
    digitableLine.substring(0, 5) +
    "." +
    digitableLine.substring(5, 10) +
    " " +
    digitableLine.substring(10, 15) +
    "." +
    digitableLine.substring(15, 21) +
    " " +
    digitableLine.substring(21, 26) +
    "." +
    digitableLine.substring(26, 32) +
    " " +
    digitableLine.substring(32, 33) +
    " " +
    digitableLine.substring(33, 47);

  return formattedDigitableLine;
}

export const MASKED_INPUT_DEFAULT_PROPS = {
  autoUnmask: true,
  placeholder: "",
  showMaskOnFocus: false,
  showMaskOnHover: false,
};

export const getGroupedDate = (date: Date) => {
  return new Date(date).toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
};

export const formatDocument = (document: string) => {
  if (!document) return "";
  const unmaskedDocument = unMask(document);

  if (isValidCNPJ(unmaskedDocument)) return formatCNPJ(document);

  if (isValidCPF(unmaskedDocument)) return formatCPF(document);

  return document;
};

export const normalizeNFD = (text: string) => {
  try {
    if (!text) return "";
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  } catch (error) {
    console.error(error);
  }
};

export const formatBrDate = (date: Date) => {
  if (!date) throw new Error("Date not found");
  return new Intl.DateTimeFormat("pt-BR").format(date);
};

export const getDigitalLinkStatus = (
  linkData: DigitalLinkData,
): DigitalLinkStatus => {
  if (linkData.isPaid) return "Pago";

  if (linkData.isCancelled) return "Cancelado";

  if (linkData.isExpired) return "Expirado";

  return "Pendente";
};
