import { useState } from "react";
import { NewChargeDrawer, ScrollArea } from "@/components";
import { useToggle } from "@/hooks";
import { useNewCharge } from "@/stores/NewCharge";
import { Avatar, Select } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import { useTheme } from "styled-components";
import { PaymentInput } from "../PaymentInput/PaymentInput";
import * as S from "./ChargeWrapper.styles";
import { DigitalSale } from "./components";

export function ChargeWrapper() {
  const { palette } = useTheme();
  const [isEditDataOpen, setEditData] = useToggle();
  const [amountValue, setAmountValue] = useState(0);

  const { payerData } = useNewCharge();

  return (
    <>
      <S.Container>
        <PaymentInput handleInput={setAmountValue} inputValue={amountValue} />
        <S.PayerInfoContainerDesktop>
          <S.DataList>
            <S.PayerInfoTitle>Dados do pagador</S.PayerInfoTitle>
            <S.DataDescription>
              <S.IconContainer onClick={setEditData}>
                <Icon
                  name="ContentEditEditLine"
                  width={24}
                  height={24}
                  color={palette.neutral.black}
                />
              </S.IconContainer>
            </S.DataDescription>
          </S.DataList>

          <S.DataList>
            <S.DataTitle>Nome</S.DataTitle>
            <S.DataDescription>{payerData.name}</S.DataDescription>
          </S.DataList>
          <S.DataList>
            <S.DataTitle>CPF/CNPJ</S.DataTitle>
            <S.DataDescription>{payerData.document}</S.DataDescription>
          </S.DataList>
          <S.DataList>
            <S.DataTitle>E-mail</S.DataTitle>
            <S.DataDescription>{payerData.email}</S.DataDescription>
          </S.DataList>
          <S.DataList>
            <S.DataTitle>Telefone</S.DataTitle>
            <S.DataDescription>{payerData.cellphone}</S.DataDescription>
          </S.DataList>
        </S.PayerInfoContainerDesktop>

        <S.PayerInfoContainerMobile>
          <Avatar variant="secondary" label="DF" />
          <S.InfoWrapper>
            <S.DataTitle>Para</S.DataTitle>
            <S.PayerInfoTitle>{payerData.name}</S.PayerInfoTitle>
            <S.DataDescription>{payerData.document}</S.DataDescription>
            <S.DataDescription>{payerData.cellphone}</S.DataDescription>
          </S.InfoWrapper>
          <S.IconContainer onClick={setEditData}>
            <Icon
              name="ContentEditEditLine"
              width={24}
              height={24}
              color={palette.neutral.black}
            />
          </S.IconContainer>
        </S.PayerInfoContainerMobile>

        <Select
          defaultValue="Link de cobrança"
          name="creditCardCompany"
          label="Tipo de cobrança"
          disabled
        >
          <option>Link de cobrança</option>
        </Select>
        <S.Wrapper data-active={true}>
          <S.Inner>
            <DigitalSale amount={amountValue} />
          </S.Inner>
        </S.Wrapper>
      </S.Container>

      <NewChargeDrawer isOpen={isEditDataOpen} onDismiss={setEditData} />
    </>
  );
}
