import { Spinner } from "@/components";
import * as S from "./LoadingFallback.styles";

type LoadingFallbackProps = React.ComponentProps<"div">;

export function LoadingFallback({ ...props }: LoadingFallbackProps) {
  return (
    <S.Container
      role="progressbar"
      aria-valuetext="Carregando..."
      aria-busy="true"
      aria-live="assertive"
      aria-valuemin={0}
      aria-valuemax={100}
      {...props}
    >
      <h2>Carregando...</h2>
      <Spinner />
    </S.Container>
  );
}
