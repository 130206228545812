import { FavoriteCharge } from "@/components/FavoriteCharge";
import { ShareCharge } from "@/components/ShareCharge";
import { BoletoChargeData } from "@/stores/NewCharge/types";
import { formatCurrency } from "@/utils/utility";
import * as S from "../../NewChargeReceipt.styles";

type BoletoReceiptProps = BoletoChargeData & { amount: number };

export function BoletoReceipt(props: BoletoReceiptProps) {
  const { amount, created, description, dueDate } = props;
  return (
    <S.Container>
      <S.Title>Valor do boleto</S.Title>
      <S.Amount>{formatCurrency(amount)}</S.Amount>
      <S.Divider />
      <S.ContainerTitle>Informações da cobrança</S.ContainerTitle>
      <S.DataList>
        <S.DataTitle>Descrição</S.DataTitle>
        <S.DataDescription>{description}</S.DataDescription>
      </S.DataList>
      <S.DataList>
        <S.DataTitle>Tipo de cobrança</S.DataTitle>
        <S.DataDescription>Única</S.DataDescription>
        <S.DataTitle>Pagamento em até</S.DataTitle>
        <S.DataDescription>1x de {formatCurrency(amount)}</S.DataDescription>
      </S.DataList>
      <S.DataList>
        <S.DataTitle>Criado em</S.DataTitle>
        <S.DataDescription>{created.toLocaleDateString()}</S.DataDescription>
      </S.DataList>
      <S.DataList>
        <S.DataTitle>Vencimento</S.DataTitle>
        <S.DataDescription>{dueDate.toLocaleDateString()}</S.DataDescription>
      </S.DataList>
      <S.Divider />
      <S.DataTitle>
        Em instantes, o boleto será gerado e enviado para os dados informados.
      </S.DataTitle>

      <FavoriteCharge />
      <ShareCharge />
    </S.Container>
  );
}
