import { Button } from "@hyperlocal/vital";
import { FallbackProps } from "react-error-boundary";
import * as S from "./ErrorFallback.styles";

export function ErrorFallback({ resetErrorBoundary }: FallbackProps) {
  return (
    <S.Container role="alert" data-type="errorFallback">
      <S.Wrapper>
        <S.Paragraph>
          Desculpe, ocorreu um erro. Por favor, tente novamente mais tarde.
        </S.Paragraph>
        <Button variant="link" onClick={resetErrorBoundary}>
          Recarregar
        </Button>
      </S.Wrapper>
    </S.Container>
  );
}
