import { breakpoints } from "@/styles/breakpoints";
import { styled } from "styled-components";

export const Container = styled.div`
  max-width: 1248px;
  padding: ${({ theme }) => theme.spacings.inset.xl};
  margin: 0 auto;

  ${breakpoints.mobile} {
    padding: ${({ theme }) => theme.spacings.inset.md};
    background: ${({ theme }) => theme.palette.neutral.white};
    height: 100%;
  }
`;

export const Header = styled.header`
  ${breakpoints.mobile} {
    display: none;
  }
`;

export const Title = styled.h3`
  margin-top: 24px;

  font-size: ${({ theme }) => theme.fontSizes.lg};
  line-height: ${({ theme }) => theme.lineHeights.default};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.neutral.darkest};
`;

export const Section = styled.section`
  margin-top: 32px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  position: relative;
  overflow: hidden;

  ${breakpoints.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    margin-top: 0;
    height: 100%;
  }
`;

export const LeftWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.inline.xs};
`;

export const StatusWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.inline.xs};

  ${breakpoints.mobile} {
    gap: ${({ theme }) => theme.spacings.inline.x2s};
  }
`;
