import { breakpoints } from "@/styles/breakpoints";
import styled from "styled-components";

export const ModalContainer = styled.div`
  & > div {
    & > main {
      width: auto;
    }
  }

  ${breakpoints.mobile} {
    display: none;
  }
`;

export const DrawerContainer = styled.div`
  ${breakpoints.desktop} {
    display: none;
  }
`;

export const CalendarContainer = styled.div`
  & > div {
    box-shadow: none;
  }
`;

export const WarningText = styled.p`
  color: ${({ theme }) => theme.palette.neutral.dark};
  font-size: ${({ theme }) => theme.fontSizes.x2s};
  max-width: 340px;
`;
