import { useEffect } from "react";
import { NewChargeReceipt as NewChargeReceiptComponent } from "@/components";
import { useSetHeader } from "@/hooks";
import { useNewCharge } from "@/stores/NewCharge";
import { getChargeRoutesData } from "@/utils/utility";
import { useNavigate } from "react-router-dom";
import * as S from "./NewChargeReceiptPage.styles";

const headerLabelMap = {
  BOLETO: "Resumo do boleto",
  PIX: "Resumo do Pix",
  DIGITAL_SALE_LINK: "Resumo da venda",
  DIGITAL_SALE: "Resumo da venda",
  DIGITAL_SALE_RECURRENCE: "Resumo da recorrência",
};

const routes = getChargeRoutesData();

export function NewChargeReceiptPage() {
  const { chargeData } = useNewCharge();

  const navigate = useNavigate();

  useSetHeader({
    title: headerLabelMap[chargeData?.type],
    backRoute: "charge",
  });

  useEffect(() => {
    if (!chargeData.amount) {
      navigate(routes.newCharge);
    }
  }, [chargeData.amount, navigate]);

  if (!chargeData.amount) return null;

  return (
    <S.Container>
      <NewChargeReceiptComponent />
    </S.Container>
  );
}
