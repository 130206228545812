import VitalIcon from "@hyperlocal/vital-icons";
import styled from "styled-components";

export const Icon = styled(VitalIcon)<{ padding: number }>`
  // Se esse elemento for filho de um flex isso impede que ele diminua.
  flex-shrink: 0;

  box-sizing: content-box;
  padding: ${({ padding }) => `${padding}px`};
  border-radius: ${({ theme }) => theme.borderRadius.circle}; // 50%

  &[data-status="Pago"] {
    background: ${({ theme }) => theme.palette.status.success.successLight};
    color: ${({ theme }) => theme.palette.status.success.successDark};
  }

  &[data-status="Pendente"] {
    background: ${({ theme }) => theme.palette.status.warning.warningLight};
    color: ${({ theme }) => theme.palette.status.warning.warningDark};
  }

  &[data-status="Estornado"] {
    background: ${({ theme }) => theme.palette.primary.lighter};
    color: ${({ theme }) => theme.palette.primary.darker};
  }

  &[data-status="Cancelado"],
  &[data-status="Expirado"] {
    background: ${({ theme }) => theme.palette.status.error.errorLight};
    color: ${({ theme }) => theme.palette.status.error.errorDark};
  }

  &[data-status="Pausado"] {
    background: ${({ theme }) => theme.palette.neutral.lighter};
    color: ${({ theme }) => theme.palette.neutral.darker};
  }
`;
