import { useCallback, useEffect, useReducer } from "react";
import { getCities, useCep, useGetStates } from "@/api/useAddress";
import {
  useCreditCardCompanies,
  useEditCard,
} from "@/api/useInstallmentDetail";
import { InputMask, TokenDrawer } from "@/components";
import { useSetHeader, useToggle } from "@/hooks";
import { guidTokenType } from "@/services/general";
import { useFilter } from "@/stores";
import { StandardCustomEvent } from "@/types/general";
import { CepPromiseError } from "@/types/request";
import { unMask } from "@/utils/format";
import {
  getChargeRoutesData,
  Hooks,
  toast,
  useAccountStore,
  useUserStore,
} from "@/utils/utility";
import { Drawer, Select } from "@hyperlocal/vital";
import { useParams } from "react-router-dom";
import * as S from "./ChangeCreditCardDrawer.styles";
import { ReducerFormAction, ReducerFormState } from "./types";

const DOCUMENT_LENGTH = 11;

const CREDIT_CARD_MIN_LENGTH = 16;

const CVV_LENGTH = 3;

const DUE_DATE_LENGTH = 4;

const ZIP_CODE_LENGTH = 8;

export interface ChangeCardDrawerProps {
  isOpen: boolean;
  onDismiss: () => void;
}

const routes = getChargeRoutesData();

const formReducer = (
  state: ReducerFormState,
  { name, value }: ReducerFormAction<keyof ReducerFormState>,
) => {
  return {
    ...state,
    [name]: value,
  };
};

const formInitialState: ReducerFormState = {
  cardName: "",
  cardNumber: "",
  city: "",
  complement: "",
  CVV: "",
  document: "",
  dueDate: "",
  number: "",
  state: "",
  street: "",
  zipCode: "",
  citiesList: [],
  changeAll: false,
  creditCardCompany: null,
};

const formValidation = (form: ReducerFormState) => {
  const validationMap: Record<keyof ReducerFormState, boolean> = {
    cardName: !!form.cardName,
    cardNumber: unMask(form.cardNumber).length >= CREDIT_CARD_MIN_LENGTH,
    changeAll: true,
    citiesList: !!form.citiesList.length,
    city: !!form.city,
    complement: true,
    CVV: unMask(form.CVV).length === CVV_LENGTH,
    document: unMask(form.document).length === DOCUMENT_LENGTH,
    dueDate: unMask(form.dueDate).length === DUE_DATE_LENGTH,
    number: !!form.number,
    state: !!form.state,
    street: !!form.street,
    zipCode: unMask(form.zipCode).length === ZIP_CODE_LENGTH,
    creditCardCompany: !!form.creditCardCompany,
  };

  return Object.keys(form).every((key) => validationMap[key]);
};

const { useDispatchTokenEvent, useListenCustomEvent, useMediaQuery } = Hooks;

export function ChangeCreditCardDrawer({
  isOpen,
  onDismiss,
}: ChangeCardDrawerProps) {
  const [formState, dispatch] = useReducer(formReducer, formInitialState);
  const [tokenDrawer, setTokenDrawer] = useToggle();
  const {
    filterState: { installmentSelected },
  } = useFilter();

  const {
    user: { userId: guidUser },
  } = useUserStore();

  const { currentAccountId } = useAccountStore();

  const { id } = useParams();

  const isMobile = useMediaQuery("mobile");

  const path = isMobile
    ? routes.futureInstallmentDetail.replace(":id", id)
    : routes.futureInstallments.replace(":id", id);

  const dispatchToken = useDispatchTokenEvent({
    detail: {
      eventIdentifier: "EDIT_RECURRENCE_CARD",
      request: {
        guid_user: guidUser,
        guid_token_type: guidTokenType.EDIT_RECURRENCE_CARD,
        path,
      },
    },
  });

  const { data } = useCreditCardCompanies({
    suspense: false,
  });

  const editMutation = useEditCard({
    onError: (error) => {
      const errorMessage =
        error?.response?.data[0]?.message || `Houve um erro ao editar o cartão`;
      toast.error(errorMessage);
    },
    onSuccess: () => {
      toast.success("Cartão editado com sucesso");
      onDismiss();
    },
  });

  const { refetch } = useCep(formState.zipCode, {
    suspense: false,
    enabled: false,
    onError: (error: CepPromiseError) => {
      dispatch({
        name: "zipCode",
        value: "",
      });
      const errorMessage = error?.message || "Houve um erro ao procurar o CEP";

      toast.error(errorMessage);
    },
    onSuccess: (data) => {
      dispatch({
        name: "street",
        value: data.street,
      });

      dispatch({
        name: "city",
        value: data.city,
      });

      dispatch({
        name: "state",
        value: data.state,
      });

      getCities(data.state).then((res) =>
        dispatch({
          name: "citiesList",
          value: res,
        }),
      );
    },
  });

  const { data: statesList } = useGetStates({
    suspense: false,
  });

  const handleInput = (
    { target }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    value?: boolean,
  ) => {
    dispatch({
      name: target.name as keyof ReducerFormState,
      value: value || target.value,
    });
  };

  const setHeader = useSetHeader({
    title: `Detalhes da ${installmentSelected?.installmentNumber}ª parcela`,
    backRoute: "futureInstallmentDetail",
  });

  const handleEdit = useCallback(
    ({ detail }: StandardCustomEvent) => {
      setHeader();
      setTokenDrawer();
      const [month, year] = formState.dueDate.split("/");

      editMutation.mutate({
        address: formState.street,
        card_holder: formState.cardName,
        card_number: unMask(formState.cardNumber),
        changeAll: formState.changeAll,
        city: formState.city,
        complement: formState.complement || "Sem complemento",
        country: "BR",
        CVV: formState.CVV,
        month_valid_thru: month,
        number: formState.number,
        year_valid_thru: year,
        state: formState.state,
        guidDigitalSaleRecurrence: id,
        guidAccount: currentAccountId,
        guid_user: guidUser,
        guid_digital_sale_recurrence_Item: installmentSelected.recurrenceItemId,
        guid_creditcard_company:
          formState.creditCardCompany?.guid_creditcard_company,
        token: detail.token,
      });
    },
    [
      editMutation,
      formState.CVV,
      formState.cardName,
      formState.cardNumber,
      formState.changeAll,
      formState.city,
      formState.complement,
      formState.creditCardCompany?.guid_creditcard_company,
      formState.dueDate,
      formState.number,
      formState.state,
      formState.street,
      currentAccountId,
      guidUser,
      id,
      installmentSelected.recurrenceItemId,
      setHeader,
      setTokenDrawer,
    ],
  );

  useListenCustomEvent({
    eventName: "EDIT_RECURRENCE_CARD",
    callback: handleEdit,
  });

  useEffect(() => {
    const isZipCodeFilled = unMask(formState.zipCode).length === 8;
    if (isZipCodeFilled) {
      refetch();
    }
  }, [formState.zipCode, formState.zipCode.length, refetch]);

  return (
    <>
      <Drawer
        title="Dados do cartão"
        position="right"
        open={isOpen}
        onDismiss={onDismiss}
        testId={{ title: "change-card-title", drawer: "change-card-drawer" }}
      >
        <S.Container>
          <InputMask
            mask="999.999.999-99"
            name="document"
            label="CPF"
            placeholder="000.000.000-00"
            value={formState.document}
            onChange={handleInput}
          />
          <S.Input
            name="cardName"
            type="text"
            value={formState.cardName}
            label="Nome impresso no cartão"
            placeholder="Nome impresso"
            onChange={(e) => handleInput(e)}
          />
          <InputMask
            mask="9999.9999.9999.9999999"
            name="cardNumber"
            type="number"
            label="Nº do cartão"
            placeholder="0000.0000.0000.0000"
            maskPlaceholder={null}
            onChange={handleInput}
          />
          <S.FlexContainer className="flex-input">
            <InputMask
              mask="99/99"
              name="dueDate"
              label="Validade"
              placeholder="01/29"
              onChange={handleInput}
            />
            <InputMask
              mask="999"
              onChange={handleInput}
              name="CVV"
              label="CVV"
              placeholder="000"
            />
          </S.FlexContainer>
          <Select
            value={formState.creditCardCompany?.description}
            onChange={handleInput}
            name="creditCardCompany"
            label="Bandeira"
          >
            {data?.map((company) => (
              <option key={company.guid_creditcard_company}>
                {company.description}
              </option>
            ))}
          </Select>
          <InputMask
            mask="99.999-999"
            onChange={handleInput}
            value={formState.zipCode}
            name="zipCode"
            label="CEP"
            placeholder="00.000-000"
          />
          <S.Input
            onChange={(e) => handleInput(e)}
            type="text"
            value={formState.street}
            name="street"
            label="Endereço"
            placeholder="Rua"
            disabled={!formState.street}
          />
          <S.FlexContainer className="flex-input">
            <InputMask
              mask="99999999999999"
              onChange={handleInput}
              name="number"
              maskPlaceholder={null}
              label="Número"
              disabled={!formState.street}
            />
            <S.Input
              type="text"
              onChange={(e) => handleInput(e)}
              name="complement"
              label="Complemento"
              placeholder="Casa"
              disabled={!formState.street}
            />
          </S.FlexContainer>
          <S.FlexContainer>
            <Select
              name="city"
              label="Cidade"
              onChange={handleInput}
              value={formState.city}
              disabled={!formState.city}
            >
              {formState.citiesList.map((item, index) => (
                <option key={index}>{item.nome}</option>
              ))}
            </Select>
            <Select
              value={formState.state}
              disabled={!formState.state}
              onChange={handleInput}
              name="state"
              label="Estado"
            >
              {statesList?.map((state) => (
                <option key={state.sigla}>{state.sigla}</option>
              ))}
            </Select>
          </S.FlexContainer>
          <S.Checkbox
            checked={formState.changeAll}
            onChange={(e) => dispatch({ name: "changeAll", value: e.checked })}
          >
            Usar nas próximas parcelas
          </S.Checkbox>
          <S.Button
            onClick={setTokenDrawer}
            isLoading={editMutation.isLoading}
            disabled={!formValidation(formState)}
          >
            Continuar
          </S.Button>
        </S.Container>
      </Drawer>
      <TokenDrawer
        isOpen={tokenDrawer}
        onClose={setTokenDrawer}
        dispatchOnMount={dispatchToken}
      />
    </>
  );
}
