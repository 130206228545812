import { createContext, useContext, useMemo, useReducer } from "react";
import { Action, Context, State } from "@/stores/ChargesFilter/types";
import {
  handleEndDate,
  handleSelectRange,
  handleStartDate,
} from "@/stores/ChargesFilter/utils";

export const DATE_RANGES = ["7", "15", "30", "60", "90"] as const;

export const CHARGE_STATUS = [
  "Pago",
  "Expirado",
  "Pendente",
  "Cancelado",
] as const;

export const CHARGE_TYPES = [
  "Boleto",
  "Venda digitada",
  "Boleto recorrência",
  "Recorrência",
] as const;

const initialState: State = {
  ranges: DATE_RANGES,
  search: "",
  startDate: undefined, // Pega 15 dias para trás, a partir de hoje
  endDate: undefined,
  chargeStatus: CHARGE_STATUS,
  chargeTypes: CHARGE_TYPES,
  selectedRange: undefined,
  dateErrors: {
    endDate: "",
    startDate: "",
  },
};

function reducer(state: State, action: Action) {
  switch (action.type) {
    case "SET_SELECTED_RANGE":
      return handleSelectRange(state, action.payload.selectedRange);
    case "SET_SEARCH":
      return { ...state, search: action.payload.search };
    case "SET_START_DATE":
      return handleStartDate(state, action.payload.startDate);
    case "SET_END_DATE":
      return handleEndDate(state, action.payload.endDate);
    case "SET_CHARGE_STATUS":
      return { ...state, chargeStatus: action.payload.chargeStatus };
    case "SET_CHARGE_TYPES":
      return { ...state, chargeTypes: action.payload.chargeTypes };
    case "CLEAR_FILTERS":
      return initialState;
    default:
      return state;
  }
}

export const ChargesFilterContext = createContext<Context>({
  filterState: initialState,
  dispatchFilter: () => null,
});

export function ChargesFilterProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [filterState, dispatchFilter] = useReducer(reducer, initialState);

  const value = useMemo(
    () => ({ filterState, dispatchFilter }),
    [filterState, dispatchFilter],
  );

  return (
    <ChargesFilterContext.Provider value={value}>
      {children}
    </ChargesFilterContext.Provider>
  );
}

export function useChargesFilter() {
  const context = useContext(ChargesFilterContext);
  if (!context) {
    throw new Error(
      "ChargeFilterContext must be used within ChargeFilterProvider",
    );
  }
  return context;
}
