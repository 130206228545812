import { breakpoints } from "@/styles/breakpoints";
import { Button as VitalButton, Card as VitalCard } from "@hyperlocal/vital";
import styled from "styled-components";

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.neutral.white};
  padding: ${({ theme }) => `${theme.spacings.inset.lg} 0`}; //24px 0
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  inset: 0;
  grid-column: 2;

  ${breakpoints.mobile} {
    position: initial;
    inset: auto;
    grid-column: auto;
    padding: 0;
    overflow: hidden;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacings.inset.md};
`;

export const Button = styled(VitalButton)`
  width: 100%;
  gap: ${({ theme }) => theme.spacings.inset.md};
  justify-content: center;
  color: ${({ variant, theme }) =>
    variant === "link" ? theme.palette.status.error.errorDark : "auto"};
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  margin: ${({ theme }) => `0 ${theme.spacings.inset.lg}`};
`;

export const MonthHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacings.inset.md};

  ${breakpoints.mobile} {
    flex-direction: column;
  }
`;

export const MonthTitle = styled.h5`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.palette.neutral.darkest};

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

export const MonthIncome = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.palette.neutral.dark};
  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.x3s};
  }
`;

export const InstallmentCard = styled(VitalCard)`
  width: 100% !important;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: ${({ theme }) => theme.spacings.inset.x2s};
  padding: ${({ theme }) => theme.spacings.squished.md};
  margin-bottom: ${({ theme }) => theme.spacings.inset.x2s};

  &:last-of-type {
    margin-bottom: ${({ theme }) => theme.spacings.inset.md};
  }

  ${breakpoints.mobile} {
    padding: ${({ theme }) => theme.spacings.squished.sm};
  }
`;

export const InstallmentDateWrapper = styled.div`
  color: ${({ theme }) => theme.palette.neutral.black};

  &[data-status="P"] {
    color: ${({ theme }) => theme.palette.status.warning.warningDark};
  }
  &[data-status="A"] {
    color: ${({ theme }) => theme.palette.status.warning.warningDark};
  }
  &[data-status="E"] {
    color: ${({ theme }) => theme.palette.status.success.successDark};
  }
  &[data-status="C"] {
    color: ${({ theme }) => theme.palette.status.error.errorDark};
  }
`;

export const InstallmentDate = styled.h6`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  text-align: center;

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.x2s};
  }
`;

export const InstallmentMonth = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.fontSizes.x2s};
  text-align: center;

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.x4s};
  }
`;

export const InstallmentInfoStatus = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.x2s};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: ${({ theme }) => theme.lineHeights.md};
  color: ${({ theme }) => theme.palette.neutral.darkest};

  ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.x4s};
  }
`;

export const InstallmentAmountInfo = styled.div`
  text-align: right;
`;

export const InstallmentsLeft = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.fontSizes.x2s};
  color: ${({ theme }) => theme.palette.neutral.dark};
`;

export const InstallmentAmount = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.palette.neutral.black};
  &[data-status="P"] {
    color: ${({ theme }) => theme.palette.status.warning.warningDark};
  }
  &[data-status="A"] {
    color: ${({ theme }) => theme.palette.status.warning.warningDark};
  }
  &[data-status="E"] {
    color: ${({ theme }) => theme.palette.status.success.successDark};
  }
  &[data-status="C"] {
    color: ${({ theme }) => theme.palette.status.error.errorDark};
  }
`;

export const InstallmentNoFound = styled.p`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.neutral.main};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.x3s};
`;
