import * as S from "./ScrollArea.styles";

type ScrollAreaProps = React.ComponentProps<typeof S.ScrollAreaRoot>;

export function ScrollArea({ children, ...props }: ScrollAreaProps) {
  return (
    <S.ScrollAreaRoot scrollHideDelay={0} {...props}>
      <S.ScrollAreaViewport>{children}</S.ScrollAreaViewport>
      <S.ScrollAreaScrollbar orientation="vertical">
        <S.ScrollAreaThumb />
      </S.ScrollAreaScrollbar>
    </S.ScrollAreaRoot>
  );
}
