import { breakpoints } from "@/styles/breakpoints";
import { styled } from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.stack.xs}; // 32px

  background: ${({ theme }) => theme.palette.neutral.white};
  padding: ${({ theme }) => theme.spacings.squished.lg}; // 16px 24px
  border-radius: ${({ theme }) => theme.borderRadius.sm}; // 8px

  ${breakpoints.mobile} {
    background: ${({ theme }) => theme.palette.neutral.lighter};
    padding: ${({ theme }) => theme.spacings.inline.xs}; // 16px
  }
`;

export const Divider = styled.hr`
  width: 1px;
  background: ${({ theme }) => theme.palette.neutral.lighter};

  border: none;
  align-self: stretch;

  ${breakpoints.mobile} {
    display: none;
  }
`;

export const HiddenValuesButton = styled.button`
  background: none;
  border: none;
  font-size: 0;

  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.33;
  }
`;
