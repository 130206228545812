import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  place-items: center;

  height: 100%;

  background: ${({ theme }) => theme.palette.neutral.white};
  padding: ${({ theme }) => theme.spacings.inline.sm}; // 24px
  border-radius: ${({ theme }) => theme.borderRadius.sm}; // 8px

  overflow-y: auto;
`;

// Esse wrapper é necessário para quando o container estiver em overflow os
// itens não fiquem achatados e sim ocupem toda a altura disponível
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.stack.nano}; // 8px
`;

export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: 48px; // A mesma altura do botão
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.palette.neutral.dark};
`;
