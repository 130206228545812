function removeTimeZone(date: Date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
}

export function formatDateToApi(date: Date | null) {
  if (!date) return;

  return removeTimeZone(date).toISOString().split("T")[0];
}

// 31/08/2023 -> 31/08
export function getGroupedDay(date: Date) {
  return new Date(date).toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
  });
}

// 31/08/2023 -> agosto 2023
export function getLongMonthAndYear(date: Date) {
  return new Date(date)
    .toLocaleDateString("pt-BR", {
      month: "long",
      year: "numeric",
    })
    .replace(" de", "");
}
